<form class="user-form" *ngIf="!isLoading && rolesList && clientsList" [formGroup]="editForm" (submit)="onSubmit()">
    <mat-form-field>
        <mat-label>{{ 'users.edit.username.label' | translate }}</mat-label>
        <input type="text" matInput formControlName="username">
        <mat-error>{{ 'users.edit.username.errorMessage' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{ 'users.edit.email.label' | translate }}</mat-label>
        <input type="text" matInput formControlName="email">
        <mat-error>{{ 'users.edit.email.errorMessage' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{ 'users.edit.role.label' | translate }}</mat-label>
        <mat-select formControlName="role">
            <mat-option *ngFor="let role of rolesList" [value]="role.id">
                {{ 'roles.' + role.name | translate}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <section class="detail" formGroupName="detail">
        <mat-form-field>
            <mat-label>{{ 'users.edit.firstname.label' | translate }}</mat-label>
            <input type="text" matInput formControlName="firstname">
            <mat-error>{{ 'users.edit.firstname.errorMessage' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'users.edit.lastname.label' | translate }}</mat-label>
            <input type="text" matInput formControlName="lastname">
            <mat-error>{{ 'users.edit.lastname.errorMessage' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{ 'users.edit.phone.label' | translate }}</mat-label>
            <input type="text" matInput formControlName="phone">
            <mat-error>{{ 'users.edit.phone.errorMessage' | translate }}</mat-error>
        </mat-form-field>
    </section>

    <fieldset class="clients-list">
        <legend><h3>{{ 'users.edit.clients.title' | translate }}</h3></legend>
        <mat-list formArrayName="clients">
            <mat-list-item *ngFor="let clientControl of getArray('clients').controls; index as i">
                <section class="form-field">
                    <app-autocomplete [control]="clientControl" [list]="clientsList"
                        label="users.edit.clients.select.label"
                        errorMsg="users.edit.clients.select.errorMessage">
                    </app-autocomplete>
                </section>

                <button type="button" mat-icon-button color="accent"
                    [disabled]="getArray('clients').controls.length <= 1"
                    (click)="onRemoveClient(i)">
                    <mat-icon aria-label="delete">delete</mat-icon>
                </button>
            </mat-list-item>
            <mat-list-item *ngIf="canAddClient && selectedCliendId === 'all'">
                <button type="button" mat-icon-button color="accent" (click)="onAddClient()">
                    <mat-icon aria-label="Add">add</mat-icon>
                </button>
            </mat-list-item>
        </mat-list>
    </fieldset>

    <section class="actions">
        <button *ngIf="!isLoading" type="submit" mat-icon-button color="accent" [disabled]="editForm.invalid">
            <mat-icon aria-label="Validate">check</mat-icon>
        </button>
        <button *ngIf="!isLoading" type="button" mat-icon-button (click)="onCancel()">
            <mat-icon aria-label="Cancel">clear</mat-icon>
        </button>
    </section>
</form>
<mat-spinner *ngIf="isLoading" [diameter]="35" color="accent"></mat-spinner>
