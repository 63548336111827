import { ClientsService } from '@services/clients.service';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { FormEditBaseComponent } from '@components/form-edit-base.component';
import { UiService } from '@services/ui.service';
import { Contact } from '@classes/contact';
import { ContactsService } from '@services/contacts.service';
import { Civility } from '@enums/civility.enum';
import { Client } from '@classes/client';

interface CivilityItem { id: number; name: string; }

@Component({
    selector: 'app-contact-edit',
    templateUrl: './contact-edit.component.html',
    styleUrls: ['./contact-edit.component.scss']
})
export class ContactEditComponent extends FormEditBaseComponent implements OnInit {
    @Input() contact: Contact;
    civilities: CivilityItem[];
    clients: Client[];
    identifierPattern = "^[0-9]{0,50}$";

    constructor(
        protected uiSrv: UiService,
        protected fb: FormBuilder,
        private contactsSrv: ContactsService,
        private clientsSrv: ClientsService
    ) {
        super(uiSrv, fb);
    }

    ngOnInit() {
        super.ngOnInit();
        this.initSubscriptions();
        this.civilities = this.enumToArray(Civility);
    }

    private initSubscriptions(): void {
        this.isLoading = true;

        const clientsPromise = new Promise((resolve, reject) => {
            this.clientsSrv.getList()
                .subscribe(clientsFromSrv => {
                    this.clients = clientsFromSrv;
                    resolve(clientsFromSrv);
                });
        });

        Promise.all([clientsPromise])
            .then(() => {
                this.initForm();
                this.isLoading = false;
            });
    }

    onSubmit(): void {
        this.isLoading = true;
        if (this.contact) {
            this.update();
        } else {
            this.create();
        }
    }

    onCancel(): void {
        this.editFinished.emit();
    }

    private update(): void {
        const contactCopy: Contact = JSON.parse(JSON.stringify(this.contact));
        contactCopy.civility = this.editForm.value.civility;
        contactCopy.firstname = this.editForm.value.firstname;
        contactCopy.lastname = this.editForm.value.lastname;
        contactCopy.mobilePhone = this.editForm.value.mobile;
        contactCopy.phone = this.editForm.value.phone;
        contactCopy.email = this.editForm.value.email;
        contactCopy.identifier = this.editForm?.value?.identifier.replaceAll(":", "");
        contactCopy.client = this.editForm.value.client;

        this.contactsSrv.put(contactCopy).subscribe(() => this.isLoading = false);
    }

    private create(): void {
        this.contact = new Contact(
            this.editForm.value.civility,
            this.editForm.value.firstname,
            this.editForm.value.lastname,
            this.editForm.value.phone,
            this.editForm.value.mobile,
            this.editForm.value.email,
            this.editForm.value.client,
            this.editForm.value?.identifier.replaceAll(":", "")
        );


        this.contactsSrv.post(this.contact)
            .subscribe(
                () => this.isLoading = false,
                () => this.contact = null
            );
    }

    private initForm(): void {
        let civility = null;
        let firstname = "";
        let lastname = "";
        let phone = "";
        let mobile = "";
        let email = "";
        let identifier = "";
        let client = null;

        if (this.contact) {
            civility = this.contact.civility;
            firstname = this.contact.firstname;
            lastname = this.contact.lastname;
            phone = this.contact.phone;
            mobile = this.contact.mobilePhone;
            email = this.contact.email;
            identifier = this.contact.identifier;
            client = this.contact.client;
        }

        this.editForm = this.fb.group({
            civility: [civility, Validators.required],
            firstname: [firstname, Validators.required],
            lastname: [lastname, Validators.required],
            client: [client, Validators.required],
            mobile: [mobile],
            email: [email, [Validators.email]],
            phone: [phone],
            identifier: [identifier]
        });
    }

    private enumToArray(Enum: any): CivilityItem[] {
        return Object.keys(Enum)
            .filter(key => typeof Enum[key] === 'number')
            .map(key => ({ id: Enum[key], name: key } as CivilityItem));
    }
}
