import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { UiService } from "@services/ui.service";
import { ContactsService } from '@services/contacts.service';
import { Contact } from '@classes/contact';


@Injectable()
export class ContactsResolver implements Resolve<Contact[]> {
    constructor(
        private contactsSrv: ContactsService,
        private uiSrv: UiService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Contact[]> {
        this.uiSrv.routeLoadingStateChanged.next(true);

        return this.contactsSrv.getList()
        .pipe(
            tap(() => {
                this.uiSrv.routeLoadingStateChanged.next(false);
            })
        );
    }
}
