import { Component, OnInit, Input } from '@angular/core';
import { Mission } from '@classes/mission/mission';

@Component({
    selector: 'app-missions-list',
    templateUrl: './missions-list.component.html',
    styleUrls: ['./missions-list.component.scss']
})
export class MissionsListComponent implements OnInit {
    @Input() missions: Mission[];

    constructor() { }

    ngOnInit() {
    }

}
