import { TemplatesService } from './../../shared/services/templates.service';
import { Component, OnInit, Input } from '@angular/core';
import { FormEditBaseComponent } from '@components/form-edit-base.component';
import { FormBuilder, Validators, FormGroup, ValidationErrors, FormControl } from '@angular/forms';
import * as moment from 'moment';

import { UiService } from '@services/ui.service';
import { Mission } from '@classes/mission/mission';
import { Template } from '@classes/template';
import { User } from '@classes/user/user';
import { MissionsService } from '@services/missions.service';
import { Step } from '@classes/step/step';
import { AuthService } from '@services/auth.service';
import { RoleName } from '@enums/role-name.enum';
import { UsersService } from '@services/users.service';
import { MissionSchedule } from '@classes/mission/mission-schedule';
import { StepsService } from '@services/steps.service';
import { ConfirmValidMatcher } from '@classes/validations/confirm-valid-matcher';
import { Client } from '@classes/client';
import { ClientsService } from '@services/clients.service';
import { FrequenciesMissionService } from '@services/frequencies-mission.service';
import { getDate } from 'date-fns';

@Component({
    selector: 'app-mission-edit',
    templateUrl: './mission-edit.component.html',
    styleUrls: ['./mission-edit.component.scss']
})
export class MissionEditComponent extends FormEditBaseComponent implements OnInit {
    @Input() mission: Mission;
    @Input() missions: Mission[];
    @Input() templates: Template[];

    formSteps: Step[];
    drivers: User[];
    steps: Step[];
    model = 'oneStep';
    modelSchedule = 'classic';
    clients: Client[];
    frequencies = null;

    nameMaxLength = 50;
    commentMaxLength = 500;
    isRestricted: boolean;
    isAdminClient = false;
    mustCheckDate: boolean;
    confirmValidMatcher = new ConfirmValidMatcher();

    copyStep = new FormControl();


    private urlFrequency = '../../../assets/mock-data';

    constructor(
        protected uiSrv: UiService,
        protected fb: FormBuilder,
        private missionsSrv: MissionsService,
        private authSrv: AuthService,
        private usersSrv: UsersService,
        private stepsSrv: StepsService,
        private clientsSrv: ClientsService,
        private frequenciesSrv: FrequenciesMissionService,
        private templatesSrv: TemplatesService
    ) {
        super(uiSrv, fb);
    }

    ngOnInit() {
        super.ngOnInit();
        this.isRestricted = this.authSrv.getUser().role.name === RoleName.labo;
        this.isAdminClient = this.authSrv.getUser().role.name === RoleName.admin_labo;
        if (this.usersSrv.clientSelected !== 'all') {
            this.promiseAllRequest();
        } else {
            this.clientsPromise();
        }

        this.initSubscription();
    }

    initSubscription() {
        this.addSubscription(
            this.stepsSrv.stepsListChanged.subscribe((stepsFromSrv) => {
                this.steps = stepsFromSrv;
            })
        )

        this.addSubscription(
            this.usersSrv.clientChangedSubject.subscribe(() => {
                // this.editForm.reset();
                // this.editForm.updateValueAndValidity();
                this.isLoading = true;
                setTimeout(() => {
                    this.model = 'oneStep';
                    this.promiseAllRequest();
                    this.templatesPromise(this.usersSrv.clientSelected);
                })

            })
        )
    }

    isControlSet(control: string): boolean {
        return !!this.editForm.get(control);
    }

    onAddSchedule(startDate?: Date, driver?: User): void {
        const startMoment: moment.Moment = startDate ? moment(startDate).local() : moment(new Date());
        const startTime: string = startMoment ? startMoment.add(2, 'hours').format("HH:mm") : moment(startDate).add(2, 'hours').format('HH:mm');

        const newGroup = this.fb.group({
            startDate: [startMoment, Validators.required],
            startTime: [startTime, Validators.required]
        }, { validators: this.minDateValidator.bind(this) });

        if (!this.isRestricted) {
            newGroup.addControl('driver', this.fb.control(driver, [Validators.required]));
        }

        this.getArray('schedules').push(newGroup);

        newGroup.controls.startDate.valueChanges
            .subscribe(() => {
                this.mustCheckDate = true;
            });
        newGroup.controls.startTime.valueChanges
            .subscribe(() => {
                this.mustCheckDate = true;
            });
    }

    onRemoveSchedule(index: number): void {
        this.getArray('schedules').removeAt(index);
    }

    onAddStep(step?: Step): void {
        const control = this.fb.control(step || null);
        control.setValidators(Validators.required);
        this.getArray('steps').push(control);
    }

    onRemoveStep(index: number): void {
        this.getArray('steps').removeAt(index);
    }

    onStepsChanged(steps: Step[]): void {
        this.formSteps = steps;
    }

    onFinish(): void {
        this.editFinished.emit();
    }

    onSubmit(): void {
        this.isLoading = true;
        if (this.mission) {
            this.update();
        } else {
            this.create();
        }
    }

    private update(): void {
        const formValues = this.editForm.value;
        const schedule = formValues.schedules ? formValues.schedules[0] : null;
        const missionCopy: Mission = JSON.parse(JSON.stringify(this.mission));

        missionCopy.name = formValues.name;
        missionCopy.steps = this.formSteps;
        missionCopy.comment = formValues.comment;

        if (formValues.isUrgent != null) {
            missionCopy.isUrgent = formValues.isUrgent;
        }
        if (formValues.isFreeMode != null) {
            missionCopy.isFreeMode = formValues.isFreeMode;
        }
        if (schedule) {
            missionCopy.startDate = this.scheduleToDate(schedule);
            missionCopy.user = schedule.driver;
        }

        this.missionsSrv.put(missionCopy)
            .subscribe(() => {
                this.isLoading = false;
                this.uiSrv.showSnackbar('missions.edit.edited', true);
            });
    }

    private create(): void {
        const formValues = this.editForm.value;
        let schedules: MissionSchedule[];
        if (formValues.schedules) {
            schedules = formValues.schedules.map(schedule => {
                const startDate: Date = this.scheduleToDate(schedule);
                return new MissionSchedule(startDate, schedule.driver);
            });
        } else {
            const startDate = moment().add(5, 'm').utc().toDate();
            schedules = [new MissionSchedule(startDate)];

            if (formValues.modelSchedule === 'regularly') {
                let regularlyForm = formValues.scheduleRegularly;
                schedules = [];

                let tmpStartDate = moment(formValues.scheduleRegularly.startDateRegularly)
                while (tmpStartDate.startOf('day') <= moment(formValues.scheduleRegularly.endDateRegularly).endOf('day')) {
                    let number = regularlyForm.number;

                    let schedule = {
                        startDate: tmpStartDate,
                        startTime: formValues.scheduleRegularly.startTimeRegularly,
                    }
                    const startDate: Date = this.scheduleToDate(schedule);
                    schedules.push(new MissionSchedule(startDate, formValues.scheduleRegularly.driverRegularly));
                    if (regularlyForm.frequency.value === 'days') {
                        tmpStartDate = tmpStartDate.add(number, 'days')
                    } else if (regularlyForm.frequency.value === 'weeks') {
                        tmpStartDate = tmpStartDate.add(number, 'weeks')
                    }
                }
            }
        }

        if (!this.isRestricted) {
            if (this.model === 'template') {
                this.mission = new Mission(
                    formValues.name,
                    schedules,
                    this.isRestricted ? true : formValues.isUrgent,
                    this.isRestricted ? false : formValues.isFreeMode,
                    formValues.template,
                    null,
                    formValues.comment,
                );
            } else if (this.model === 'oneStep') {
                let steps: Step[] = [];
                steps.push(formValues.step);
                steps.push(formValues.client.step);

                this.mission = new Mission(
                    formValues.name,
                    schedules,
                    this.isRestricted ? true : formValues.isUrgent,
                    this.isRestricted ? false : formValues.isFreeMode,
                    null,
                    steps,
                    formValues.comment,
                    formValues.client
                );
            } else if (this.model === 'steps') {
                this.mission = new Mission(
                    formValues.name,
                    schedules,
                    this.isRestricted ? true : formValues.isUrgent,
                    this.isRestricted ? false : formValues.isFreeMode,
                    null,
                    formValues.steps,
                    formValues.comment,
                    formValues.client
                );
            }
        } else {
            this.mission = new Mission(
                formValues.name,
                schedules,
                this.isRestricted ? true : formValues.isUrgent,
                this.isRestricted ? false : formValues.isFreeMode,
                this.isRestricted ? null : formValues.template,
                this.isRestricted ? formValues.steps : null,
                formValues.comment
            );
        }

        this.missionsSrv.post(this.mission)
            .subscribe(
                () => {
                    this.isLoading = false;
                    this.uiSrv.showSnackbar('missions.create.created', true);
                    this.onFinish();
                },
                () => this.mission = null
            );
    }

    addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    private scheduleToDate(schedule: any): Date {
        if (!schedule.startDate || !schedule.startTime || typeof schedule.startDate === 'string') {
            const startDate: moment.Moment = moment(new Date());
            // const startTime: number[] = startDate.hours();
            const startTime: number[] = startDate.local().add('2', 'hours').format('HH:mm').split(':').map(s => parseInt(s, 10));
            return startDate.hours(startTime[0]).minutes(startTime[1]).utc().toDate();
        }

        const startDate: moment.Moment = schedule.startDate.local();
        const startTime: number[] = schedule.startTime.split(':').map(s => parseInt(s, 10));
        return startDate.hours(startTime[0]).minutes(startTime[1]).utc().toDate();
    }

    clientsPromise() {
        const clientsPromise = new Promise((resolve, reject) => {
            this.clientsSrv.getList()
                .subscribe(clientsFormSrv => {
                    this.clients = clientsFormSrv;
                    resolve(true);
                });
        });

        const frequenciesPromise = new Promise((resolve, reject) => {
            this.frequenciesSrv.get()
                .subscribe(frequenciesFromSrv => {
                    this.frequencies = frequenciesFromSrv;
                    resolve(true);
                });
        });

        Promise.all([clientsPromise, frequenciesPromise])
            .then(() => {
                this.isLoading = false;
                this.initForm();
            });
    }

    private promiseAllRequest(): void {
        this.isLoading = true;

        const driversPromise = new Promise((resolve, reject) => {
            this.usersSrv.getList(RoleName.driver, true)
                .subscribe(usersFromSrv => {
                    this.drivers = usersFromSrv;
                    resolve(true);
                });
        });

        const stepsPromise = new Promise((resolve, reject) => {
            this.stepsSrv.getList()
                .subscribe(stepsFromSrv => {
                    stepsFromSrv.map(step => {
                        step.intruder = true;
                    })
                    this.steps = stepsFromSrv;
                    resolve(true);
                });
        });

        const clientsPromise = new Promise((resolve, reject) => {
            this.clientsSrv.getList()
                .subscribe(clientsFormSrv => {
                    this.clients = clientsFormSrv;
                    resolve(true);
                });
        });

        const frequenciesPromise = new Promise((resolve, reject) => {
            this.frequenciesSrv.get()
                .subscribe(frequenciesFromSrv => {
                    this.frequencies = frequenciesFromSrv;
                    resolve(true);
                });
        });

        Promise.all([driversPromise, stepsPromise, clientsPromise, frequenciesPromise])
            .then(() => {
                this.isLoading = false;
                this.initForm();
            });
    }

    templatesPromise(clientId) {
        this.isLoading = true;
        const templatesPromise = new Promise((resolve, reject) => {
            if (this.usersSrv.clientSelected !== 'all') {
                this.templatesSrv.getListByClientId(clientId)
                    .subscribe(templatesFromSrv => {
                        this.templates = templatesFromSrv;
                        resolve(true);
                    });
            } else {
                this.templatesSrv.getList()
                    .subscribe(templatesFromSrv => {
                        this.templates = templatesFromSrv;
                        resolve(true);
                    });
            }

        });


        Promise.all([templatesPromise])
            .then(() => {
                this.isLoading = false;
            });
    }

    private promiseAllByClientId(clientId): void {
        this.isLoading = true;
        const driversPromise = new Promise((resolve, reject) => {
            this.usersSrv.getListByClientId(clientId, RoleName.driver, true)
                .subscribe(usersFromSrv => {
                    this.drivers = usersFromSrv;
                    resolve(true);
                });
        });

        const stepsPromise = new Promise((resolve, reject) => {
            this.stepsSrv.getListByClientId(clientId)
                .subscribe(stepsFromSrv => {
                    this.steps = stepsFromSrv;
                    resolve(true);
                });
        });

        Promise.all([stepsPromise, driversPromise])
            .then(() => {
                this.isLoading = false;
            });
    }

    private initForm(): void {
        let name = '';
        let comment = '';
        let isUrgent = this.isRestricted ? true : false;
        let isFreeMode = false;
        let isMissionStarted = false;
        let driver: User = null;
        let startDate: Date = null;
        let client: Client = null;
        if (this.mission) {
            name = this.mission.name;
            isUrgent = this.mission.isUrgent;
            isFreeMode = this.mission.isFreeMode;
            comment = this.mission.comment;
            driver = this.mission.user || null;
            isMissionStarted = !!this.mission.startedOn;
            startDate = this.mission.startDate;
            client = this.mission.client;
            this.formSteps = this.mission.steps;
        }

        if (this.isAdminClient) {
            client = this.clients[0];
        }

        this.editForm = this.fb.group({
            name: [name, [Validators.required, Validators.maxLength(this.nameMaxLength)]],
            isUrgent: [{ value: isUrgent, disabled: this.isRestricted }, Validators.required],
            comment: [comment, Validators.maxLength(this.commentMaxLength)],
            client: [client],
            modelSchedule: 'classic'
        });

        if (this.usersSrv.clientSelected === 'all') {
            this.editForm.get('client').valueChanges.subscribe(client => {
                if (typeof client !== 'string' && client) {
                    this.promiseAllByClientId(client.id);
                }
            })
        }


        this.editForm.controls.modelSchedule.valueChanges.subscribe(model => {
            if (model === 'regularly') {
                this.editForm.removeControl('schedules');
                this.editForm.addControl('scheduleRegularly', this.fb.group({
                    startDateRegularly: [null, Validators.required],
                    startTimeRegularly: [null, Validators.required],
                    endDateRegularly: [null, Validators.required],
                    driverRegularly: [null, Validators.required],
                    frequency: [null, [Validators.required]],
                    number: [null, [Validators.required, Validators.min(1)]]
                }));
            } else {
                this.editForm.removeControl('scheduleRegularly');
                this.editForm.addControl('schedules', this.fb.array([]));
                this.onAddSchedule(startDate, driver);
            }
        })

        if (!this.mission) {
            this.mustCheckDate = true;
            if (!this.isRestricted) {
                this.editForm.addControl('step', this.fb.control(null, [Validators.required]));
                this.editForm.controls.step.valueChanges.subscribe((stepValue) => {
                    if (stepValue) {
                        this.editForm.controls.name.setValue(stepValue.name);
                    }
                });
                if (this.mission) {
                    this.editForm.addControl('client', this.fb.control(this.mission.client));
                } else {
                    this.editForm.addControl('client', this.fb.control(null));
                }


                this.editForm.controls['client'].setValidators([Validators.required, this.stepDefaultValidator]);
                this.editForm.controls['client'].updateValueAndValidity();

            } else {
                this.editForm.addControl('steps', this.fb.array([]));
                this.onAddStep();
                this.editForm.updateValueAndValidity();
            }




        } else {
            if (!this.mission.template) {
                this.model = 'steps';
            }
            this.editForm.addControl('steps', this.fb.array([]));
        }

        if (!this.isRestricted) {
            if (!isMissionStarted) {
                this.editForm.addControl('schedules', this.fb.array([]));
                this.onAddSchedule(startDate, driver);
            }

            this.editForm.addControl('isFreeMode', this.fb.control(isFreeMode, [Validators.required]));
        }
    }

    private minDateValidator(control: FormGroup): ValidationErrors | null {
        const scheduleDate = this.scheduleToDate(control.value);

        if (!this.mustCheckDate) {
            return null;
        }

        if (scheduleDate && scheduleDate < new Date()) {
            this.editForm.setErrors({ mismatch: true });
            return { mismatch: true };
        }

        return null;
    }

    selectModel(): void {
        if (this.model === 'template') {
            this.editForm.addControl('template', this.fb.control(null, [Validators.required]));
            this.editForm.removeControl('step');
            this.editForm.removeControl('steps');
            this.editForm.removeControl('client');
            setTimeout(() => {
                this.editForm.controls.template.valueChanges
                    .subscribe((templateValue) => {
                        if (templateValue) {
                            this.editForm.controls.name.setValue(templateValue.name);
                            // if (typeof templateValue !== 'string') {
                            //     this.isLoading = true;
                            //     this.usersSrv.getListByClientId(templateValue.client.id, RoleName.driver)
                            //         .subscribe(usersFromSrv => {
                            //             console.log("getListByClientId");
                            //             this.drivers = usersFromSrv;
                            //             this.isLoading = false;
                            //         });
                            // }
                        }

                    });
            })


        } else if (this.model === 'steps') {
            this.editForm.addControl('steps', this.fb.array([]));
            this.onAddStep();
            if (this.mission) {
                this.editForm.addControl('client', this.fb.control(this.mission.client, [Validators.required]));
            } else {
                if (this.isAdminClient) {
                    this.editForm.addControl('client', this.fb.control(this.clients[0], [Validators.required]));
                } else {
                    this.editForm.addControl('client', this.fb.control(null, [Validators.required]));
                }
            }

            this.editForm.removeControl('template');
            this.editForm.removeControl('step');
            this.editForm.controls['client'].setValidators([Validators.required]);
            this.editForm.controls['client'].updateValueAndValidity();

            if (this.usersSrv.clientSelected === 'all') {
                this.editForm.get('client').valueChanges.subscribe(client => {
                    if (typeof client !== 'string' && client) {
                        this.promiseAllByClientId(client.id);
                    }
                })
            }
        } else if (this.model === 'oneStep') {
            this.editForm.addControl('step', this.fb.control(null, [Validators.required]));
            this.editForm.controls.step.valueChanges.subscribe((stepValue) => {
                if (stepValue) {
                    this.editForm.controls.name.setValue(stepValue.name);
                }
            });

            if (this.mission) {
                this.editForm.addControl('client', this.fb.control(this.mission.client));
            } else {
                if (this.isAdminClient) {
                    this.editForm.addControl('client', this.fb.control(this.clients[0]));
                } else {
                    this.editForm.addControl('client', this.fb.control(null));
                }

            }

            this.editForm.removeControl('template');
            this.editForm.removeControl('steps');
            this.editForm.controls['client'].setValidators([Validators.required, this.stepDefaultValidator]);
            this.editForm.controls['client'].updateValueAndValidity();
        }
    }

    copySteps(missionSelected) {
        const confirmation = confirm("Êtes-vous certain de copier les étapes de la mission choisie vers la mission actuelle ? Cela annulera la mission choisie.");
        if (confirmation) {
            this.missionsSrv.copySteps(this.mission.id, missionSelected.id).subscribe(mission => {
                // this.mission.steps = mission.steps as Step[];
                this.uiSrv.showSnackbar('steps.copied', true);
            });
        }
    }

    stepDefaultValidator(c: FormControl) {
        if (c.value) {
            let isValid = c.value.step;

            return isValid ? null : {
                stepDefaultValidator: {
                    valid: false
                }
            };
        } else {
            return null;
        }


    }

}
