import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, map } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { Address } from '@classes/address';

@Injectable({
    providedIn: 'root'
})
export class AddressesService {
    addressesListChanged = new Subject<Address[]>();
    private baseUrl: string = environment.apiUrl + 'addresses';
    private addresses: Address[];

    constructor(
        private http: HttpClient
    ) { }

    getList(): Observable<Address[]> {
        return this.http.get<Address[]>(this.baseUrl)
        .pipe(
            map(addresses => {
                this.addresses = addresses.map(a => Address.fromObject(a));
                this.addressesListChanged.next([ ...this.addresses ]);
                return [ ...this.addresses ];
            })
        );
    }

    getListByClientId(clientId): Observable<Address[]> {
        return this.http.get<Address[]>(`${this.baseUrl}?clientId=${clientId}`)
        .pipe(
            map(addresses => {
                this.addresses = addresses.map(a => Address.fromObject(a));
                this.addressesListChanged.next([ ...this.addresses ]);
                return [ ...this.addresses ];
            })
        );
    }

    put(address: Address): Observable<Address> {
        return this.http.put<Address>(this.baseUrl, address)
        .pipe(
            tap(editedAddress => {
                editedAddress = Address.fromObject(editedAddress);
                this.addresses = this.addresses.map(a => a.id === editedAddress.id ? editedAddress : a);
                this.addressesListChanged.next([ ...this.addresses ]);
            })
        );
    }

    post(address: Address): Observable<Address> {
        return this.http.post<Address>(this.baseUrl, address)
        .pipe(
            tap(createdAddress => {
                createdAddress = Address.fromObject(createdAddress);
                this.addresses.push(createdAddress);
                this.addressesListChanged.next([ ...this.addresses ]);
            })
        );
    }

    delete(id: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${id}`)
        .pipe(
            tap(() => {
                this.addresses = this.addresses.filter(a => a.id !== id);
                this.addressesListChanged.next([ ...this.addresses ]);
            })
        );
    }
}
