import { BaseClass } from './base-class';
import { User } from './user/user';
import { BaseRepository } from '@interfaces/base-repository';

export class Device extends BaseClass implements BaseRepository {
    name: string;
    model: string;
    version: string;
    identifier: string;
    pushToken?: string;

    user?: User;

    constructor() {
        super();
    }

    static fromObject(object: Device): Device {
        return Object.assign(new Device(), object);
    }

    get display(): string {
        return `${this.name}`;
    }

    doesInclude(value: string): boolean {
        return this.some([this.name], value);
    }
}
