<mat-card class="steps-card">
    <mat-card-header>
        <mat-card-title>
            <h2>{{ 'missions.detail.steps' | translate }}</h2>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <section class="steps-section">
            <app-steps-list [steps]="steps" [doesShowActions]="true"></app-steps-list>
        </section>
    </mat-card-content>
    <mat-card-actions class="actionsContainer" *ngIf="doesShowEdit && !isReadOnly && !isRestricted && !mission.cancelOn">
        <section class="sectionCopySteps">
            <app-autocomplete [control]="copyStep" [list]="missions" label="missions.detail.selectMissionToCopy"></app-autocomplete>
            <button type="button" mat-raised-button color="accent" (click)="copySteps(copyStep.value)" [disabled]="!copyStep.value">
                Copier les étapes vers
            </button>
        </section>
        <button mat-raised-button color="accent"
            (click)="onEdit()">{{'missions.detail.edit' | translate}}</button>
    </mat-card-actions>
</mat-card>
