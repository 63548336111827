import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormBuilder, FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface DialogData {
    stepId: string;
    onActivate: boolean;
}

@Component({
    selector: 'app-initials-dialog',
    templateUrl: './initials-dialog.component.html',
    styleUrls: ['./initials-dialog.component.scss']
})
export class InitialsDialogComponent implements OnInit {
    initialsForm: FormGroup;

    constructor(
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<InitialsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) { }

    ngOnInit(): void {
        this.initForm();
    }

    initForm() {
        this.initialsForm = this.fb.group({
            initials: new FormControl(null, [Validators.required, Validators.minLength(2)])
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    confirmActivation(): void {
        this.dialogRef.close(this.initialsForm.value);
    }
}
