<div class="mission-view">
    <section>
        <app-detail-summary
            [mission]="mission"
            [isReadOnly]="user.isReadOnly"></app-detail-summary>
    </section>
    <section *ngIf="!isLoading">
        <ng-container *ngIf="!isEditMode">
            <div>
                <app-detail-steps
                    [isReadOnly]="user.isReadOnly"
                    [steps]="mission.steps"
                    [doesShowEdit]="mission.finishedOn == null"
                    [mission]="mission"
                    [missions]="missions"
                    (editStarted)="onEdit()"></app-detail-steps>
            </div>
            <div #reportBlock>
                <app-step-report class="step-report"></app-step-report>
            </div>
        </ng-container>
        <app-mission-edit *ngIf="isEditMode && !user.isReadOnly"
            [mission]="mission"
            [missions]="missions"
            (editFinished)="onEditFinished()"></app-mission-edit>
    </section>
    <mat-spinner *ngIf="isLoading" [diameter]="35" color="accent"></mat-spinner>
</div>
