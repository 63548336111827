import { Component, Input, OnInit } from '@angular/core';
import { Sample } from '@classes/sample';
import { AuthService } from '@services/auth.service';
import { RoleName } from '@enums/role-name.enum';

@Component({
    selector: 'app-mission-resume',
    templateUrl: './mission-resume.component.html',
    styleUrls: ['./mission-resume.component.scss']
})
export class MissionResumeComponent implements OnInit {
    @Input() missionsLength: number;
    @Input() samples: Sample[];

    isUserLabo: boolean;

    constructor(
        private authSrv: AuthService
    ) { }

    ngOnInit(): void {
        this.isUserLabo = this.authSrv.getUser().role.name === RoleName.labo;
    }

    get samplesTotal(): number {
        if(!this.samples || !this.samples.length) {
            return 0;
        }

        return this.samples
        .reduce((total: number, sample: Sample) => total + sample.quantity, 0);
    }
}
