import { User } from '@classes/user/user';

export class MissionSchedule {
    startDate: Date;
    user: User;

    constructor(startDate: Date, user?: User) {
        this.startDate = startDate || null;
        this.user = user || null;
    }
}
