import { RoleName } from '@enums/role-name.enum';

export class NavLink {
    icon: string;
    route: string;
    title?: string;

    authorized?: RoleName[];
    children?: NavLink[];

    constructor(route: string, title: string, icon: string) {
        this.route = route;
        this.icon = icon;
        this.title = title || null;
    }
}
