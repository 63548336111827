import { BaseClass } from './base-class';
import { RoleName } from '@enums/role-name.enum';

export class Role extends BaseClass {
    name: RoleName;

    constructor(name: RoleName) {
        super();
        this.name = name;
    }
}
