<form class="edit-form" *ngIf="!isLoading" [formGroup]="editForm" (submit)="onSubmit()">
    <section>
        <app-autocomplete
            [control]="getControl('client')" [list]="clients" label="missions.create.client.label"
            errorMsg="missions.create.client.errorMessage"></app-autocomplete>
    </section>

    <mat-form-field>
        <mat-label>{{ 'repository.contacts.edit.civility.label' | translate }}</mat-label>
        <mat-select class="civility-select" formControlName="civility">
            <mat-option class="civility-opt" *ngFor="let civility of civilities" [value]="civility.id">
                {{ civility.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ 'repository.contacts.edit.firstname.label' | translate }}</mat-label>
        <input type="text" matInput formControlName="firstname">
        <mat-error>{{ 'repository.contacts.edit.firstname.errorMessage' | translate }}</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ 'repository.contacts.edit.lastname.label' | translate }}</mat-label>
        <input type="text" matInput formControlName="lastname">
        <mat-error>{{ 'repository.contacts.edit.lastname.errorMessage' | translate }}</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ 'repository.contacts.edit.email.label' | translate }}</mat-label>
        <input type="text" matInput formControlName="email">
        <mat-error>{{ 'repository.contacts.edit.email.errorMessage' | translate }}</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ 'repository.contacts.edit.mobile.label' | translate }}</mat-label>
        <input type="text" matInput formControlName="mobile">
        <mat-error>{{ 'repository.contacts.edit.mobile.errorMessage' | translate }}</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ 'repository.contacts.edit.phone.label' | translate }}</mat-label>
        <input type="text" matInput formControlName="phone">
        <mat-error>{{ 'repository.contacts.edit.phone.errorMessage' | translate }}</mat-error>
    </mat-form-field>

    <mat-form-field class="identifier-field">
        <mat-label>{{ 'repository.contacts.edit.identifier.label' | translate }}</mat-label>
        <input type="text" matInput formControlName="identifier">
        <mat-error>{{ 'repository.contacts.edit.identifier.errorMessage' | translate }}</mat-error>
    </mat-form-field>



    <section class="actions">
        <button *ngIf="!isLoading" type="submit" mat-icon-button color="accent" [disabled]="editForm.invalid">
            <mat-icon aria-label="Validate">check</mat-icon>
        </button>
        <button *ngIf="!isLoading" type="button" mat-icon-button (click)="onCancel()">
            <mat-icon aria-label="Cancel">clear</mat-icon>
        </button>
    </section>
</form>
<mat-spinner *ngIf="isLoading" [diameter]="35" color="accent"></mat-spinner>
