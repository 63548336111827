import { ClientsService } from '@services/clients.service';
import { UsersService } from '@services/users.service';
import { Component, OnInit } from '@angular/core';
import { trigger, state, style } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';

import { DatatableBaseComponent } from '@components/datatable-base.component';
import { UiService } from '@services/ui.service';
import { Contact } from '@classes/contact';
import { ContactsService } from '@services/contacts.service';
import { Civility } from '@enums/civility.enum';
import { Client } from '@classes/client';
import { AuthService } from '@services/auth.service';
import { RoleName } from '@enums/role-name.enum';

@Component({
    selector: 'app-contacts',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' }))
        ])
    ]
})
export class ContactsComponent extends DatatableBaseComponent implements OnInit {
    contacts: Contact[];
    clients: Client[];

    displayedColumns = ['civility', 'firstname', 'lastname', 'mobilePhone', 'phone', 'email', 'identifier', 'client', 'actions'];
    dataSource: MatTableDataSource<Contact>;
    civility = Civility;

    isAdminClient: boolean = false;

    constructor(
        protected uiSrv: UiService,
        private route: ActivatedRoute,
        private contactsSrv: ContactsService,
        private usersSrv: UsersService,
        private authSrv: AuthService
    ) {
        super(uiSrv);
    }

    ngOnInit() {
        super.ngOnInit();
        this.getRouteData();
        this.initSubscriptions();
    }

    private initTable(): void {
        this.isAdminClient = this.authSrv.getUser().role.name === RoleName.admin_labo;
        if (this.isAdminClient) this.displayedColumns.pop();

        this.dataSource = new MatTableDataSource(this.contacts);
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (item: any, property) => {
            switch (property) {
                case 'client': return item.client.name;
                default: return item[property];
            }
        };
    }

    private getRouteData(): void {
        this.route.data
            .subscribe((data: { contacts: Contact[] }) => {
                this.contacts = data.contacts;
                this.initTable();
            });
    }

    private initSubscriptions(): void {
        this.addSubscription(
            this.contactsSrv.contactsListChanged
                .subscribe(contacts => {
                    this.dataSource.data = contacts || [];
                    this.dataSource.sort = this.sort;
                    this.isLoading = false;
                    this.onEditFinished();
                })
        );

        this.addSubscription(
            this.usersSrv.clientChangedSubject.subscribe(() => {
                this.isLoading = true;
                this.addSubscription(
                    this.contactsSrv.getList().subscribe()
                )
            })
        )
    }

    delete(idContact: string) {
        const confirmation = confirm('Êtes-vous certain de vouloir supprimer ce contact ?');

        if (confirmation) {
            this.contactsSrv.delete(idContact).subscribe(() => {
                this.uiSrv.showSnackbar('repository.contacts.deleted', true);
            });
        }

    }
}
