import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { UiService } from "@services/ui.service";
import { Asset } from '@classes/mworker/asset';
import { AssetService } from '@services/assets.service';



@Injectable()
export class AssetsResolver implements Resolve<Asset[]> {
    constructor(
        private assetService: AssetService,
        private uiSrv: UiService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Asset[]> {
        this.uiSrv.routeLoadingStateChanged.next(true);

        return this.assetService.getAllAssetsOfAccount(localStorage.getItem('accountId'))
        .pipe(
            tap(() => {
                this.uiSrv.routeLoadingStateChanged.next(false);
            })
        );
    }
}
