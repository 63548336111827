<mat-card>
    <mat-card-header>
        <mat-card-title>
            <h1>{{ 'repository.steps.title' | translate }}</h1>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <section class="creation-form">
            <button *ngIf="!isCreationMode" (click)="onEdit()" [matTooltip]="'repository.steps.list.add' | translate"
                mat-mini-fab class="action-button" color="accent">
                <mat-icon aria-label="Add">add</mat-icon>
            </button>
            <app-step-edit *ngIf="isCreationMode && !editedId" (editFinished)="onEditFinished()"></app-step-edit>
        </section>

        <section class="datatable-container">
            <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'repository.steps.list.name' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let step"> {{ step.name }} </td>
                </ng-container>

                <ng-container matColumnDef="contacts">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'repository.steps.list.contacts.default' | translate }} </th>
                    <td mat-cell *matCellDef="let step">
                        <ng-container *ngFor="let contact of step.contacts">
                            <ng-container *ngIf="contact.isDefault">
                                <span *ngIf="contact.isDefault">{{ contact.display }}</span>
                            </ng-container>

                        </ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="client">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Client </th>
                    <td mat-cell *matCellDef="let step">
                        {{ step.client.name }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="isDeposit">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'repository.steps.list.isDeposit' | translate }} </th>
                    <td mat-cell *matCellDef="let step">
                        <span *ngIf="step.isDeposit">Oui</span>
                        <span *ngIf="!step.isDeposit">Non</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'repository.steps.list.address' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let step">
                        <ng-container *ngIf="step.address">
                            {{ step.address.display }}
                        </ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="tags">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'repository.steps.list.tag' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let step">
                        <span *ngFor="let tag of step.tags">"{{tag}}" </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="timeOnSite">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'repository.steps.list.timeOnSite' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let step"> {{ step.timeOnSite }} </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> {{ 'repository.steps.list.actions' | translate }} </th>
                    <td mat-cell *matCellDef="let step">
                        <ng-container *ngIf="step.id !== editedId">
                            <button mat-icon-button class="action-button" color="accent" [disabled]="isLoading"
                                [matTooltip]="'repository.steps.list.edit' | translate" (click)="onEdit(step.id)">
                                <mat-icon aria-label="Edit">edit</mat-icon>
                            </button>
                        </ng-container>
                        <ng-container>
                            <button mat-icon-button class="action-button" color="accent"
                                [disabled]="isLoading"
                                [matTooltip]="'repository.steps.list.delete' | translate"
                                (click)="delete(step.id)">
                                <mat-icon aria-label="Delete">clear</mat-icon>
                            </button>
                        </ng-container>
                    </td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let step" [attr.colspan]="displayedColumns.length">
                        <div *ngIf="step.id === editedId" class="row-edit"
                            [@detailExpand]="step.id === editedId ? 'expanded' : 'collapsed'">
                            <app-step-edit [step]="step" (editFinished)="onEditFinished()"></app-step-edit>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row"
                    [ngClass]="{ 'expanded-row': row.id === editedId }"></tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
            </table>
        </section>
        <mat-progress-bar *ngIf="isLoading" class="progress-bar" mode="indeterminate" color="accent"></mat-progress-bar>
    </mat-card-content>
</mat-card>
