import { UsersService } from './../../shared/services/users.service';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';

import { FormEditBaseComponent } from '@components/form-edit-base.component';
import { UiService } from '@services/ui.service';
import { Template } from '@classes/template';
import { TemplatesService } from '@services/templates.service';
import { ClientsService } from '@services/clients.service';
import { StepsService } from '@services/steps.service';
import { Client } from '@classes/client';
import { Step } from '@classes/step/step';

@Component({
  selector: 'app-template-edit',
  templateUrl: './template-edit.component.html',
  styleUrls: ['./template-edit.component.scss']
})
export class TemplateEditComponent extends FormEditBaseComponent implements OnInit {
    @Input() template: Template;

    clients: Client[];
    availableSteps: Step[];
    formSteps: Step[];

    constructor(
        protected uiSrv: UiService,
        protected fb: FormBuilder,
        private templatesSrv: TemplatesService,
        private clientsSrv: ClientsService,
        private stepsSrv: StepsService,
        private usersSrv: UsersService
    ) {
        super(uiSrv, fb);
    }

    ngOnInit() {
        super.ngOnInit();
        this.getDropdownsContent();
    }

    onSubmit(): void {
        this.isLoading = true;
        if (this.template) {
            this.update();
        } else {
            this.create();
        }
    }

    onCancel(): void {
        this.editFinished.emit();
    }

    onStepsChanged(steps: Step[]): void {
        this.formSteps = steps;
    }

    private update(): void {
        const templateCopy: Template = JSON.parse(JSON.stringify(this.template));
        templateCopy.name = this.editForm.value.name;
        templateCopy.client = this.editForm.value.client;
        templateCopy.steps = this.formSteps;
        templateCopy.comment = this.editForm.value.comment;

        this.templatesSrv.put(templateCopy).subscribe(() => this.isLoading = false);
    }

    private create(): void {
        this.template = new Template(
            this.editForm.value.name,
            this.editForm.value.client,
            this.formSteps,
            this.editForm.value.comment
        );

        this.templatesSrv.post(this.template)
        .subscribe(
            () => this.isLoading = false,
            () => this.template = null
        );
    }

    private getDropdownsContent(): void {
        this.isLoading = true;

        const clientsPromise = new Promise((resolve, reject) => {
            this.clientsSrv.getList()
            .subscribe(clientsFromSrv => {
                this.clients = clientsFromSrv;
                resolve(clientsFromSrv);
            });
        });



        Promise.all([clientsPromise])
        .then(() => {
            this.initForm();
            this.isLoading = false;
        });
    }

    getStepsByClientId(clientId) {
        const stepsPromise = new Promise((resolve, reject) => {
            this.stepsSrv.getListByClientId(clientId)
            .subscribe(stepsFromSrv => {
                this.availableSteps = stepsFromSrv;
                resolve(stepsFromSrv);
            });
        });

        Promise.all([stepsPromise])
        .then(() => {
            this.editForm.addControl('steps', this.fb.array([]));
            this.isLoading = false;
        });
    }

    getSteps() {
        const stepsPromise = new Promise((resolve, reject) => {
            this.stepsSrv.getList()
            .subscribe(stepsFromSrv => {
                this.availableSteps = stepsFromSrv;
                resolve(stepsFromSrv);
            });
        });

        Promise.all([stepsPromise])
        .then(() => {
            this.editForm.addControl('steps', this.fb.array([]));
            this.isLoading = false;
        });
    }

    private initForm(): void {
        let name = "";
        let comment = "";
        let client = null;

        if (this.template) {
            name = this.template.name;
            comment = this.template.comment;
            client = this.template.client || null;
        }

        this.editForm = this.fb.group({
            name: [name, Validators.required],
            client: [client, Validators.required],
            comment: [comment, Validators.maxLength(500)]
        });


        if (this.usersSrv.clientSelected === 'all') {
            if (this.template) {
                this.getStepsByClientId(this.template.client.id);
            }
            this.editForm.get('client').valueChanges.subscribe(client => {
                if (typeof client !== 'string') {
                    if (this.isControlSet('steps')) {
                        this.editForm.removeControl('steps');
                    }
                    this.getStepsByClientId(client.id);
                }
            })
        } else {
            this.getSteps();
        }


    }

    isControlSet(control: string): boolean {
        return !!this.editForm.get(control);
    }
}
