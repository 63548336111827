import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { AuthService } from '@services/auth.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
    resetForm: FormGroup;
    isLoading: boolean;
    resetPasswordSent: boolean;

    constructor(
        private authSrv: AuthService,
    ) {}

    ngOnInit() {
        this.initForm();
    }

    onSubmit() {
        this.isLoading = true;

        this.authSrv.requestPasswordReset(this.resetForm.value.username)
        .subscribe(() => {
            this.isLoading = false;
            this.resetPasswordSent = true;
        });
    }

    private initForm() {
        this.resetForm = new FormGroup({
            username: new FormControl('', {
                validators: [Validators.required]
            })
        });
    }
}
