<form class="edit-form" *ngIf="!isLoading" [formGroup]="editForm" (submit)="onSubmit()">
    <section class="clientSection">
        <app-autocomplete [control]="getControl('client')" [list]="clients" label="missions.create.client.label"
            errorMsg="missions.create.client.errorMessage"></app-autocomplete>
    </section>

    <mat-form-field>
        <mat-label>{{ 'repository.addresses.edit.street.label' | translate }}</mat-label>
        <input type="text" matInput formControlName="street">
        <mat-error>{{ 'repository.addresses.edit.street.errorMessage' | translate }}</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ 'repository.addresses.edit.streetNumber.label' | translate }}</mat-label>
        <input type="text" matInput formControlName="streetNumber">
        <mat-error>{{ 'repository.addresses.edit.streetNumber.errorMessage' | translate }}</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ 'repository.addresses.edit.locality.label' | translate }}</mat-label>
        <input type="text" matInput formControlName="locality">
        <mat-error>{{ 'repository.addresses.edit.locality.errorMessage' | translate }}</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ 'repository.addresses.edit.postalCode.label' | translate }}</mat-label>
        <input type="text" matInput formControlName="postalCode">
        <mat-error>{{ 'repository.addresses.edit.postalCode.errorMessage' | translate }}</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ 'repository.addresses.edit.latitude.label' | translate }}</mat-label>
        <input type="text" matInput formControlName="latitude">
        <mat-error>{{ 'repository.addresses.edit.latitude.errorMessage' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{ 'repository.addresses.edit.longitude.label' | translate }}</mat-label>
        <input type="text" matInput formControlName="longitude">
        <mat-error>{{ 'repository.addresses.edit.longitude.errorMessage' | translate }}</mat-error>
    </mat-form-field>

    <section class="actions">
        <button *ngIf="!isLoading" type="submit" mat-icon-button color="accent" [disabled]="editForm.invalid">
            <mat-icon aria-label="Validate">check</mat-icon>
        </button>
        <button *ngIf="!isLoading" type="button" mat-icon-button (click)="onCancel()">
            <mat-icon aria-label="Cancel">clear</mat-icon>
        </button>
    </section>

</form>
<mat-spinner *ngIf="isLoading" [diameter]="35" color="accent"></mat-spinner>
