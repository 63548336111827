import { BaseClass } from '../base-class';
import { Role } from '../role';
import { UserDetail } from './user-detail';
import { Client } from '../client';
import { BaseRepository } from '@interfaces/base-repository';

export class User extends BaseClass implements BaseRepository {
    username: string;
    email: string;
    isActive: boolean;
    lastLogonOn: Date;
    externalId?: string;
    role: Role;
    detail: UserDetail;
    client?: Client;
    clients: Client[];
    toNotify?: boolean;
    byMail?: boolean;
    bySms?: boolean;

    constructor()
    constructor(username: string, email: string, role: Role, clients: Client[],
                firstname?: string, lastname?: string, phone?: string)
    constructor(username?: string, email?: string, role?: Role, clients?: Client[],
                firstname?: string, lastname?: string, phone?: string) {
        super();
        this.username =this.trim(username);
        this.email = this.trim(email).toLowerCase();
        this.role = role || null;
        this.clients = clients || [];
        this.detail = new UserDetail(firstname, lastname, phone);
    }

    static fromObject(object: User): User {
        return Object.assign(new User(), {
            ...object,
            clients: object.clients.map(o => Client.fromObject(o))
        });
    }

    get display(): string {
        return this.username;
    }

    doesInclude(value: string): boolean {
        return this.some([this.username], value);
    }
}
