<mat-card class="login-form" *ngIf="!isExpired">
    <mat-card-header>
        <mat-card-title>
            <h1>{{'auth.changePassword.title' | translate}}</h1>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
            <mat-form-field *ngIf="!isResetMode">
                <mat-label>{{'auth.changePassword.currentPassword.label' | translate}}</mat-label>
                <mat-icon matPrefix>lock</mat-icon>
                <input type="password" matInput formControlName="currentPassword">
                <mat-error>{{'auth.changePassword.currentPassword.errorMessage' | translate}}</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'auth.changePassword.newPassword.label' | translate}}</mat-label>
                <mat-icon matPrefix>lock</mat-icon>
                <input type="password" matInput formControlName="newPassword">
                <mat-error>{{'auth.changePassword.newPassword.errorMessage' | translate}}</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'auth.changePassword.confirmPassword.label' | translate}}</mat-label>
                <mat-icon matPrefix>lock</mat-icon>
                <input type="password" matInput formControlName="confirmPassword" [errorStateMatcher]="confirmValidMatcher">
                <mat-error>{{'auth.changePassword.confirmPassword.errorMessage' | translate}}</mat-error>
            </mat-form-field>
            <button *ngIf="!isLoading" type="submit" mat-raised-button color="accent" [disabled]="passwordForm.invalid">
                {{'global.actions.submit' | translate}}
            </button>
            <mat-spinner *ngIf="isLoading" [diameter]="35" color="accent"></mat-spinner>
        </form>
    </mat-card-content>
</mat-card>

<!-- LINK EXPIRED -->
<mat-card class="login-form" *ngIf="isExpired">
    <mat-card-header>
        <mat-card-title>
            <h1>{{'auth.changePassword.linkExpired.title' | translate}}</h1>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="expired-message" [innerHTML]="'auth.changePassword.linkExpired.detail' | translate"></div>
    </mat-card-content>
</mat-card>
