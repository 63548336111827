<button mat-button [matMenuTriggerFor]="userMenu">
    {{ user.username }} ({{ 'roles.' + user.role.name | translate }})
    <mat-icon aria-label="Account"> account_circle </mat-icon>
</button>

<mat-menu #userMenu="matMenu">
    <button mat-button (click)="onLogout()" color="accent">
        <span>{{'main.header.logout' | translate}}</span>
        <mat-icon aria-label="Exit">exit_to_app</mat-icon>
    </button>
</mat-menu>
