import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { StepReport } from '@classes/step/step-report';
import { ReportService } from '@services/report.service';
import { UiService } from '@services/ui.service';

@Component({
    selector: 'app-step-report',
    templateUrl: './step-report.component.html',
    styleUrls: ['./step-report.component.scss']
})
export class StepReportComponent implements OnInit, OnDestroy {
    report: StepReport;
    isLoading: boolean;
    selectedSampleId: string;

    private subscription = new Subscription();

    constructor(
        private reportSrv: ReportService,
        private uiSrv: UiService
    ) { }

    ngOnInit() {
        this.initSubscriptions();
    }

    onGetAttachment(id: string): void {
        this.isLoading = true;
        this.selectedSampleId = id;
        this.reportSrv.getAttachment(id).subscribe(() => this.isLoading = false);
    }

    private initSubscriptions(): void {
        this.subscription.add(
            this.reportSrv.selectedReportSubject
            .subscribe(selectedReport => {
                this.report = selectedReport;
            })
        );

        this.subscription.add(
            this.uiSrv.loadingStateChanged
            .subscribe(loadingState => this.isLoading = loadingState)
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
