import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MissionNotification } from '@classes/notification/mission-notification';
import { NotificationsService } from '@services/notifications.service';
import { Pagination } from '@classes/pagination/pagination';
import { PageEvent } from '@angular/material/paginator';
import { PaginationRequest } from '@classes/pagination/pagination-request';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
    private subscription = new Subscription();
    notifications: MissionNotification[] = [];
    isLoading: boolean;

    filterValue = null;
    sortValue: string;
    pagination: Pagination;


    constructor(
        private notificationsSrv: NotificationsService
    ) { }

    ngOnInit() {
        this.initSubscriptions();
    }

    onClear(): void {
        this.notificationsSrv.clear().subscribe();
    }

    private initSubscriptions(): void {
        // this.notificationsSrv.getList(new PaginationRequest());

        this.subscription.add(
            this.notificationsSrv.notificationsSubject
                .subscribe(notifications => {
                    this.notifications = notifications;
                })
        );

        this.subscription.add(
            this.notificationsSrv.paginatioNotificationSubject
            .subscribe(pagination => {
                this.pagination = pagination;
            })
        );

        this.subscription.add(
            this.notificationsSrv.loadingStateChanged
                .subscribe(loadingState => {
                    this.isLoading = loadingState;
                })
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onPageChanged(pageEvent: PageEvent): void {
        this.pagination.pageNumber = pageEvent.pageIndex + 1;
        this.getList();
    }

    private getList(): void {
        const newRequest = new PaginationRequest(
            this.filterValue,
            this.sortValue,
            this.pagination.pageNumber,
            12
        );

        this.notificationsSrv.getList(newRequest);
    }
}
