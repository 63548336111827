import { UsersService } from './../../shared/services/users.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { trigger, state, style } from '@angular/animations';

import { DatatableBaseComponent } from '@components/datatable-base.component';
import { UiService } from '@services/ui.service';
import { Address } from '@classes/address';
import { AddressesService } from '@services/addresses.service';
import { AuthService } from '@services/auth.service';
import { RoleName } from '@enums/role-name.enum';

@Component({
    selector: 'app-addresses',
    templateUrl: './addresses.component.html',
    styleUrls: ['./addresses.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' }))
        ])
    ]
})
export class AddressesComponent extends DatatableBaseComponent implements OnInit {
    addresses: Address[];

    displayedColumns = ['street', 'streetNumber', 'locality', 'postalCode', 'coordinates', 'client', 'actions'];
    dataSource: MatTableDataSource<Address>;

    isAdminClient: boolean = false;

    constructor(
        protected uiSrv: UiService,
        private route: ActivatedRoute,
        private addressesSrv: AddressesService,
        private usersSrv: UsersService,
        private authSrv: AuthService
    ) {
        super(uiSrv);
    }

    ngOnInit() {
        super.ngOnInit();
        this.isAdminClient = this.authSrv.getUser().role.name === RoleName.admin_labo;
        if (this.isAdminClient) this.displayedColumns.pop();

        this.getRouteData();
        this.initSubscriptions();
    }

    private initTable(): void {
        this.dataSource = new MatTableDataSource(this.addresses);
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (item: any, property) => {
            switch (property) {
                case 'client': return item.client.name;
                default: return item[property];
            }
        };
    }

    private getRouteData(): void {
        this.route.data
            .subscribe((data: { addresses: Address[] }) => {
                this.addresses = data.addresses;
                this.initTable();
            });
    }

    private initSubscriptions(): void {
        this.addSubscription(
            this.addressesSrv.addressesListChanged
                .subscribe(addresses => {
                    this.dataSource.data = addresses || [];
                    this.dataSource.sort = this.sort;
                    this.isLoading = false;
                    this.onEditFinished();
                })
        );


        this.addSubscription(
            this.usersSrv.clientChangedSubject.subscribe(() => {
                this.isLoading = true;
                this.addSubscription(
                    this.addressesSrv.getList().subscribe()
                )
            })
        )
    }

    delete(idAdresse: string) {
        const confirmation = confirm('Êtes-vous certain de vouloir supprimer cette adresse ?');

        if (confirmation) {
            this.addressesSrv.delete(idAdresse).subscribe(() => {
                this.uiSrv.showSnackbar('repository.addresses.deleted', true);
            });
        }

    }
}
