import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import {
    TranslateModule,
    TranslateLoader,
    MissingTranslationHandler
} from '@ngx-translate/core';

import { environment } from '@environments/environment';

import { ApiMissingTranslationHandler } from './shared/apiMissingTranslationHandler';
import { ApiTranslateLoader } from './shared/apiTranslationLoader';
import { appInitializerProviders } from './shared/appInitializerProviders';
import { httpInterceptorProviders } from './shared/http-interceptors';
import { resolverProviders } from './shared/resolvers';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { MainModule } from './main/main.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { AuthModule } from './auth/auth.module';
import { RepositoryModule } from './repository/repository.module';
import { MissionsModule } from './missions/missions.module';
import { ParametersModule } from './parameters/parameters.module';
import { UsersModule } from './users/users.module';
import { TemplatesModule } from './templates/templates.module';
import { TemperaturesModule } from './temperatures/temperatures.module';

import { AppComponent } from './app.component';
import { ErrorComponent } from './error/error.component';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

registerLocaleData(localeFr);

export function tokenGetterFactory(): string {
    return localStorage.getItem(environment.tokenKey);
}

export function TranslateLoaderFactory(http: HttpClient): ApiTranslateLoader {
    return new ApiTranslateLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        ErrorComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        SharedModule,
        MainModule,
        DashboardModule,
        AuthModule,
        RepositoryModule,
        MissionsModule,
        ParametersModule,
        UsersModule,
        TemplatesModule,
        TemperaturesModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetterFactory,
                whitelistedDomains: [environment.apiDomain],
                blacklistedRoutes: [environment.identityApiUrl + 'connect/token', environment.identityApiMworkerUrl + 'connect/token']
            }
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: TranslateLoaderFactory,
                deps: [HttpClient]
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: ApiMissingTranslationHandler
            }
        })
    ],
    providers: [
        httpInterceptorProviders,
        appInitializerProviders,
        resolverProviders
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
