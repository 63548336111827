import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, map } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { Template } from '@classes/template';

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {
    templatesListChanged = new Subject<Template[]>();
    private baseUrl: string = environment.apiUrl + 'templates';
    private templates: Template[];

    constructor(
        private http: HttpClient
    ) { }

    getList(): Observable<Template[]> {
        return this.http.get<Template[]>(this.baseUrl)
        .pipe(
            map(templates => {
                this.templates = templates.map(t => Template.fromObject(t));
                this.templatesListChanged.next([ ...this.templates ]);
                return [ ...this.templates ];
            })
        );
    }

    getListByClientId(clientId): Observable<Template[]> {
        return this.http.get<Template[]>(`${this.baseUrl}?clientId=${clientId}`)
        .pipe(
            map(templates => {
                this.templates = templates.map(t => Template.fromObject(t));
                this.templatesListChanged.next([ ...this.templates ]);
                return [ ...this.templates ];
            })
        );
    }

    put(template: Template): Observable<Template> {
        return this.http.put<Template>(this.baseUrl, template)
        .pipe(
            tap(editedTemplate => {
                editedTemplate = Template.fromObject(editedTemplate);
                this.templates = this.templates.map(t => t.id === editedTemplate.id ? editedTemplate : t);
                this.templatesListChanged.next([ ...this.templates ]);
            })
        );
    }

    post(template: Template): Observable<Template> {
        return this.http.post<Template>(this.baseUrl, template)
        .pipe(
            tap(createdTemplate => {
                createdTemplate = Template.fromObject(createdTemplate);
                this.templates.push(createdTemplate);
                this.templatesListChanged.next([ ...this.templates ]);
            })
        );
    }

    delete(id: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${id}`)
        .pipe(
            tap(() => {
                this.templates = this.templates.filter(t => t.id !== id);
                this.templatesListChanged.next([ ...this.templates ]);
            })
        );
    }
}
