<ng-container *ngIf="link.children; else listItem">
    <mat-expansion-panel [class.mat-elevation-z0]="true" dense class="nav-expansion">
        <mat-expansion-panel-header routerLinkActive="active">
            <mat-panel-title
                [routerLink]="link.route"
                [ngClass]="{ disabled: isRouteLoading }" >
                <mat-icon aria-label="Nav">{{link.icon}}</mat-icon>
                <span class="link-label">{{link.title | translate}}</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list>
            <app-nav-item *ngFor="let child of link.children" [link]="child"></app-nav-item>
        </mat-nav-list>
    </mat-expansion-panel>
</ng-container>

<ng-template #listItem>
    <a mat-list-item class="nav-item" *ngIf="link.route"
        [routerLink]="link.route" [attr.disabled]="isRouteLoading"
        [ngClass]="{ disabled: isRouteLoading}"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{exact: true}">
        <mat-icon aria-label="Nav">{{link.icon}}</mat-icon>
        <span class="link-label">{{link.title | translate}}</span>
    </a>
</ng-template>
