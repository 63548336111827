import { Component, OnInit, OnDestroy } from '@angular/core';
import {
    FormGroup,
    Validators,
    FormBuilder,
    ValidationErrors
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

import { AuthService } from '@services/auth.service';
import { UiService } from '@services/ui.service';
import { ConfirmValidMatcher } from '@classes/validations/confirm-valid-matcher';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
    private subscription = new Subscription();

    passwordForm: FormGroup;
    isResetMode: boolean;
    isExpired: boolean;
    isLoading: boolean;

    confirmValidMatcher = new ConfirmValidMatcher();

    token: string;
    private tokenKey: string = environment.tokenKey;
    private rfTokenKey: string = environment.rfTokenKey;
    private tokenExpKey: string = environment.tokenExpKey;
    // private tokenMworkerKey: string = environment.tokenMworkerKey;
    // private rfTokenMworkerKey: string = environment.rfTokenMworkerKey;
    // private tokenMworkerExpKey: string = environment.tokenMworkerExpKey;

    id: string;

    constructor(
        private authSrv: AuthService,
        private fb: FormBuilder,
        private uiSrv: UiService,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.checkRouteParams();
        this.initForm();
        this.initSubscriptions();
    }

    onSubmit(): void {
        this.isLoading = true;

        if (this.isResetMode) {
            this.authSrv.resetPassword(this.id, this.token, this.passwordForm.value.newPassword).subscribe();
        } else {
            this.authSrv.updatePassword(this.passwordForm.value).subscribe();
        }
    }

    private checkRouteParams(): void {
        this.token = this.route.snapshot.queryParams.token;
        this.id = this.route.snapshot.queryParams.id;

        const exp = this.route.snapshot.queryParams.exp;
        const username = this.route.snapshot.queryParams.user;
        // const expDate = moment(exp, "DD-MM-yy hh:mm:ss TZD");
        // const now = moment();
        const expDate = new Date(moment(exp, "DD-MM-YY hh:mm:ss TZD").toString());
        const now = new Date();

        if (this.token && this.id && exp && username) {
            // Logout if user is already logged in with another account to avoid confusion
            if (this.authSrv.getDecodedToken()) {
                // localStorage.clear();
                localStorage.removeItem(this.tokenKey);
                localStorage.removeItem(this.tokenExpKey);
                localStorage.removeItem(this.rfTokenKey);
                // localStorage.removeItem(this.tokenMworkerKey);
                // localStorage.removeItem(this.tokenMworkerExpKey);
                // localStorage.removeItem(this.rfTokenMworkerKey);
                this.authSrv.authChanged.next(false);
            }

            if (expDate.getTime() < now.getTime()) {
                this.isExpired = true;
                this.authSrv.requestPasswordInit(username).subscribe();
            } else {
                this.isResetMode = true;
            }
        }
    }

    private initForm(): void {
        this.passwordForm = this.fb.group({
            newPassword: ['', [Validators.required, Validators.minLength(5)]],
            confirmPassword: ['', Validators.required]
        }, { validators: this.passwordMatchValidator });

        if (!this.isResetMode) {
            this.passwordForm.addControl('currentPassword', this.fb.control('', [Validators.required]));
        }
    }

    // Check if password and confirmPassword matches
    private passwordMatchValidator(control: FormGroup): ValidationErrors | null {
        const newPassword = control.get('newPassword');
        const confirmPassword = control.get('confirmPassword');

        return newPassword.value !== confirmPassword.value ? { mismatch: true } : null;
    }

    private initSubscriptions(): void {
        this.isLoading = false;
        this.subscription.add(
            this.uiSrv.loadingStateChanged
                .subscribe(loadingState => {
                    this.isLoading = loadingState;
                })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
