<mat-card>
    <mat-card-header>
        <mat-card-title>
            <h1>{{ 'missions.title' | translate }}</h1>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <section class="headerFilter">
            <form *ngIf="missions" [formGroup]="filterForm" (submit)="onFilter()" class="filterForm">
                <mat-form-field>
                    <mat-label>Nom de la mission</mat-label>
                    <input matInput type="text" formControlName="name">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Template</mat-label>
                    <input matInput type="text" formControlName="template">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Statut</mat-label>
                    <mat-select formControlName="status">
                        <mat-option *ngFor="let status of allStatus" [value]="status.value">
                            {{status.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Coursier</mat-label>
                    <input matInput type="text" formControlName="user">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{ 'missions.list.client' | translate }}</mat-label>
                    <input matInput type="text" formControlName="client">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Dispatcheur</mat-label>
                    <input matInput type="text" formControlName="createdBy">
                </mat-form-field>


                <section class="step-field" *ngIf="!isLoadingSteps">
                    <app-autocomplete [control]="getControl('step')" [list]="steps" [noDefault]="true"
                        label="missions.list.filters.step"></app-autocomplete>
                </section>

                <mat-spinner class="spinnerStep" color="accent" diameter="30" *ngIf="isLoadingSteps"></mat-spinner>

                <mat-form-field>
                    <mat-label>Du</mat-label>
                    <input matInput formControlName="fromStartDate" [matDatepicker]="fromStartDatePicker">
                    <mat-datepicker-toggle matSuffix [for]="fromStartDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #fromStartDatePicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>Au</mat-label>
                    <input matInput formControlName="toStartDate" [matDatepicker]="toStartDatePicker">
                    <mat-datepicker-toggle matSuffix [for]="toStartDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #toStartDatePicker></mat-datepicker>
                </mat-form-field>

                <button mat-icon-button type="submit" color="accent" [disabled]="filterForm.pristine"
                    matTooltip="Filtrer">
                    <mat-icon>
                        search
                    </mat-icon>
                </button>

                <button mat-icon-button color="accent" *ngIf="!filterForm.pristine" matTooltip="Effacer le filtre"
                    (click)="clearFilters()">
                    <mat-icon>
                        clear
                    </mat-icon>
                </button>
            </form>
            <section class="actionsButtons">
                <div *ngIf="isAdmin" matTooltip="Vous devez sélectionner des missions terminées ou annulées."
                    [matTooltipDisabled]="canDelete" class="divDelete">
                    <button mat-raised-button color="accent" class="export-btn" (click)="onDelete()"
                        [disabled]="isLoading || selection.selected.length === 0 || !canDelete">
                        <mat-icon aria-label="File">delete</mat-icon>
                        {{ 'missions.list.delete' | translate }}
                    </button>
                </div>

                <button mat-raised-button color="accent" class="export-btn" (click)="onExport()"
                    [disabled]="isLoading || selection.selected.length === 0">
                    <mat-icon aria-label="File">insert_drive_file</mat-icon>
                    {{ 'missions.list.export' | translate }}
                </button>
                <button mat-raised-button color="accent" class="export-btn pdfButton" (click)="onExportRoadMap()"
                    [disabled]="isLoading || selection.selected.length === 0">
                    <mat-icon aria-label="File">picture_as_pdf</mat-icon>
                    Feuille de route
                </button>
            </section>
        </section>


        <section class="datatable-container">

            <div class="menuDisplayedColumns">
                <button mat-mini-fab color="accent" matTooltip="Préférences des colonnes" [matMenuTriggerFor]="menu">
                    <mat-icon>list</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <div cdkDropList (cdkDropListDropped)="drop($event)">
                        <div *ngFor="let displayedColumn of displayedColumnsMenu" cdkDrag>
                            <section class="menu-item" (click)="$event.stopPropagation()">
                                <ng-container *ngIf="displayedColumn.editable">
                                    <mat-checkbox class="example-margin" [checked]="displayedColumn.selected"
                                        (change)="onSelectMenu($event,displayedColumn.name)">
                                        {{ 'missions.list.' + displayedColumn.name | translate }}
                                    </mat-checkbox>
                                </ng-container>
                            </section>
                        </div>
                    </div>
                </mat-menu>
            </div>

            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="onSortChanged($event)">
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef class="checkboxCell">
                        <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row" class="checkboxCell">
                        <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'missions.list.name' | translate }} </th>
                    <td mat-cell *matCellDef="let mission"> {{ mission.name }} </td>
                </ng-container>
                <ng-container matColumnDef="template">
                    <th mat-header-cell *matHeaderCellDef> {{ 'missions.list.template' | translate }} </th>
                    <td mat-cell *matCellDef="let mission"> {{ mission.template?.name }} </td>
                </ng-container>
                <ng-container matColumnDef="steps.length">
                    <th mat-header-cell *matHeaderCellDef class="center"> {{'missions.list.steps.length' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let mission" class="center"> {{ mission.steps.length}} </td>
                </ng-container>
                <ng-container matColumnDef="completion">
                    <th mat-header-cell *matHeaderCellDef class="center"> {{ 'missions.list.completion' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let mission" class="center">
                        {{ calcMissionCompletion(mission.steps) }}
                        <span *ngIf="completedStepsNumber(mission) > 0">({{ completedStepsNumber(mission) }})</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="timeLeft">
                    <th mat-header-cell *matHeaderCellDef class="center"> {{'missions.list.timeLeft' | translate}} </th>
                    <td mat-cell *matCellDef="let mission" class="center">
                        <span *ngIf="mission.status === 1 || mission.status === 2 || mission.status === 4">{{
                            timeLeft(mission) }} min </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="timeSpent">
                    <th mat-header-cell *matHeaderCellDef class="center"> {{'missions.list.timeSpent' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let mission" class="center">
                        <span *ngIf="mission.status === 8">{{ timeSpent(mission) }} min </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="totalSamples">
                    <th mat-header-cell *matHeaderCellDef class="center"> {{'missions.list.totalSamples' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let mission" class="center">
                        <span>{{ getTotalSamples(mission) }} </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="nextStepName">
                    <th mat-header-cell *matHeaderCellDef> {{'missions.list.nextStepName' | translate}} </th>
                    <td mat-cell *matCellDef="let mission">
                        <span *ngIf="mission.status !==  8 && mission.status !==  16">{{ getNextStepName(mission) }}
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="nextStepAddress">
                    <th mat-header-cell *matHeaderCellDef> {{'missions.list.nextStepAddress' | translate}}</th>
                    <td mat-cell *matCellDef="let mission">
                        <span *ngIf="mission.status !==  8 && mission.status !==  16">{{ getNextStepAddress(mission) }}
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="timeEnd">
                    <th mat-header-cell *matHeaderCellDef class="center"> {{'missions.list.timeEnd' | translate}}</th>
                    <td mat-cell *matCellDef="let mission" class="center">
                        <span *ngIf="mission.status !== 1 && mission.status !==  8 && mission.status !==  16">
                            {{ getTimeEndMission(mission) | amDateFormat: 'HH:mm'}}
                        </span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="createdOn">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'missions.list.createdOn' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let mission"> {{ mission.createdOn | amDateFormat: 'DD/MM/YYYY' }} </td>
                </ng-container>
                <ng-container matColumnDef="modifiedOn">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'missions.list.modifiedOn' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let mission"> {{ mission.modifiedOn | amDateFormat: 'DD/MM/YYYY' }} </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'missions.status.title' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let mission"> {{ showStatusText(mission.status) | translate }} </td>
                </ng-container>
                <ng-container matColumnDef="user">
                    <th mat-header-cell *matHeaderCellDef> {{ 'missions.list.user' | translate }} </th>
                    <!-- <th mat-header-cell *matHeaderCellDef mat-sort-header="user.username"> {{ 'missions.list.user' | translate }} </th> -->
                    <td mat-cell *matCellDef="let mission"> {{ mission.user?.username }} </td>
                </ng-container>
                <ng-container matColumnDef="client">
                    <th mat-header-cell *matHeaderCellDef> {{ 'missions.list.client' | translate }} </th>
                    <!-- <th mat-header-cell *matHeaderCellDef mat-sort-header="client.name"> {{ 'missions.list.client' | translate }} </th> -->
                    <td mat-cell *matCellDef="let mission"> {{ mission.client?.name }} </td>
                </ng-container>
                <ng-container matColumnDef="startDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'missions.list.startDate' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let mission"> {{ mission.startDate | amDateFormat: 'DD/MM/YYYY' }} </td>
                </ng-container>
                <ng-container matColumnDef="createdBy">
                    <th mat-header-cell *matHeaderCellDef> {{ 'missions.list.createdBy' | translate }} </th>
                    <!-- <th mat-header-cell *matHeaderCellDef mat-sort-header="createdBy.username"> {{ 'missions.list.createdBy' | translate }} </th> -->
                    <td mat-cell *matCellDef="let mission"> {{ mission.createdBy?.username }} </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> {{ 'missions.list.actions' | translate }} </th>
                    <td mat-cell *matCellDef="let mission">
                        <button mat-icon-button class="action-button" color="accent" [disabled]="isLoading"
                            [matTooltip]="'missions.list.detail' | translate" (click)="onSelectMission(mission.id)">
                            <mat-icon aria-label="Edit">zoom_in</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row"></tr>
            </table>
        </section>
        <mat-progress-bar *ngIf="isLoading" class="progress-bar" mode="indeterminate" color="accent"></mat-progress-bar>
        <mat-paginator *ngIf="pagination" [pageSize]="pagination.pageSize" [length]="pagination.totalRecords"
            [pageIndex]="pagination.pageNumber - 1" (page)="onPageChanged($event)" showFirstLastButtons></mat-paginator>
    </mat-card-content>
</mat-card>