<mat-card>
    <mat-card-header>
        <mat-card-title>
            <h1>{{ 'repository.samples.title' | translate }}</h1>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <section class="creation-form">
            <button *ngIf="!isCreationMode" (click)="onEdit()"
                [matTooltip]="'repository.samples.list.add' | translate"
                mat-mini-fab class="action-button" color="accent">
                <mat-icon aria-label="Add">add</mat-icon>
            </button>
            <app-sample-edit *ngIf="isCreationMode && !editedId"
                [sortOrders]="sortOrders"
                (editFinished)="onEditFinished()"></app-sample-edit>
        </section>

        <section class="datatable-container">
            <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
                <ng-container matColumnDef="sortOrder">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'repository.samples.list.sortOrder' | translate }} </th>
                    <td mat-cell *matCellDef="let sample"> {{ sample.sortOrder }} </td>
                </ng-container>

                <ng-container matColumnDef="client">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Client </th>
                    <td mat-cell *matCellDef="let sample"> {{ sample.client.name }} </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'repository.samples.list.name' | translate }} </th>
                    <td mat-cell *matCellDef="let sample"> {{ sample.name }} </td>
                </ng-container>

                <ng-container matColumnDef="lowThreshold">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'repository.samples.list.lowThreshold' | translate }} </th>
                    <td mat-cell *matCellDef="let sample"> {{ sample.lowThreshold }} </td>
                </ng-container>

                <ng-container matColumnDef="highThreshold">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'repository.samples.list.highThreshold' | translate }} </th>
                    <td mat-cell *matCellDef="let sample"> {{ sample.highThreshold }} </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> {{ 'repository.samples.list.actions' | translate }} </th>
                    <td mat-cell *matCellDef="let sample">
                        <ng-container *ngIf="sample.id !== editedId">
                            <button mat-icon-button class="action-button" color="accent"
                                [disabled]="isLoading"
                                [matTooltip]="'repository.samples.list.edit' | translate"
                                (click)="onEdit(sample.id)">
                                <mat-icon aria-label="Edit">edit</mat-icon>
                            </button>
                        </ng-container>
                        <ng-container>
                            <button mat-icon-button class="action-button" color="accent"
                                [disabled]="isLoading"
                                [matTooltip]="'repository.samples.list.delete' | translate"
                                (click)="delete(sample.id)">
                                <mat-icon aria-label="Delete">clear</mat-icon>
                            </button>
                        </ng-container>
                    </td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let sample" [attr.colspan]="displayedColumns.length">
                        <div *ngIf="sample.id === editedId" class="row-edit"
                            [@detailExpand]="sample.id === editedId ? 'expanded' : 'collapsed'">
                            <app-sample-edit [sample]="sample" [sortOrders]="sortOrders" (editFinished)="onEditFinished()"></app-sample-edit>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row"
                    [ngClass]="{ 'expanded-row': row.id === editedId }"></tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
            </table>
        </section>
        <mat-progress-bar *ngIf="isLoading"  class="progress-bar" mode="indeterminate" color="accent"></mat-progress-bar>
    </mat-card-content>
</mat-card>
