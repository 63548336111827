import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { UiService } from "@services/ui.service";
import { ClientsService } from '@services/clients.service';
import { Client } from '@classes/client';


@Injectable()
export class ClientsResolver implements Resolve<Client[]> {
    constructor(
        private clientsSrv: ClientsService,
        private uiSrv: UiService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Client[]> {
        this.uiSrv.routeLoadingStateChanged.next(true);

        return this.clientsSrv.getList()
        .pipe(
            tap(() => {
                this.uiSrv.routeLoadingStateChanged.next(false);
            })
        );
    }
}
