import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, FormControl } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { Step } from '@classes/step/step';
import { StepFormGroup } from '@interfaces/step-form-group';
import { Mission } from '@classes/mission/mission';
import { MissionsService } from '@services/missions.service';

@Component({
    selector: 'app-steps-form',
    templateUrl: './steps-form.component.html',
    styleUrls: ['./steps-form.component.scss']
})
export class StepsFormComponent implements OnInit {
    @Input() steps: Step[];
    @Input() availableSteps: Step[];
    // @Input() missions: Mission[];
    @Output() stepsChanged = new EventEmitter<Step[]>();

    lockedSteps: Step[];
    startOrder = 1;

    stepsForm: FormGroup;
    // copyStep = new FormControl();

    constructor(
        private fb: FormBuilder,
        private missionsSrv: MissionsService
    ) { }

    ngOnInit() {
        this.initForm();
    }

    get stepsFields() {
        return this.stepsForm.get('steps') as FormArray;
    }

    get totalEstimatedTime(): number {
        const locked = this.lockedSteps.reduce((total: number, step: Step) => {
            return total + step.estimatedTime + (step.timeOnSite || 0);
        }, 0);

        const form = this.stepsFields.value.reduce((total: number, step: StepFormGroup) => {
            return total + step.estimatedTime + (step.step ? step.step.timeOnSite : 0);
        }, 0);

        return locked + form;
    }

    onAddStep(step?: Step): void {
        this.stepsFields.push(
            this.fb.group({
                step: [step || null],
                estimatedTime: [step ? step.estimatedTime : 0],
                onCall: [step ? step.onCall : false]
            })
        );
    }

    onRemoveStep(index: number): void {
        this.stepsFields.removeAt(index);
    }

    onDropStep(event: CdkDragDrop<string[]>) {
        // Update controls order and values
        moveItemInArray(this.stepsFields.controls, event.previousIndex, event.currentIndex);
        moveItemInArray(this.stepsFields.value, event.previousIndex, event.currentIndex);

        this.emitStepsFromGroups(this.stepsFields.value);
    }

    private initForm(): void {
        let stepsToEdit: Step[] = [];
        this.lockedSteps = [];

        if(this.steps && this.steps.length) {
            this.lockedSteps = this.steps.filter(s => s.arrivedOn != null);
            stepsToEdit = this.steps.filter(s => s.arrivedOn == null);
        }

        this.startOrder = this.lockedSteps.length + 1;

        this.stepsForm = this.fb.group({
            steps: this.fb.array([])
        });

        if(stepsToEdit.length) {
            stepsToEdit.map(step => this.onAddStep(step));
        } else {
            this.onAddStep();
        }

        this.stepsForm.controls.steps.valueChanges
        .subscribe((stepGroups: StepFormGroup[]) => {
            this.emitStepsFromGroups(stepGroups);
        });
    }

    private emitStepsFromGroups(stepGroups: StepFormGroup[]): void {
        const stepsList: Step[] = stepGroups.reduce((steps: Step[], stepGroup: StepFormGroup, index: number) => {
            if(!stepGroup.step || typeof stepGroup.step === 'string') {
                return [ ...steps ];
            }

            const step: Step = { ...stepGroup.step } as Step;
            step.sortOrder = index + 1;
            step.estimatedTime = stepGroup.estimatedTime;
            step.onCall = stepGroup.onCall;


            return [ ...steps, step];
        }, []);

        this.stepsChanged.emit([ ...this.lockedSteps, ...stepsList ]);
    }
}
