<mat-card class="samplesContainer" [ngClass]="{'isUserLabo': isUserLabo}">
    <mat-card-header>
        <mat-card-title>
            <h2>{{ 'dashboard.samplesList' | translate }}</h2>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-list>
            <mat-list-item *ngFor="let sample of samples" class="listItemDashboard">
                <span class="label">{{ sample.name }}</span> <span>{{ sample.quantity }}</span>
            </mat-list-item>
        </mat-list>
    </mat-card-content>
</mat-card>
