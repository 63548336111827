import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';

import { AppConfigService } from '@services/app-config.service';
import { UiService } from '@services/ui.service';
import { AuthService } from '@services/auth.service';
import { NotificationsService } from '@services/notifications.service';
import { Pagination } from '@classes/pagination/pagination';
import { PaginationRequest } from '@classes/pagination/pagination-request';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    private subscription = new Subscription();

    isAuth: boolean;
    isMaintenance: boolean;
    isError: boolean;
    isRouteLoading: boolean;
    isTranslationLoading: boolean;

    navMode = "side";
    mobileQuery: MediaQueryList;

    private mobileQueryListener: () => void;

    filterValue = null;
    sortValue: string;
    pagination: Pagination;

    constructor(
        private configSrv: AppConfigService,
        private uiSrv: UiService,
        private authSrv: AuthService,
        private changeDetectorRef: ChangeDetectorRef,
        private media: MediaMatcher,
        private notificationsSrv: NotificationsService,
        private httpClient: HttpClient
    ) {
        this.mobileQuery = media.matchMedia('(max-width: 999px)');
        this.mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this.mobileQueryListener);
        this.isMaintenance = configSrv.isMaintenance;
    }

    ngOnInit() {
        this.clearCacheIfNewVersion();
        this.isAuth = this.authSrv.isAuth();
        this.initSubscriptions();
        this.initNotifications();
    }

    toggleSidenav(sidenav: MatSidenav, isToggle?: boolean): void {
        if (isToggle) {
            sidenav.toggle();
        } else if (this.mobileQuery.matches) {
            sidenav.close();
        }
    }

    private initSubscriptions(): void {
        this.subscription.add(
            this.uiSrv.routeLoadingStateChanged
                .subscribe(loadingState => {
                    this.isRouteLoading = loadingState;
                })
        );

        this.subscription.add(
            this.uiSrv.translationLoadingStateChanged
                .subscribe(loadingState => {
                    this.isTranslationLoading = loadingState;
                })
        );

        this.subscription.add(
            this.authSrv.authChanged
                .subscribe(authStatus => {
                    if (this.isAuth !== authStatus) {
                        this.isAuth = authStatus;
                        this.initNotifications();
                    }
                })
        );
    }

    private initNotifications(): void {
        if (this.isAuth) {
            const newRequest = new PaginationRequest(
                this.filterValue,
                this.sortValue,
                1,
                12
            );
            // this.notificationsSrv.get().subscribe();
            this.notificationsSrv.getList(newRequest);
            this.notificationsSrv.init();
        }
    }

    private clearCacheIfNewVersion(): void {
        const newBuild = `${environment.version}`;
        const oldBuild = localStorage.getItem('build');
        if (newBuild !== oldBuild) {
            localStorage.clear();
            localStorage.setItem('build', newBuild);

            const headers = new HttpHeaders()
                .set('Cache-Control', 'no-cache')
                .set('Pragma', 'no-cache');

            this.httpClient
                .get("", { headers, responseType: "text" })
                .subscribe(() => location.reload());
        }
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this.mobileQueryListener);
    }
}
