import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { MainHeaderComponent } from './main-header/main-header.component';
import { MainNavComponent } from './main-nav/main-nav.component';
import { NavItemComponent } from './main-nav/nav-item/nav-item.component';
import { RouterModule } from '@angular/router';
import { NotificationsComponent } from './main-header/notifications/notifications.component';
import { MomentModule } from 'ngx-moment';
import { UserMenuComponent } from './main-header/user-menu/user-menu.component';

@NgModule({
    declarations: [
        MainHeaderComponent,
        MainNavComponent,
        NavItemComponent,
        NotificationsComponent,
        UserMenuComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        MomentModule,
        ReactiveFormsModule
    ],
    exports: [
        MainHeaderComponent,
        MainNavComponent
    ]
})
export class MainModule { }
