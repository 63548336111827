import { BaseClass } from './base-class';
import { Client } from './client';
import { Step } from './step/step';
import { BaseRepository } from '@interfaces/base-repository';

export class Template extends BaseClass implements BaseRepository {
    name: string;
    comment: string;
    client: Client;
    steps: Step[];

    constructor()
    constructor(name: string, client: Client, steps: Step[], comment?: string)
    constructor(name?: string, client?: Client, steps?: Step[], comment?: string) {
        super();
        this.name = this.trim(name);
        this.comment = this.trim(comment);
        this.client = client || null;
        this.steps = steps || [];
    }

    static fromObject(object: Template): Template {
        return Object.assign(new Template(), {
            ...object,
            client: object.client ? Client.fromObject(object.client) : null,
            steps: object.steps.map(o => Step.fromObject(o)) || []
        });
    }

    get display(): string {
        return `${this.name}`;
    }

    doesInclude(value: string): boolean {
        return this.some([this.name], value);
    }
}
