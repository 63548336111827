import { StepsService } from '@services/steps.service';
import { UiService } from '@services/ui.service';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, FormControl } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { Step } from '@classes/step/step';
import { StepFormGroup } from '@interfaces/step-form-group';
import { Mission } from '@classes/mission/mission';
import { MissionsService } from '@services/missions.service';
import { BaseRepository } from '@interfaces/base-repository';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { BreakpointObserver, Breakpoints, MediaMatcher } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-steps-edit-form',
    templateUrl: './steps-edit-form.component.html',
    styleUrls: ['./steps-edit-form.component.scss']
})
export class StepsEditFormComponent<T extends BaseRepository> implements OnInit {
    @Input() steps: Step[];
    @Input() availableSteps: Step[];
    // @Input() missions: Mission[];
    @Output() stepsChanged = new EventEmitter<Step[]>();

    lockedSteps: Step[];
    startOrder = 1;

    stepsForm: FormGroup;
    // copyStep = new FormControl();
    isTablet = false;
    mobileQuery: MediaQueryList;

    constructor(
        private fb: FormBuilder,
        private missionsSrv: MissionsService,
        private uiSrv: UiService,
        private media: MediaMatcher,
        private changeDetectorRef: ChangeDetectorRef,
        private breakpointObserver: BreakpointObserver,
        private stepsSrv: StepsService
    ) {
        breakpointObserver.observe([
            Breakpoints.Tablet
        ]).subscribe(result => {
            if (result.matches) {
                this.isTablet = result.matches;
            }
        });
        // this.isTablet = this.mobileQuery.matches;
    }

    ngOnInit() {
        this.initForm();
    }

    get stepsFields() {

        return this.stepsForm.get('steps') as FormArray;
    }

    get totalEstimatedTime(): number {
        const locked = this.lockedSteps.reduce((total: number, step: Step) => {
            return total + step.estimatedTime + (step.timeOnSite || 0);
        }, 0);

        const form = this.stepsFields.value.reduce((total: number, step: StepFormGroup) => {
            return total + step.estimatedTime + (step.step ? step.step.timeOnSite : 0);
        }, 0);

        return locked + form;
    }

    onAddStep(step?: Step): void {
        this.stepsFields.push(
            this.fb.group({
                step: [step || null],
                estimatedTime: [step ? step.estimatedTime : 0],
                onCall: [step ? step.onCall : false],
                missionStepId: [step ? step.missionStepId : null]
            })
        );
    }

    displayFn(object: T): string | undefined {
        return object ? object.display : null;
    }

    onRemoveStep(index: number): void {
        this.stepsFields.removeAt(index);
    }

    onDropStep(event: CdkDragDrop<string[]>) {
        // Update controls order and values
        let canChangePosition = true;
        if (event.currentIndex < event.previousIndex) {
            for (let i = event.currentIndex; i < event.previousIndex; i++) {
                if (this.steps[i] && (this.steps[i].finishedOn || this.steps[i].arrivedOn || this.steps[i].ignoredOn)) {
                    canChangePosition = false;
                }
            }
        } else if (event.currentIndex > event.previousIndex) {
            for (let i = event.previousIndex; i <= event.currentIndex; i++) {
                if (this.steps[i] && (this.steps[i].finishedOn || this.steps[i].arrivedOn || this.steps[i].ignoredOn)) {
                    canChangePosition = false;
                }
            }
        }

        if (canChangePosition) {
            moveItemInArray(this.stepsFields.controls, event.previousIndex, event.currentIndex);
            moveItemInArray(this.stepsFields.value, event.previousIndex, event.currentIndex);

            this.emitStepsFromGroups(this.stepsFields.getRawValue());
        } else {
            this.uiSrv.showSnackbar('missions.edit.cannotChangePosition', true, 5000);
        }
    }



    getStatusIcon(step): string {
        if (step.onCall && !step.onCallActivated && !step.step.finishedOn) {
            return 'call';
        } else {
            if (step.step) {
                if (step.step.finishedOn) {
                    return 'done';
                }

                // if (step.step.onCall && !step.step.onCallActivated) {
                //     return 'call';
                // }

                if (step.step.ignoredOn) {
                    return 'close';
                }
                if (step.step.arrivedOn) {
                    return 'play_arrow';
                }

                return 'place';
            } else {
                if (step.finishedOn) {
                    return 'done';
                }

                if (step.onCall && !step.onCallActivated) {
                    return 'call';
                }

                if (step.ignoredOn) {
                    return 'close';
                }
                if (step.arrivedOn) {
                    return 'play_arrow';
                }

                return 'place';
            }
        }


    }

    private initForm(): void {
        let stepsToEdit: Step[] = [];
        this.lockedSteps = [];

        if (this.steps && this.steps.length) {
            this.lockedSteps = this.steps.filter(s => s.finishedOn != null || s.arrivedOn != null || s.ignoredOn != null);
            // stepsToEdit = this.steps.filter(s => s.finishedOn == null);
            stepsToEdit = this.steps;
        }

        // this.startOrder = this.lockedSteps.length + 1;
        this.startOrder = 1;

        this.stepsForm = this.fb.group({
            steps: this.fb.array([])
        });

        if (stepsToEdit.length) {
            stepsToEdit.map(step => this.onAddStep(step));
        } else {
            this.onAddStep();
        }

        this.steps.map((step, index) => {
            if (this.steps[index].finishedOn || this.steps[index].arrivedOn || this.steps[index].ignoredOn) {
                this.stepsFields.controls[index].disable({ onlySelf: true });
            }
        });

        this.stepsForm.controls.steps.valueChanges
            .subscribe((stepGroups: StepFormGroup[]) => {
                this.emitStepsFromGroups(this.stepsForm.getRawValue().steps);
            });
    }

    private emitStepsFromGroups(stepGroups: StepFormGroup[]): void {
        const stepsList: Step[] = stepGroups.reduce((steps: Step[], stepGroup: StepFormGroup, index: number) => {
            if (!stepGroup.step || typeof stepGroup.step === 'string') {
                return [...steps];
            }
            const step: Step = { ...stepGroup.step } as Step
            step.sortOrder = index + 1;
            step.estimatedTime = stepGroup.estimatedTime;
            step.onCall = stepGroup.onCall;
            step.missionStepId = stepGroup.missionStepId;

            return [...steps, step];
        }, []);

        this.stepsChanged.emit([...stepsList]);
    }

    public activateDesactiveCall(event: MatSlideToggleChange, stepControl) {
        this.changeDetectorRef.detectChanges();
    }

    changeOrder(previousIndex, value) {
        let drag: CdkDragDrop<string[]> = {
            previousIndex: previousIndex,
            currentIndex: value - 1,
            container: null,
            distance: null,
            dropPoint: null,
            isPointerOverContainer: null,
            item: null,
            previousContainer: null
        };

        this.onDropStep(drag);
    }
}
