import { BaseClass } from './base-class';
import { Address } from './address';
import { Contact } from './contact';
import { BaseRepository } from '@interfaces/base-repository';
import { Step } from './step/step';

export class Client extends BaseClass implements BaseRepository {
    name: string;

    address?: Address;
    contacts: Contact[];
    step?: Step;
    textSms?: string;

    constructor()
    constructor(name: string, address?: Address, contacts?: Contact[], step?: Step, textSms?: string)
    constructor(name?: string, address?: Address, contacts?: Contact[], step?: Step, textSms?: string) {
        super();

        this.name = this.trim(name);
        this.address = address || null;
        this.contacts = contacts || [];
        this.step = step;
        this.textSms = textSms;
    }

    static fromObject(object: Client): Client {
        return Object.assign(new Client(), {
            ...object,
            address: object.address ? Address.fromObject(object.address) : null,
            step: object.step ? Step.fromObject(object.step) : null,
            contacts: object.contacts.map(o => Contact.fromObject(o)) || []
        });
    }

    get display(): string {
        return this.name;
    }

    doesInclude(value: string): boolean {
        return this.some([this.name], value);
    }
}
