export enum PushType {
    missionCreated = 10,
    missionSeen = 11,
    missionStarted = 1,
    missionPaused = 2,
    missionUpdated = 3,
    stepArrived = 4,
    clientArrived = 5,
    stepIgnored = 6,
    stepCompleted = 7,
    missionCompleted = 8,
    logout = 9,
    numberOfMissions = 12,
    clearNotifications = 13,
    StepActivated = 14,
    MissionReassigned = 15,
    MissionCancelled = 16,
    StepDeactivated = 17
}
