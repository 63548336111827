import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import * as FileSaver from 'file-saver';

import { environment } from '@environments/environment';
import { StepReport } from '@classes/step/step-report';
import { MissionsService } from './missions.service';

@Injectable({
    providedIn: 'root'
})
export class ReportService {
    selectedReportSubject = new BehaviorSubject<StepReport>(null);

    private baseUrl: string = environment.apiUrl + 'reports';
    private missionsUrl: string = environment.apiUrl + 'missions';
    private clientsUrl: string = environment.apiUrl + 'clients';

    constructor(
        private http: HttpClient,
        private missionSrv: MissionsService
    ) { }

    get(id: string, type: 'step' | 'client'): Observable<StepReport> {
        let url: string;
        const missionId: string = this.missionSrv.selectedId;

        switch(type) {
            case 'step':
                url = `${this.missionsUrl}/${missionId}/steps/${id}/reports`;
                break;
            case 'client':
            default:
                url = `${this.clientsUrl}/${id}/missions/${missionId}/reports`;
        }

        return this.http.get<StepReport>(url)
        .pipe(
            tap(reportFromApi => {
                this.selectedReportSubject.next(reportFromApi);
            })
        );
    }

    getAttachment(sampleId: string): Observable<Blob> {
        const reportId: string = this.selectedReportSubject.value.id;
        const url = `${this.baseUrl}/${reportId}/samples/${sampleId}/attachments`;

        return this.http.get(url, {observe: 'response', responseType: 'blob' })
        .pipe(map(res => this.saveFile(res)));
    }

    private saveFile(res: HttpResponse<Blob>): Blob {
        FileSaver.saveAs(res.body, res.headers.get('filename'));
        return res.body;
    }
}
