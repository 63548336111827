import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SharedModule } from 'app/shared/shared.module';
import { TemperaturesComponent } from './temperatures.component';
import { TemperaturesSuiviComponent } from './temperatures-suivi/temperatures-suivi.component';
import { TemperaturesDetailComponent } from './temperatures-detail/temperatures-detail.component';
import { TemperaturesCreateAlertComponent } from './temperatures-create-alert/temperatures-create-alert.component';
import { TemperaturesAlertListComponent } from './temperatures-alert-list/temperatures-alert-list.component';

import { ReactiveFormsModule } from '@angular/forms';
import { TemperaturesAlertDetailComponent } from './temperatures-alert-detail/temperatures-alert-detail.component';
import { TemperaturesEditAlertComponent } from './temperatures-edit-alert/temperatures-edit-alert.component';
import { MomentModule } from 'ngx-moment';




@NgModule({
    declarations: [
        TemperaturesComponent,
        TemperaturesSuiviComponent,
        TemperaturesDetailComponent,
        TemperaturesCreateAlertComponent,
        TemperaturesAlertListComponent,
        TemperaturesAlertDetailComponent,
        TemperaturesEditAlertComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        ReactiveFormsModule,
        RouterModule,
        MomentModule
    ]
})
export class TemperaturesModule { }
