import { Pipe, PipeTransform } from '@angular/core';

import { FormatService } from '@services/format.service';

@Pipe({
    name: 'formatPercent'
})
export class FormatPercentPipe implements PipeTransform {

    constructor( private formatSrv: FormatService) {}

    transform(value: number): string {
        return this.formatSrv.formatPercent(value);
    }

}
