import { Component, OnInit, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';

import { NavLink } from '@classes/nav-link';
import { UiService } from '@services/ui.service';

@Component({
    selector: 'app-nav-item',
    templateUrl: './nav-item.component.html',
    styleUrls: ['./nav-item.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavItemComponent implements OnInit, OnDestroy {
    @Input() link: NavLink;

    private subscription = new Subscription();

    isRouteLoading: boolean;

    constructor(
        private uiSrv: UiService
    ) { }

    ngOnInit() {
        this.initSubscriptions();
    }

    private initSubscriptions(): void {
        this.subscription.add(
            this.uiSrv.routeLoadingStateChanged
            .subscribe(loadingState => {
                this.isRouteLoading = loadingState;
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
