<div class="app-container">
    <app-main-header
        [isAuth]="isAuth"
        (sidenavToggle)="toggleSidenav(sidenav, true)"></app-main-header>

    <mat-sidenav-container class="sidenav-container">
        <mat-sidenav
            #sidenav
            role="navigation"
            fixedTopGap="56"
            class="mat-elevation-z2"
            [mode]="mobileQuery.matches ? 'over' : 'side'"
            [opened]="(!isAuth || mobileQuery.matches) ? 'false' : 'true'"
            [fixedInViewport]="true" >
            <app-main-nav [isAuth]="isAuth" (sidenavClose)="toggleSidenav(sidenav)"></app-main-nav>
        </mat-sidenav>

        <mat-sidenav-content>
            <main>
                <router-outlet></router-outlet>
            </main>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
<div class="loading" *ngIf="isRouteLoading || isTranslationLoading">
    <mat-progress-bar class="progress-bar" mode="indeterminate" color="primary"></mat-progress-bar>
</div>
