import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { UiService } from "@services/ui.service";
import { Step } from '@classes/step/step';
import { StepsService } from '@services/steps.service';


@Injectable()
export class StepsResolver implements Resolve<Step[]> {
    constructor(
        private stepsSrv: StepsService,
        private uiSrv: UiService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Step[]> {
        this.uiSrv.routeLoadingStateChanged.next(true);

        return this.stepsSrv.getList()
        .pipe(
            tap(() => {
                this.uiSrv.routeLoadingStateChanged.next(false);
            })
        );
    }
}
