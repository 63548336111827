import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Step } from '@classes/step/step';
import { ReportService } from '@services/report.service';
import { UiService } from '@services/ui.service';
import { MissionsService } from '@services/missions.service';
import { MatDialog } from '@angular/material/dialog';
import { InitialsDialogComponent } from '@components/initials-dialog/initials-dialog.component';

@Component({
    selector: 'app-steps-list',
    templateUrl: './steps-list.component.html',
    styleUrls: ['./steps-list.component.scss']
})
export class StepsListComponent implements OnInit, OnDestroy {
    @Input() steps: Step[];
    @Input() doesShowActions: boolean;

    private subsciption = new Subscription();

    isLoading: boolean;
    isLoadingActivateStep: boolean;
    isLoadingDeactivateStep: boolean;
    selectedStepId: string;

    constructor(
        private reportSrv: ReportService,
        private uiSrv: UiService,
        private missionSrv: MissionsService,
        private dialog: MatDialog
    ) { }

    ngOnInit(): void {
        // Clean up selected report on list init
        this.reportSrv.selectedReportSubject.next(null);
        this.initSubscriptions();
    }

    getStatusIcon(step: Step): string {
        if (step.onCall && !step.onCallActivated) {
            return 'call';
        }
        if (step.finishedOn) {
            return 'done';
        }
        if (step.ignoredOn) {
            return 'close';
        }
        if (step.arrivedOn) {
            return 'play_arrow';
        }

        return 'place';
    }

    onShowReport(id: string): void {
        this.isLoading = true;
        this.selectedStepId = id;
        this.reportSrv.get(id, 'step').subscribe(() => this.isLoading = false);
    }

    onActivate(step: Step): void {
        this.isLoadingActivateStep = true;
        const dialogRef = this.dialog.open(InitialsDialogComponent, {
            width: '260px',
            data: { stepId: step.id, onActivate: true }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                let initials = result.initials;
                this.missionSrv.activateStep(step.missionStepId, initials)
                    .subscribe(() => {
                        step.onCallActivatedBy = initials;
                        this.isLoadingActivateStep = false;
                    });
            } else {
                this.isLoadingActivateStep = false;
            }
        });

    }
    onDeactivate(step: Step): void {
        this.isLoadingDeactivateStep = true;
        const dialogRef = this.dialog.open(InitialsDialogComponent, {
            width: '260px',
            data: { stepId: step.id }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                let initials = result.initials;
                this.missionSrv.deactivateStep(step.missionStepId, initials)
                    .subscribe(() => {
                        step.onCallDeactivatedBy = initials;
                        this.isLoadingDeactivateStep = false;
                    });
            } else {
                this.isLoadingDeactivateStep = false;
            }
        });
    }

    private initSubscriptions(): void {
        this.subsciption.add(
            this.uiSrv.loadingStateChanged
                .subscribe(loadingState => {
                    this.isLoading = loadingState;
                })
        );
    }

    ngOnDestroy(): void {
        this.subsciption.unsubscribe();
    }
}
