export class PaginationRequest {
    page?: number;
    query?: any;
    sort?: string;
    pageSize?: number;
    includeCount?: boolean;
    fromStartDate?: Date;
    toStartDate?: Date;

    constructor(query?: any, sort?: string, page = 1, pageSize = 40, includeCount = true) {
        this.page = page;
        // this.query = query ? query.trim() : null;
        this.query = query ? query : null;
        this.sort = sort ? sort.trim() : null;
        this.pageSize = pageSize;
        this.includeCount = includeCount;
    }
}
