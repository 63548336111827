import { StepsService } from '@services/steps.service';
import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import { FormBuilder, Validators, FormArray, FormControl, AbstractControl } from '@angular/forms';

import { FormEditBaseComponent } from '@components/form-edit-base.component';
import { UiService } from '@services/ui.service';
import { ClientsService } from '@services/clients.service';
import { ContactsService } from '@services/contacts.service';
import { Client } from '@classes/client';
import { Address } from '@classes/address';
import { Contact } from '@classes/contact';
import { AddressesService } from '@services/addresses.service';
import { Step } from '@classes/step/step';

@Component({
    selector: 'app-client-edit',
    templateUrl: './client-edit.component.html',
    styleUrls: ['./client-edit.component.scss']
})
export class ClientEditComponent extends FormEditBaseComponent implements OnInit {
    @Input() client: Client;

    contacts: Contact[];
    addresses: Address[];
    steps: Step[];
    smsMaxLength = 250;

    constructor(
        protected uiSrv: UiService,
        protected fb: FormBuilder,
        private clientsSrv: ClientsService,
        private contactsSrv: ContactsService,
        private addressesSrv: AddressesService,
        private stepsSrv: StepsService,
        private renderer: Renderer2
    ) {
        super(uiSrv, fb);
    }

    ngOnInit() {
        super.ngOnInit();
        this.initSubscriptions();
    }

    onSubmit(): void {
        this.isLoading = true;
        if (this.client) {
            this.update();
        } else {
            this.create();
        }
    }

    onCancel(): void {
        this.editFinished.emit();
    }

    private update(): void {
        const clientCopy: Client = JSON.parse(JSON.stringify(this.client));
        clientCopy.name = this.editForm.value.name;
        clientCopy.address = this.editForm.value.address;
        clientCopy.step = this.editForm.value.step;
        clientCopy.contacts = this.contactsSrv.setDefault(this.editForm.value.contacts);
        clientCopy.textSms = this.editForm.value.textSms ? this.editForm.value.textSms.trim() : "";

        this.clientsSrv.put(clientCopy).subscribe(() => this.isLoading = false);
    }

    private create(): void {
        this.client = new Client(
            this.editForm.value.name,
            this.editForm.value.address,
            this.contactsSrv.setDefault(this.editForm.value.contacts),
            this.editForm.value.step,
            this.editForm.value.textSms ? this.editForm.value.textSms.trim() : ""
        );

        this.clientsSrv.post(this.client)
            .subscribe(
                () => this.isLoading = false,
                () => this.client = null
            );
    }

    private initSubscriptions(): void {
        this.isLoading = true;

        const contactsPromise = new Promise((resolve, reject) => {
            this.contactsSrv.getList()
                .subscribe(contactsFromSrv => {
                    this.contacts = contactsFromSrv;
                    resolve(contactsFromSrv);
                });
        });

        const addressesPromise = new Promise((resolve, reject) => {
            this.addressesSrv.getList()
                .subscribe(addressesFromSrv => {
                    this.addresses = addressesFromSrv;
                    resolve(addressesFromSrv);
                });
        });

        const stepsPromise = new Promise((resolve, reject) => {
            this.stepsSrv.getList()
                .subscribe(stepsFromSrv => {
                    this.steps = stepsFromSrv;
                    resolve(stepsFromSrv);
                });
        });

        Promise.all([contactsPromise, addressesPromise, stepsPromise])
            .then(() => {
                this.initForm();
                this.isLoading = false;
            });
    }

    private initForm(): void {
        let name = '';
        let address = null;
        let contactsControls = [this.fb.control(null), this.fb.control(null)];
        let step = null;
        let textSms = '';

        if (this.client) {
            name = this.client.name;
            address = this.client.address || null;
            step = this.client.step || null;
            textSms = this.client.textSms;
            if (this.client.contacts.length) {
                contactsControls = [];
                this.client.contacts.map(oneContact => {
                    contactsControls.push(this.fb.control(oneContact));
                });
                contactsControls = contactsControls.map((control, i) => {
                    return this.fb.control(this.client.contacts[i] || null);
                });
            }
        }

        this.editForm = this.fb.group({
            name: [name, Validators.required],
            contacts: this.fb.array(contactsControls),
            address: [address],
            step: [step],
            textSms: [textSms, Validators.maxLength(this.smsMaxLength)],
        });

    }

    onAddContact(contact?: Contact): void {
        this.contactsFields.push(
            this.fb.control(null)
        );
    }

    removeContact(contactToRemove) {
        this.getArray('contacts').controls.splice(this.getArray('contacts').controls.indexOf(contactToRemove), 1);
        this.editForm.value.contacts.splice(this.editForm.value.contacts.indexOf(contactToRemove.value), 1);
    }

    get contactsFields() {
        return this.editForm.get('contacts') as FormArray;
    }

    addTag(tag: string) {
        const text = this.editForm.value.textSms + ' ' + tag;

        this.editForm.get('textSms').setValue(text);
        this.renderer.selectRootElement('#smsInput').focus();
    }
}

