import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-gauge',
    templateUrl: './gauge.component.html',
    styleUrls: ['./gauge.component.scss']
})
export class GaugeComponent implements OnInit {
    @Input() value: number;
    @Input() strokeWidth = 5;
    @Input() isDarken: boolean;

    radius: number;
    circumference: number;

    constructor() {
        this.radius = 40;
        this.circumference = 2 * Math.PI * this.radius;
    }

    ngOnInit() { }

    get dashOffset(): number {
        let value = this.value;

        if(value > 1) {
            value = 1;
        } else if(value < -1) {
            value = -1;
        }

        return this.circumference * (1 - value);
    }
}
