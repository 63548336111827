import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { UiService } from "@services/ui.service";
import { Template } from '@classes/template';
import { TemplatesService } from '@services/templates.service';


@Injectable()
export class TemplatesResolver implements Resolve<Template[]> {
    constructor(
        private templatesSrv: TemplatesService,
        private uiSrv: UiService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Template[]> {
        this.uiSrv.routeLoadingStateChanged.next(true);

        return this.templatesSrv.getList()
        .pipe(
            tap(() => {
                this.uiSrv.routeLoadingStateChanged.next(false);
            })
        );
    }
}
