import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';

import { AuthService } from '@services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class UnauthGuard implements CanActivate {
    constructor(
        private authSrv: AuthService,
        private jwtHelperSrv: JwtHelperService,
        private router: Router
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        if(!this.authSrv.isAuth() && !this.jwtHelperSrv.tokenGetter()) {
            return true;
        } else {
            this.router.navigate(['/']);
            return false;
        }
    }
}
