import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Dashboard } from '@classes/dashboard';
import { Sample } from '@classes/sample';
import { Mission } from '@classes/mission/mission';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    missionsChanged = new Subject<Mission[]>();
    samplesChanged = new Subject<Sample[]>();

    private baseUrl: string = environment.apiUrl + 'dashboard';

    constructor(
        private http: HttpClient
    ) { }

    get(): Observable<Dashboard> {
        return this.http.get<Dashboard>(this.baseUrl)
        .pipe(
            tap(dashFromApi => {
                this.missionsChanged.next(dashFromApi.missions);
                this.samplesChanged.next(dashFromApi.samples);
            })
        );
    }
}
