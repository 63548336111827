import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, finalize, filter, take } from 'rxjs/operators';

import { UiService } from "@services/ui.service";
import { AuthService } from "@services/auth.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private isRefreshingToken: boolean;
    private isRefreshingTokenMworker: boolean;
    private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private tokenMworkerSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(
        private uiSrv: UiService,
        private authSrv: AuthService,
        private router: Router
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // if(!req.url.includes('staging-mw5.bizzdev')) {
        return next.handle(req).pipe(
            catchError(err => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        // token outdated
                        return this.refreshToken(req, next);
                    }
                    this.uiSrv.loadingStateChanged.next(false);
                    this.uiSrv.routeLoadingStateChanged.next(false);
                    this.uiSrv.translationLoadingStateChanged.next(false);
                    this.handleErrorMessage(err);
                    return throwError(err);
                }
            })
        );
        // } else if(req.url.includes('staging-mw5.bizzdev')) {
        //     return next.handle(req).pipe(
        //         catchError(err => {
        //             if(err instanceof HttpErrorResponse) {
        //                 if(err.status === 401) {
        //                     // token outdated
        //                     return this.refreshMworkerToken(req, next);
        //                 }

        //                 this.uiSrv.loadingStateChanged.next(false);
        //                 this.uiSrv.routeLoadingStateChanged.next(false);
        //                 this.uiSrv.translationLoadingStateChanged.next(false);

        //                 this.handleErrorMessage(err);

        //                 return throwError(err);
        //             }
        //         })
        //     );
        //     }
    }

    private refreshToken(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            return this.authSrv.refreshToken()
                .pipe(
                    switchMap(newToken => {
                        if (newToken) {
                            this.tokenSubject.next(newToken.access_token);
                            return next.handle(this.addToken(req, newToken.access_token));
                        }

                        // If we don't get a new token, we are in trouble so logout.
                        this.authSrv.logout();
                        this.showMessage('auth.tokenRfFail');
                        return throwError("");
                    }),
                    catchError(error => {
                        // If there is an exception calling 'refreshToken', bad news so logout.
                        this.authSrv.logout();
                        return throwError(error);
                    }),
                    finalize(() => this.isRefreshingToken = false)
                );
        } else {
            return this.tokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(this.addToken(req, token));
                })
            );
        }
    }

    // private refreshMworkerToken(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //     if (!this.isRefreshingTokenMworker) {
    //         this.isRefreshingTokenMworker = true;

    //         // Reset here so that the following requests wait until the token
    //         // comes back from the refreshToken call.
    //         this.tokenMworkerSubject.next(null);

    //         return this.authSrv.refreshMworkerToken()
    //             .pipe(
    //                 switchMap(newToken => {
    //                     if (newToken) {
    //                         this.tokenMworkerSubject.next(newToken.access_token);
    //                         return next.handle(this.addToken(req, newToken.access_token));
    //                     }

    //                     // If we don't get a new token, we are in trouble so logout.
    //                     this.authSrv.logout();
    //                     this.showMessage('auth.tokenRfFail');
    //                     return throwError("");
    //                 }),
    //                 catchError(error => {
    //                     // If there is an exception calling 'refreshToken', bad news so logout.
    //                     this.authSrv.logout();
    //                     return throwError(error);
    //                 }),
    //                 finalize(() => this.isRefreshingTokenMworker = false)
    //             );
    //     } else {
    //         return this.tokenMworkerSubject.pipe(
    //             filter(token => token != null),
    //             take(1),
    //             switchMap(token => {
    //                 return next.handle(this.addToken(req, token));
    //             })
    //         );
    //     }
    // }

    private addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
    }

    private handleErrorMessage(err: HttpErrorResponse): void {
        const defaultText = `${err.status} ${err.statusText}`;

        switch (err.status) {
            case 403:
                this.handle403Error();
                break;
            case 400:
            case 409:
                if (err.error && err.error.error) {
                    this.handle400Error(err.error.error, err.error.error_description);
                } else {
                    this.showMessage(defaultText, false);
                }
                break;
            case 404:
                this.showMessage('global.dialogs.serverNotFound');
                break;
            default:
                // redirect to error page if error status is not a known one
                this.showMessage(defaultText, false);
                this.router.navigate(['/error'], { queryParams: { code: err.status, message: err.statusText } });
        }
    }

    private handle403Error(): void {
        this.showMessage('auth.tokenRfFail');
        this.router.navigate(['/']);
    }

    private handle400Error(error: string, errorDescription: string): void {
        switch (error) {
            case 'invalid_grant':
                this.handleInvalidGrant(errorDescription);
                break;
            // case 'invalid_type' :
            //     this.showMessage('files.uploadStates.typeError.summary');
            //     break;
            // case 'username_already_used' :
            //     this.showMessage('users.usersList.userExists.summary');
            //     break;
            default:
                this.showMessage(error, false);
        }
    }

    private handleInvalidGrant(errorDescription: string): void {
        switch (errorDescription) {
            // Login failed
            case 'invalid_username_or_password':
                this.showMessage('auth.invalidGrant');
                break;
            // Token refresh failed
            default:
                this.authSrv.logout();
                this.showMessage('auth.tokenRfFail');
        }
    }

    private showMessage(errorMessage: string, isTranslated = true): void {
        this.uiSrv.showSnackbar(errorMessage, isTranslated, 5000);
    }
}
