import { OnInit, Output, EventEmitter, OnDestroy, Component } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import { UiService } from '@services/ui.service';

@Component({
    template: ""
})
export class FormEditBaseComponent implements OnInit, OnDestroy {
    @Output() editFinished = new EventEmitter<void>();

    private subscription = new Subscription();

    editForm: FormGroup;
    isLoading: boolean;

    constructor(
        protected uiSrv: UiService,
        protected fb: FormBuilder
    ) { }

    ngOnInit() {
        this.initBaseSubs();
    }

    getControl(control: string): FormControl {
        return this.editForm.get(control) as FormControl;
    }

    getArray(array: string): FormArray {
        return this.editForm.get(array) as FormArray;
    }

    private initBaseSubs(): void {
        this.subscription.add(
            this.uiSrv.loadingStateChanged
            .subscribe(loadingState => {
                this.isLoading = loadingState;
            })
        );
    }

    protected addSubscription(newSub: Subscription): void {
        this.subscription.add(newSub);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
