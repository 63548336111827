import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { UiService } from "@services/ui.service";
import { SamplesService } from '@services/samples.service';
import { Sample } from '@classes/sample';


@Injectable()
export class SamplesResolver implements Resolve<Sample[]> {
    constructor(
        private samplesSrv: SamplesService,
        private uiSrv: UiService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Sample[]> {
        this.uiSrv.routeLoadingStateChanged.next(true);

        return this.samplesSrv.getList()
        .pipe(
            tap(() => {
                this.uiSrv.routeLoadingStateChanged.next(false);
            })
        );
    }
}
