<mat-card>
    <mat-card-header>
        <mat-card-title>
            <h1>{{ 'users.title' | translate }}</h1>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <section class="creation-form">
            <button *ngIf="!isCreationMode" (click)="onEdit()"
                [matTooltip]="'users.list.add' | translate"
                mat-mini-fab class="action-button" color="accent">
                <mat-icon aria-label="Add">person_add</mat-icon>
            </button>
            <app-user-edit *ngIf="isCreationMode && !editedId" (editFinished)="onEditFinished()"></app-user-edit>
        </section>

        <section class="datatable-container">
            <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
                <ng-container matColumnDef="username">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'users.list.username' | translate }} </th>
                    <td mat-cell *matCellDef="let user"> {{ user.username }} </td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'users.list.email' | translate }} </th>
                    <td mat-cell *matCellDef="let user"> {{ user.email }} </td>
                </ng-container>

                <ng-container matColumnDef="role">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'users.list.role' | translate }} </th>
                    <td mat-cell *matCellDef="let user"> {{ 'roles.' + user.role.name | translate }} </td>
                </ng-container>

                <ng-container matColumnDef="clients">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'users.list.clients' | translate }} </th>
                    <td mat-cell *matCellDef="let user">
                        <ul>
                            <li *ngFor="let client of user.clients"> {{ client.name }} </li>
                        </ul>
                    </td>
                </ng-container>

                <ng-container matColumnDef="lastLogonOn">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'users.list.lastLogonOn' | translate }} </th>
                    <td mat-cell *matCellDef="let user"> {{ user.lastLogonOn | amDateFormat: 'DD/MM/YYYY' }} </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="actions"> {{ 'users.list.actions' | translate }} </th>
                    <td mat-cell *matCellDef="let user">
                        <ng-container *ngIf="user.id !== editedId">
                            <button mat-icon-button class="action-button" color="accent"
                                [disabled]="isLoading"
                                [matTooltip]="'users.list.edit' | translate"
                                (click)="onEdit(user.id)">
                                <mat-icon aria-label="Edit">edit</mat-icon>
                            </button>
                            <button mat-icon-button class="action-button" color="accent"
                                *ngIf="user.isActive"
                                [disabled]="isLoading"
                                [matTooltip]="'users.list.disable' | translate"
                                (click)="onChangeState(user, false)">
                                <mat-icon aria-label="Disable">toggle_on</mat-icon>
                            </button>
                            <button mat-icon-button class="action-button" color="primary"
                                *ngIf="!user.isActive"
                                [disabled]="isLoading"
                                [matTooltip]="'users.list.enable' | translate"
                                (click)="onChangeState(user, true)">
                                <mat-icon aria-label="Disable">toggle_off</mat-icon>
                            </button>
                            <button mat-icon-button class="action-button" color="accent"
                                *ngIf="user.lastLogonOn == null"
                                [disabled]="isLoading"
                                [matTooltip]="'users.list.sendWelcome' | translate" (click)="onSendMail(user)">
                                <mat-icon aria-label="Edit">email</mat-icon>
                            </button>
                        </ng-container>
                    </td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let user" [attr.colspan]="displayedColumns.length">
                        <div *ngIf="user.id === editedId" class="row-edit"
                            [@detailExpand]="user.id === editedId ? 'expanded' : 'collapsed'">
                            <app-user-edit [user]="user" (editFinished)="onEditFinished()"></app-user-edit>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row" [ngClass]="{
                            'disabled': !row.isActive,
                            'user-expanded-row': row.id === editedId
                        }"></tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
            </table>
        </section>
        <mat-progress-bar *ngIf="isLoading"  class="progress-bar" mode="indeterminate" color="accent"></mat-progress-bar>
    </mat-card-content>
</mat-card>
