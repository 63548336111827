import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Role } from '@classes/role';

@Injectable({
    providedIn: 'root'
})
export class RolesService {
    rolesListChanged = new Subject<Role[]>();

    private baseUrl: string = environment.apiUrl + 'roles';

    constructor(
        private http: HttpClient
    ) { }

    getList(): Observable<Role[]> {
        return this.http.get<Role[]>(this.baseUrl)
        .pipe(
            tap(list => {
                this.rolesListChanged.next(list);
            })
        );
    }
}
