import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Step } from '@classes/step/step';

@Injectable({
    providedIn: 'root'
})
export class StepsService {
    stepsListChanged = new Subject<Step[]>();
    private baseUrl: string = environment.apiUrl + 'steps';
    private steps: Step[];

    constructor(
        private http: HttpClient
    ) { }

    getList(): Observable<Step[]> {
        return this.http.get<Step[]>(this.baseUrl)
        .pipe(
            map(steps => {
                this.steps = steps.map(s => Step.fromObject(s));
                this.stepsListChanged.next([ ...this.steps ]);
                return [ ...this.steps ];
            })
        );
    }

    getListByClientId(clientId): Observable<Step[]> {
        return this.http.get<Step[]>(`${this.baseUrl}?clientId=${clientId}`)
        .pipe(
            map(steps => {
                this.steps = steps.map(s => Step.fromObject(s));
                this.stepsListChanged.next([ ...this.steps ]);
                return [ ...this.steps ];
            })
        );
    }

    put(step: Step): Observable<Step> {
        return this.http.put<Step>(this.baseUrl, step)
        .pipe(
            tap(editedStep => {
                editedStep = Step.fromObject(editedStep);
                this.steps = this.steps.map(s => s.id === editedStep.id ? editedStep : s);
                this.stepsListChanged.next([ ...this.steps ]);
            })
        );
    }

    post(step: Step): Observable<Step> {
        return this.http.post<Step>(this.baseUrl, step)
        .pipe(
            tap(createdStep => {
                createdStep = Step.fromObject(createdStep);
                this.steps.push(createdStep);
                this.stepsListChanged.next([ ...this.steps ]);
            })
        );
    }

    delete(id: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${id}`)
        .pipe(
            tap(() => {
                this.steps = this.steps.filter(s => s.id !== id);
                this.stepsListChanged.next([ ...this.steps ]);
            })
        );
    }
}
