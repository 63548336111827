import { AuthService } from './../../shared/services/auth.service';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { FormEditBaseComponent } from '@components/form-edit-base.component';
import { User } from '@classes/user/user';
import { Role } from '@classes/role';
import { UiService } from '@services/ui.service';
import { UsersService } from '@services/users.service';
import { RolesService } from '@services/roles.service';
import { UserDetail } from '@classes/user/user-detail';
import { Client } from '@classes/client';
import { ClientsService } from '@services/clients.service';
import { RoleName } from '@enums/role-name.enum';

@Component({
    selector: 'app-user-edit',
    templateUrl: './user-edit.component.html',
    styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent extends FormEditBaseComponent implements OnInit {
    @Input() user: User;

    rolesList: Role[];
    clientsList: Client[];
    selRole: Role;
    userNamePattern = "^[a-z0-9._-]{3,50}$";

    selectedCliendId = '';

    constructor(
        protected uiSrv: UiService,
        protected fb: FormBuilder,
        private usersSrv: UsersService,
        private rolesSrv: RolesService,
        private clientsSrv: ClientsService,
        private authSrv: AuthService
    ) {
        super(uiSrv, fb);
    }

    ngOnInit() {
        super.ngOnInit();
        this.selectedCliendId = this.usersSrv.clientSelected;
        this.initSubscriptions();
    }

    get isClientRequired(): boolean {
        return this.selRole && this.selRole.name !== RoleName.admin;
    }

    get canAddClient(): boolean {
        const restrictedRoles = [RoleName.admin_labo, RoleName.labo];
        return !this.selRole || !restrictedRoles.some(r => r === this.selRole.name);
    }

    onAddClient(client?: Client): void {
        const control = this.fb.control(client || null);
        if(this.isClientRequired) {
            control.setValidators(Validators.required);
        }
        this.getArray('clients').push(control);
    }

    onRemoveClient(index: number): void {
        this.getArray('clients').removeAt(index);
    }

    onSubmit(): void {
        this.isLoading = true;
        if (this.user) {
            this.update();
        } else {
            this.create();
        }
    }

    onCancel(): void {
        this.editFinished.emit();
    }

    private update(): void {
        const userCopy: User = JSON.parse(JSON.stringify(this.user));
        userCopy.username = this.editForm.value.username;
        userCopy.email = this.editForm.value.email;
        userCopy.role = this.selRole;
        if(userCopy.detail == null) {
            userCopy.detail = new UserDetail();
        }

        userCopy.detail.firstname = this.editForm.value.detail.firstname;
        userCopy.detail.lastname = this.editForm.value.detail.lastname;
        userCopy.detail.phone = this.editForm.value.detail.phone;

        userCopy.clients = this.editForm.value.clients.filter(c => c != null);

        this.usersSrv.put(userCopy).subscribe(() => this.isLoading = false);
    }

    private create(): void {
        this.user = new User(
            this.editForm.value.username,
            this.editForm.value.email,
            this.selRole,
            this.editForm.value.clients.filter(c => c != null),
            this.editForm.value.detail.firstname,
            this.editForm.value.detail.lastname,
            this.editForm.value.detail.phone
        );

        this.usersSrv.post(this.user)
        .subscribe(
            () => this.isLoading = false,
            () => this.user = null
        );
    }

    private initSubscriptions(): void {
        this.isLoading = true;

        const rolesPromise = new Promise((resolve, reject) => {
            this.rolesSrv.getList()
            .subscribe(rolesFromSrv => {
                this.rolesList = rolesFromSrv;
                resolve(rolesFromSrv);
            });
        });

        const clientsPromise = new Promise((resolve, reject) => {
            this.clientsSrv.getList()
            .subscribe(clientsFromSrv => {
                this.clientsList = clientsFromSrv;
                resolve(clientsFromSrv);
            });
        });

        Promise.all([rolesPromise, clientsPromise])
        .then(() => {
            this.initForm();
            this.isLoading = false;
        });
    }

    private initForm(): void {
        let username = "";
        let email = "";
        let role = "";
        let firstname = "";
        let lastname = "";
        let phone = "";
        let clients: Client[] = [];

        if (this.user) {
            username = this.user.username;
            email = this.user.email;
            role = this.user.role.id;
            clients = this.user.clients;

            this.selRole = this.user.role;

            if(this.user.detail) {
                firstname = this.user.detail.firstname;
                lastname = this.user.detail.lastname;
                phone = this.user.detail.phone;
            }
        }

        this.editForm = this.fb.group({
            username: [username, [Validators.required, Validators.pattern(this.userNamePattern)]],
            email: [email, [Validators.required, Validators.email]],
            role: [role, Validators.required],
            clients: this.fb.array([]),
            detail: this.fb.group({
                firstname : [firstname],
                lastname : [lastname],
                phone : [phone]
            })
        });

        if(clients && clients.length) {
            clients.map(c => this.onAddClient(c));
        } else {
            this.onAddClient();
        }

        this.observeFormChanges();
    }

    private observeFormChanges(): void {
        this.editForm.controls.role.valueChanges
        .subscribe((selId: string) => {
            this.selRole = this.rolesList.find(r => r.id === selId);

            this.getArray('clients').controls
            .map(control => {
                if(this.isClientRequired) {
                    control.setValidators(Validators.required);
                } else {
                    control.setValidators([]);
                }

                control.updateValueAndValidity();
            });
        });
    }
}
