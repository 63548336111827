<form class="edit-form" *ngIf="!isLoading" [formGroup]="editForm" (submit)="onSubmit()">
    <section class="clientField">
        <app-autocomplete
            [control]="getControl('client')" [list]="clients" label="missions.create.client.label"
            errorMsg="missions.create.client.errorMessage"></app-autocomplete>
    </section>

    <mat-form-field>
        <mat-label>{{ 'repository.samples.edit.name.label' | translate }}</mat-label>
        <input type="text" matInput formControlName="name">
        <mat-error>{{ 'repository.samples.edit.name.errorMessage' | translate }}</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ 'repository.samples.edit.sortOrder.label' | translate }}</mat-label>
        <input type="number" matInput formControlName="sortOrder" [errorStateMatcher]="confirmValidMatcher">
        <mat-error>{{ 'repository.samples.edit.sortOrder.errorMessage' | translate }}</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ 'repository.samples.edit.lowThreshold.label' | translate }}</mat-label>
        <input type="number" matInput formControlName="lowThreshold" [errorStateMatcher]="confirmValidMatcher">
        <mat-error>{{ 'repository.samples.edit.lowThreshold.errorMessage' | translate }}</mat-error>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{ 'repository.samples.edit.highThreshold.label' | translate }}</mat-label>
        <input type="number" matInput formControlName="highThreshold" [errorStateMatcher]="confirmValidMatcher">
        <mat-error>{{ 'repository.samples.edit.highThreshold.errorMessage' | translate }}</mat-error>
    </mat-form-field>

    <button *ngIf="!isLoading" type="submit" mat-icon-button color="accent" [disabled]="editForm.invalid">
        <mat-icon aria-label="Validate">check</mat-icon>
    </button>
    <button *ngIf="!isLoading" type="button" mat-icon-button (click)="onCancel()">
        <mat-icon aria-label="Cancel">clear</mat-icon>
    </button>
</form>
<mat-spinner *ngIf="isLoading" [diameter]="35" color="accent" ></mat-spinner>
