<mat-card>
    <mat-card-header>
        <mat-card-title>
            <h1>{{ 'repository.addresses.title' | translate }}</h1>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <section class="creation-form">
            <button *ngIf="!isCreationMode" (click)="onEdit()"
                [matTooltip]="'repository.addresses.list.add' | translate"
                mat-mini-fab class="action-button" color="accent">
                <mat-icon aria-label="Add">add</mat-icon>
            </button>
            <app-address-edit *ngIf="isCreationMode && !editedId" (editFinished)="onEditFinished()"></app-address-edit>
        </section>

        <section class="datatable-container">
            <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
                <ng-container matColumnDef="street">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'repository.addresses.list.street' | translate }} </th>
                    <td mat-cell *matCellDef="let address"> {{ address.street }} </td>
                </ng-container>

                <ng-container matColumnDef="streetNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'repository.addresses.list.streetNumber' | translate }} </th>
                    <td mat-cell *matCellDef="let address"> {{ address.streetNumber }} </td>
                </ng-container>

                <ng-container matColumnDef="postalCode">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'repository.addresses.list.postalCode' | translate }} </th>
                    <td mat-cell *matCellDef="let address"> {{ address.postalCode }} </td>
                </ng-container>

                <ng-container matColumnDef="locality">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'repository.addresses.list.locality' | translate }} </th>
                    <td mat-cell *matCellDef="let address"> {{ address.locality }} </td>
                </ng-container>

                <ng-container matColumnDef="coordinates">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'repository.addresses.list.coordinates' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let address"> {{ address.latitude }} - {{ address.longitude }} </td>
                </ng-container>

                <ng-container matColumnDef="client">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Client </th>
                    <td mat-cell *matCellDef="let address">
                        {{ address.client.name }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> {{ 'repository.addresses.list.actions' | translate }} </th>
                    <td mat-cell *matCellDef="let address">
                        <ng-container *ngIf="address.id !== editedId">
                            <button mat-icon-button class="action-button" color="accent"
                                [disabled]="isLoading"
                                [matTooltip]="'repository.addresses.list.edit' | translate"
                                (click)="onEdit(address.id)">
                                <mat-icon aria-label="Edit">edit</mat-icon>
                            </button>
                        </ng-container>
                        <ng-container>
                            <button mat-icon-button class="action-button" color="accent"
                                [disabled]="isLoading"
                                [matTooltip]="'repository.addresses.list.delete' | translate"
                                (click)="delete(address.id)">
                                <mat-icon aria-label="Delete">clear</mat-icon>
                            </button>
                        </ng-container>
                    </td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let address" [attr.colspan]="displayedColumns.length">
                        <div *ngIf="address.id === editedId" class="row-edit"
                            [@detailExpand]="address.id === editedId ? 'expanded' : 'collapsed'">
                            <app-address-edit [address]="address" (editFinished)="onEditFinished()"></app-address-edit>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row"
                    [ngClass]="{ 'expanded-row': row.id === editedId }"></tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
            </table>
            <mat-progress-bar *ngIf="isLoading"  class="progress-bar" mode="indeterminate" color="accent"></mat-progress-bar>
        </section>
    </mat-card-content>
</mat-card>
