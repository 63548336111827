import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SharedModule } from 'app/shared/shared.module';

import { RepositoryComponent } from './repository.component';
import { ClientsComponent } from './clients/clients.component';
import { ContactsComponent } from './contacts/contacts.component';
import { DevicesComponent } from './devices/devices.component';
import { SamplesComponent } from './samples/samples.component';
import { StepsComponent } from './steps/steps.component';
import { ContactEditComponent } from './contacts/contact-edit/contact-edit.component';
import { ClientEditComponent } from './clients/clients-edit/client-edit.component';
import { SampleEditComponent } from './samples/sample-edit/sample-edit.component';
import { StepEditComponent } from './steps/step-edit/step-edit.component';
import { AddressesComponent } from './addresses/addresses.component';
import { AddressEditComponent } from './addresses/address-edit/address-edit.component';

@NgModule({
    declarations: [
        RepositoryComponent,
        ClientsComponent,
        ContactsComponent,
        DevicesComponent,
        SamplesComponent,
        StepsComponent,
        ContactEditComponent,
        ClientEditComponent,
        SampleEditComponent,
        StepEditComponent,
        AddressesComponent,
        AddressEditComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        SharedModule,
        ReactiveFormsModule
    ]
})
export class RepositoryModule { }
