import { NotificationResponse } from './notification-response';
import { PushType } from '@enums/push-type.enum';

export class MissionNotification {
    id: string;
    name: string;
    tagScanned: boolean;
    notificationId?: string;
    stepId?: string;
    stepName?: string;
    status?: string;
    icon?: string;
    sentOn?: Date;

    constructor(response: NotificationResponse) {
        this.notificationId = response.id || null;
        this.id = response.payload.id;
        this.name = response.payload.name;
        this.tagScanned = response.payload.tagScanned;

        this.stepId = response.payload.stepId || null;
        this.stepName = response.payload.stepName || null;

        this.sentOn = response.createdOn || new Date();

        this.status = 'main.notifications.missions.';

        switch (response.type) {
            case PushType.missionCreated:
                this.status += 'created';
                this.icon = 'add';
                break;
            case PushType.missionSeen:
                this.status += 'seen';
                this.icon = 'remove_red_eye';
                break;
            case PushType.missionStarted:
                this.status += 'started';
                this.icon = 'play_arrow';
                break;
            case PushType.missionPaused:
                this.status += 'paused';
                this.icon = 'pause';
                break;
            case PushType.stepArrived:
                this.status += 'stepArrived';
                this.icon = 'flag';
                break;
            case PushType.stepIgnored:
                this.status += 'stepIgnored';
                this.icon = 'close';
                break;
            case PushType.stepCompleted:
                this.status += 'stepCompleted';
                this.icon = 'done';
                break;
            case PushType.StepActivated:
                this.status += 'stepActivated';
                this.icon = 'close';
                break;
            case PushType.StepDeactivated:
                this.status += 'stepDeactivated';
                this.icon = 'done';
                break;
            case PushType.clientArrived:
                this.status += 'clientArrived';
                this.icon = 'flag';
                break;
            case PushType.missionCompleted:
                this.status += 'missionCompleted';
                this.icon = 'done_all';
                break;
            case PushType.MissionReassigned:
                this.status += 'missionReassigned';
                this.icon = 'assignment_ind';
                break;
            case PushType.MissionCancelled:
                this.status += 'missionCancelled';
                this.icon = 'cancel';
                break;
            case PushType.missionUpdated:
            default:
                this.status += 'updated';
        }
    }
}
