<mat-card class="login-card">
    <mat-card-header>
        <mat-card-title>
            <h1>{{'auth.login.title' | translate}}</h1>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <mat-form-field>
                <mat-label>{{'auth.login.username.label' | translate}}</mat-label>
                <mat-icon matPrefix>person</mat-icon>
                <input type="text" matInput formControlName="username">
                <mat-error>{{'auth.login.username.errorMessage' | translate}}</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'auth.login.password.label' | translate}}</mat-label>
                <mat-icon matPrefix>lock</mat-icon>
                <input type="password" matInput formControlName="password">
                <!-- <mat-hint align="end"><a routerLink="/forgot-password">{{'auth.forgotPassword' | translate}}</a></mat-hint> -->
                <mat-error>{{'auth.login.password.errorMessage' | translate}}</mat-error>
            </mat-form-field>
            <div class="passwordLinkContainer">
                <a routerLink="/forgot-password" class="passwordLink">{{'auth.forgotPassword' | translate}}</a>
            </div>
            <button type="submit" mat-raised-button color="accent"
                *ngIf="!isLoading"
                [disabled]="loginForm.invalid">{{'global.actions.submit' | translate}}</button>
            <mat-spinner *ngIf="isLoading" [diameter]="35" color="accent" ></mat-spinner>
        </form>
    </mat-card-content>
</mat-card>
