<mat-card class="steps-card" *ngIf="report">
    <mat-card-header *ngIf="report.step">
        <mat-card-title>
            <h2>
                {{ 'missions.report.title' | translate }}
            </h2>
        </mat-card-title>
        <mat-card-subtitle>
            {{ report.step.name }}
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-header *ngIf="!report.step">
        <h2>{{ 'missions.report.clientTitle' | translate }}</h2>
    </mat-card-header>
    <mat-card-content>
        <mat-tab-group color="accent">
            <mat-tab *ngIf="report.samples && report.samples.length"
                [label]="'missions.report.pickup' | translate">
                <section class="samples-list">
                    <h3>{{ 'missions.report.samples' | translate }}</h3>
                    <mat-list>
                        <mat-list-item *ngFor="let sample of report.samples">
                            <mat-icon mat-list-icon color="accent">colorize</mat-icon>
                            <span mat-line>
                                {{ sample.name }}
                                <span *ngIf="sample.temperature" class="temperature" [ngClass]="{'red': sample.temperature < sample.lowThreshold || sample.temperature > sample.highThreshold}">
                                    ({{ sample.temperature }} °C)
                                </span>
                            </span>
                            <span mat-line>{{ 'missions.report.quantity' | translate }} {{ sample.quantity }}</span>
                            <button mat-icon-button *ngIf="sample.hasAttachment" color="accent"
                                [matTooltip]="'missions.report.attachment' | translate"
                                [disabled]="isLoading"
                                (click)="onGetAttachment(sample.id)">
                                <mat-icon aria-label="Photo">
                                    photo
                                </mat-icon>
                            </button>
                            <mat-spinner *ngIf="isLoading && sample.id === selectedSampleId" [diameter]="35" color="accent"></mat-spinner>
                        </mat-list-item>
                    </mat-list>
                </section>
                <section class="comment" *ngIf="report.pickupComment">
                    <h3>{{ 'missions.report.comment' | translate }}</h3>
                    <p>{{ report.pickupComment }}</p>
                </section>
            </mat-tab>
            <mat-tab *ngIf="report.barcode"
                [label]="'missions.report.delivery' | translate">
                <section class="pickup-detail">
                    <h3>{{ 'missions.report.detail' | translate }}</h3>
                    <mat-list>
                        <mat-list-item>
                            <span class="label">{{ 'missions.report.firstname' | translate }}</span>
                            <span>{{ report.firstname }}</span>
                        </mat-list-item>
                        <mat-list-item>
                            <span class="label">{{ 'missions.report.lastname' | translate }}</span>
                            <span>{{ report.lastname }}</span>
                        </mat-list-item>
                        <mat-list-item>
                            <span class="label">{{ 'missions.report.barcode' | translate }}</span>
                            <mat-icon *ngIf="report.barcodeScannedOn">nfc</mat-icon>
                            <span>{{ report.barcode }}</span>
                        </mat-list-item>
                    </mat-list>
                </section>
                <section class="comment" *ngIf="report.deliveryComment">
                    <h3>{{ 'missions.report.comment' | translate }}</h3>
                    <p>{{ report.deliveryComment }}</p>
                </section>
            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>
