import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { UiService } from "@services/ui.service";
import { DashboardService } from '@services/dashboard.service';
import { Dashboard } from '@classes/dashboard';


@Injectable()
export class DashboardResolver implements Resolve<Dashboard> {
    constructor(
        private dashSrv: DashboardService,
        private uiSrv: UiService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Dashboard> {
        this.uiSrv.routeLoadingStateChanged.next(true);

        return this.dashSrv.get()
        .pipe(
            tap(() => {
                this.uiSrv.routeLoadingStateChanged.next(false);
            })
        );
    }
}
