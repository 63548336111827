import { Component, OnInit, Input } from '@angular/core';
import { FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BaseRepository } from '@interfaces/base-repository';

@Component({
    selector: 'app-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent<T extends BaseRepository> implements OnInit {
    @Input() list: T[];
    @Input() control: FormControl;
    @Input() label: string;
    @Input() errorMsg: string;
    @Input() number: number;
    @Input() numberContact: number;
    @Input() lastStep: boolean;
    @Input() inOneStep: boolean;
    @Input() noDefault: boolean;

    filteredList: Observable<T[]>;

    constructor() { }

    ngOnInit() {
        this.setfilters();

        if (this.list?.length === 1 && !this.noDefault) {
            this.control.setValue(this.list[0]);
        }
    }

    displayFn(object: T): string | undefined {
        return object ? object.display : null;
    }

    private setfilters(): void {
        const validators = [this.forbiddenTypeValidator('string')];

        if (this.control.validator) {
            validators.push(this.control.validator);
        }

        this.control.setValidators(validators);

        this.filteredList = this.control.valueChanges
            .pipe(
                startWith(''),
                map((value: string | T) => typeof value === 'string' ? value : value?.display),
                map((value: string) => value ? this.filter(value) : this.list?.slice())
            );
    }

    private filter(value: string): T[] {
        const filterValue = value.toLowerCase();
        return this.list.filter(item => item.doesInclude(filterValue));
    }

    private forbiddenTypeValidator(forbiddenType: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const forbidden = control.value !== '' && typeof control.value === forbiddenType;
            return forbidden ? { forbiddenType: { value: control.value } } : null;
        };
    }
}
