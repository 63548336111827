import { BaseClass } from '../base-class';
import { Client } from '../client';
import { Template } from '../template';
import { User } from '../user/user';
import { MissionStatus } from '@enums/mission-status.enum';
import { Step } from '../step/step';
import { MissionSchedule } from './mission-schedule';
import { Sample } from '@classes/sample';

export class Mission extends BaseClass {
    name: string;
    comment?: string;
    isUrgent: boolean;
    isFreeMode: boolean;
    startDate: Date;
    user: User;
    schedules: MissionSchedule[];

    template?: Template;
    step?: Step;
    client?: Client;
    alerts?: number;
    samples?: Sample[];

    status?: MissionStatus;
    startedOn?: Date;
    pausedOn?: Date;
    cancelOn?: Date;
    finishedOn?: Date;
    arrivedOn?: Date;
    seenOn?: Date;
    steps?: Step[];
    totalEstimatedTime?: number;

    constructor()
    constructor(name: string, schedules: MissionSchedule[], isUrgent: boolean, isFreeMode: boolean,
                template?: Template, steps?: Step[], comment?: string, client?: Client)
    constructor(name?: string, schedules?: MissionSchedule[], isUrgent?: boolean, isFreeMode?: boolean,
                template?: Template, steps?: Step[], comment?: string, client?: Client) {
        super();

        this.name = this.trim(name);
        this.schedules = schedules;
        this.isUrgent = isUrgent != null ? isUrgent : false;
        this.isFreeMode = isFreeMode != null ? isFreeMode : false;
        this.template = template || null;
        this.steps = steps || null;
        this.client = client || null;
        this.comment = this.trim(comment);
    }

    static fromObject(object: Mission): Mission {
        return Object.assign(new Mission(), {
            ...object,
            template: object.template ? Template.fromObject(object.template) : null,
            client: object.client ? Client.fromObject(object.client) : null,
            user: object.user ? User.fromObject(object.user) : null,
            steps: object.steps.map(o => Step.fromObject(o)) || []
        });
    }

    get display(): string {
        return this.name;
    }

    doesInclude(value: string): boolean {
        return this.some([this.name], value);
    }
}
