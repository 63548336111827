import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParametersComponent } from './parameters.component';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
    declarations: [
        ParametersComponent
    ],
    imports: [
        CommonModule,
        SharedModule
    ]
})
export class ParametersModule { }
