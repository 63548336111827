<form class="edit-form" *ngIf="!isLoading && contacts && addresses && editForm" [formGroup]="editForm"
    (submit)="onSubmit()">
    <mat-form-field>
        <mat-label>{{ 'repository.clients.edit.name.label' | translate }}</mat-label>
        <input type="text" matInput formControlName="name">
        <mat-error>{{ 'repository.clients.edit.name.errorMessage' | translate }}</mat-error>
    </mat-form-field>

    <section class="form-field">
        <app-autocomplete [control]="getControl('address')" [list]="addresses"
            label="repository.clients.edit.address.label"
            errorMsg="repository.clients.edit.address.errorMessage"></app-autocomplete>
    </section>

    <section class="form-field">
        <app-autocomplete [control]="getControl('step')" [list]="steps"
            label="repository.clients.edit.step.label"></app-autocomplete>
    </section>

    <fieldset>
        <legend>Contacts</legend>

        <ng-container formArrayName="contacts">
            <section class="form-field" *ngFor="let contactControl of getArray('contacts').controls; index as i"
                class="contactSection">
                <app-autocomplete [control]="contactControl" [list]="contacts"
                    [label]="i <= 1 ? (i === 0 ? 'repository.clients.edit.contact.default' : 'repository.clients.edit.contact.secondary') : 'repository.clients.edit.contact.number'"
                    [numberContact]="i">
                </app-autocomplete>
                <button type="button" mat-icon-button color="accent">
                    <mat-icon class="clearButton" (click)="removeContact(contactControl)"
                        [matTooltip]="'repository.steps.edit.removeContact' | translate">clear</mat-icon>
                </button>
            </section>
            <button type="button" mat-icon-button color="accent" (click)="onAddContact()">
                <mat-icon aria-label="Add"
                    [matTooltip]="'repository.clients.edit.addContact' | translate">add</mat-icon>
            </button>
        </ng-container>
    </fieldset>

    <fieldset>
        <legend>SMS
            <mat-icon [matTooltip]="'repository.clients.edit.textSms.tooltip' | translate"
                color="primary">help_center</mat-icon>
        </legend>
        <div class="buttonsSMS">
            <button mat-raised-button color="primary" (click)="$event.preventDefault();addTag('{Etape}')"
                [disabled]="editForm.value.textSms ? editForm.value.textSms.includes('{Etape}') : false">
                Etape
            </button>
            <button mat-raised-button color="primary" (click)="$event.preventDefault();addTag('{Client}')"
                [disabled]="editForm.value.textSms ? editForm.value.textSms.includes('{Client}') : false">
                Client
            </button>
            <button mat-raised-button color="primary" (click)="$event.preventDefault();addTag('{Heure}')"
                [disabled]="editForm.value.textSms ? editForm.value.textSms.includes('{Heure}') : false">
                Heure
            </button>
        </div>

        <mat-form-field class="sms-field">
            <mat-label>{{ 'repository.clients.edit.textSms.label' | translate }}</mat-label>
            <textarea id="smsInput" matInput rows="5" formControlName="textSms"></textarea>
            <mat-error>{{ 'repository.clients.edit.textSms.errorMessage' | translate: { maxLength: smsMaxLength } }}
            </mat-error>
        </mat-form-field>

    </fieldset>

    <button *ngIf="!isLoading" type="submit" mat-icon-button color="accent" [disabled]="editForm.invalid">
        <mat-icon aria-label="Validate">check</mat-icon>
    </button>
    <button *ngIf="!isLoading" type="button" mat-icon-button (click)="onCancel()">
        <mat-icon aria-label="Cancel">clear</mat-icon>
    </button>
</form>

<mat-spinner *ngIf="isLoading" [diameter]="35" color="accent"></mat-spinner>