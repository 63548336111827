<h1 mat-dialog-title>{{data.onActivate ? 'Activation' : 'Désactivation'}} sur appel</h1>
<div mat-dialog-content>
    <form [formGroup]="initialsForm" class="form">
        <mat-form-field>
            <mat-label>Quelles sont vos initiales ?</mat-label>
            <input matInput type="text" maxlength="2" formControlName="initials">
        </mat-form-field>

        <div class="actions">
            <button class="driverButton" mat-raised-button color="accent" type="button" (click)="confirmActivation()"
                [disabled]="initialsForm.pristine || initialsForm.invalid">Valider</button>
            <button class="driverButton" mat-raised-button color="accent" type="button"
                (click)="onNoClick()">Annuler</button>
        </div>

    </form>
</div>
