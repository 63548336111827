import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
    code: number;
    message: string;

    constructor(
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.code = this.route.snapshot.queryParams.code;
        this.message = this.route.snapshot.queryParams.message;
    }
}
