import { Role } from '../role';
import { RoleName } from '@enums/role-name.enum';

export class AuthUser {
    username: string;
    role: Role;
    clientId: string;

    constructor(username: string, role: Role, clientId: string) {
        this.username = username;
        this.role = role;
        this.clientId = clientId;
    }

    public get isReadOnly(): boolean {
        return (
            this.role.name === RoleName.driver
            || this.role.name === RoleName.labo
        );
    }
}
