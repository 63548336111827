import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Device } from '@classes/device';

@Injectable({
    providedIn: 'root'
})
export class DevicesService {
    devicesListChanged = new Subject<Device[]>();
    private baseUrl: string = environment.apiUrl + 'devices';
    private devices: Device[];

    constructor(
        private http: HttpClient
    ) { }

    getList(): Observable<Device[]> {
        return this.http.get<Device[]>(this.baseUrl)
        .pipe(
            map(devices => {
                this.devices = devices.map(d => Device.fromObject(d));
                this.devicesListChanged.next([ ...this.devices ]);
                return [ ...this.devices ];
            })
        );
    }
}
