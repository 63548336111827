import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Subject, Observable, BehaviorSubject, Subscription } from 'rxjs';
import { tap, map, timeout } from 'rxjs/operators';
import * as FileSaver from 'file-saver';

import { environment } from '@environments/environment';
import { Mission } from '@classes/mission/mission';
import { UiService } from './ui.service';
import { Pagination } from '@classes/pagination/pagination';
import { PaginationRequest } from '@classes/pagination/pagination-request';
import { Step } from '@classes/step/step';

import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class MissionsService {
    missionsListChanged = new Subject<Mission[]>();
    selectedMissionChanged = new Subject<Mission>();
    paginationSubject = new BehaviorSubject<Pagination>(null);

    private baseUrl: string = environment.apiUrl + 'missions';
    private missions: Mission[];
    private lastQuery: string;
    private lastQueryObj: {
        name: null,
        template: null,
        status: null,
        user: null,
        client: null,
        fromStartDate: null,
        fromCreatedOn: null,
        fromModifiedOn: null,
        createdBy: null
    };
    private getListSub: Subscription;
    private selectedMission: Mission;
    private allNoEndedMissions: Mission[];

    constructor(
        private http: HttpClient,
        private uiSrv: UiService
    ) { }

    getList(request: PaginationRequest): void {
        this.uiSrv.loadingStateChanged.next(true);
        this.missionsListChanged.next([]);

        let httpParams = new HttpParams();

        if (this.getListSub) {
            this.getListSub.unsubscribe();
        }


        if (request.pageSize && request.includeCount && request.page) {
            httpParams = httpParams
                .append("pageSize", request.pageSize.toString())
                .append("includeCount", request.includeCount.toString())
                // .append("page", request.query !== this.lastQuery ? "1" : request.page.toString());
                .append("page", request.query !== this.lastQueryObj ? "1" : request.page.toString());
        }

        if (request.query) {
            // httpParams = httpParams.append("filter", request.query);
            const filters = request.query;
            if (filters.name) {
                httpParams = httpParams.append("Name", request.query.name);
            }
            if (filters.template) {
                httpParams = httpParams.append("Template", request.query.template);
            }
            if (filters.status) {
                httpParams = httpParams.append("Status", request.query.status);
            }
            if (filters.user) {
                httpParams = httpParams.append("User", request.query.user);
            }
            if (filters.client) {
                httpParams = httpParams.append("Client", request.query.client);
            }
            if (filters.fromStartDate) {
                httpParams = httpParams.append("FromStartDate", moment(request.query.fromStartDate).startOf('day').utc().format().toString());
            }
            if (filters.toStartDate) {
                httpParams = httpParams.append("ToStartDate", moment(request.query.toStartDate).endOf('day').utc().format().toString());
            }
            if (filters.createdBy) {
                httpParams = httpParams.append("CreatedBy", request.query.createdBy);
            }
            if (filters.step) {
                httpParams = httpParams.append("StepId", request.query.step.id);
            }
        }

        if (request.sort) {
            httpParams = httpParams.append("sort", request.sort);
        }

        if (request.fromStartDate && request.toStartDate) {
            httpParams = httpParams
                .append("fromStartDate", request.fromStartDate.toDateString())
                .append("toStartDate", request.toStartDate.toDateString());
        }

        // this.lastQuery = request.query;
        this.lastQueryObj = request.query;

        this.getListSub = this.http.get(this.baseUrl, { params: httpParams, observe: 'response' })
            .subscribe((result: HttpResponse<Mission[]>) => {
                this.uiSrv.loadingStateChanged.next(false);

                const pagination: Pagination = JSON.parse(result.headers.get('X-Pagination'));

                this.missions = result.body;
                this.missionsListChanged.next([...this.missions]);
                this.paginationSubject.next({ ...pagination });
            });
    }

    getAllNoEndedMissions(): Observable<Mission[]> {
        return this.http.get<Mission[]>(this.baseUrl)
            .pipe(
                map(missions => {
                    this.allNoEndedMissions = missions.map(m => Mission.fromObject(m));
                    return this.allNoEndedMissions.filter(m => !m.finishedOn && !m.cancelOn);
                })
            );
    }

    get(id: string): Observable<Mission> {
        return this.http.get<Mission>(`${this.baseUrl}/${id}`)
            .pipe(
                map(missionFromApi => {
                    missionFromApi = Mission.fromObject(missionFromApi);
                    this.selectedMission = missionFromApi;
                    this.selectedMissionChanged.next(missionFromApi);
                    return missionFromApi;
                })
            );
    }

    getListForCopyStep(): Observable<Mission[]> {
        const dateNow = moment(new Date()).startOf('day').utc().format().toString();
        return this.http.get<Mission[]>(`${this.baseUrl}?status=7&fromStartDate=${dateNow}`)
            .pipe(
                map(missions => {
                    return missions.map(m => Mission.fromObject(m));
                })
            );
    }

    get selectedId(): string {
        return this.selectedMission ? this.selectedMission.id : null;
    }

    exportExcel(missions?: string[]): Observable<Blob> {
        this.uiSrv.loadingStateChanged.next(true);
        return this.http.get(`${this.baseUrl}/xlsx?ids=${missions}`, { observe: 'response', responseType: 'blob' })
            .pipe(map(res => this.saveFile(res)));
    }

    exportPdf(missions?: string[]): Observable<Blob> {
        this.uiSrv.loadingStateChanged.next(true);
        return this.http.get(`${this.baseUrl}/pdf?ids=${missions}`, { observe: 'response', responseType: 'blob' })
            .pipe(map(res => this.saveFile(res)));
    }

    put(mission: Mission): Observable<Mission> {
        return this.http.put<Mission>(this.baseUrl, mission)
            .pipe(
                tap(missionFromApi => {
                    missionFromApi = Mission.fromObject(missionFromApi);
                    this.selectedMissionChanged.next(missionFromApi);
                })
            );
    }

    post(mission: Mission): Observable<Mission> {
        return this.http.post<Mission>(this.baseUrl, mission);
    }

    delete(missionsId?: string[]): Observable<void> {
        let body = {
            missionIds: missionsId
        };
        return this.http.delete<void>(`${this.baseUrl}`, { body: body })
            .pipe(
                tap(() => {
                    this.missions = this.missions.filter(function (m) {
                        let hasBeenFound = false;
                        missionsId.map(missionId => {
                            if (m.id === missionId) {
                                hasBeenFound = true;
                            }
                        })
                        if (hasBeenFound) {
                            return false;
                        } else {
                            return true;
                        }
                    });
                    this.missionsListChanged.next([...this.missions]);
                })
            );
    }

    pause(id: string): Observable<Mission> {
        return this.http.put<Mission>(`${this.baseUrl}/${id}/pause`, {})
            .pipe(
                tap(missionFromApi => {
                    this.selectedMissionChanged.next(missionFromApi);
                })
            );
    }

    restart(id: string): Observable<Mission> {
        return this.http.put<Mission>(`${this.baseUrl}/${id}/start`, {})
            .pipe(
                tap(missionFromApi => {
                    this.selectedMissionChanged.next(missionFromApi);
                })
            );
    }

    cancelForEver(id: string): Observable<Mission> {
        return this.http.put<Mission>(`${this.baseUrl}/${id}/cancel`, {})
            .pipe(
                tap(missionFromApi => {
                    this.selectedMissionChanged.next(missionFromApi);
                })
            );
    }

    activateStep(stepId: string, initials: string): Observable<Step> {
        return this.http.put<Step>(`${this.baseUrl}/${this.selectedId}/steps/${stepId}/activate?initials=${initials}`, {})
            .pipe(
                tap((stepFromApi: Step) => {
                    stepFromApi.onCallActivatedBy = initials;
                    this.selectedMission.steps = this.selectedMission.steps
                        .map(s => s.missionStepId === stepFromApi.missionStepId ? Step.fromObject(stepFromApi) : s);
                    this.selectedMissionChanged.next(this.selectedMission);
                    this.uiSrv.showSnackbar('repository.steps.stepActivated', true);
                })
            );
    }

    deactivateStep(stepId: string, initials: string): Observable<Step> {
        return this.http.put<Step>(`${this.baseUrl}/${this.selectedId}/steps/${stepId}/deactivate?initials=${initials}`, {})
            .pipe(
                tap((stepFromApi: Step) => {
                    stepFromApi.onCallDeactivatedBy = initials;
                    this.selectedMission.steps = this.selectedMission.steps
                        .map(s => s.missionStepId === stepFromApi.missionStepId ? Step.fromObject(stepFromApi) : s);
                    this.selectedMissionChanged.next(this.selectedMission);
                    this.uiSrv.showSnackbar('repository.steps.stepDeactivated', true);
                })
            );
    }

    calcCompletion(steps: Step[]): number {
        const stepsAllActivated = steps.filter(s => s.onCall === false || (s.onCall === true && s.onCallActivated));
        const stepsDone: number = steps.filter(s => s.finishedOn != null || s.ignoredOn != null).length;


        // if(steps.length === 0) {
        //     return 0;
        // }
        if (stepsAllActivated.length === 0) {
            return 0;
        }

        // return stepsDone / steps.length;
        return stepsDone / stepsAllActivated.length;
    }

    private saveFile(res: HttpResponse<Blob>): Blob {
        FileSaver.saveAs(res.body, res.headers.get('filename'));
        this.uiSrv.loadingStateChanged.next(false);
        return res.body;
    }

    copySteps(id: string, idFrom: string): Observable<Mission> {
        return this.http.put<Mission>(`${this.baseUrl}/${id}/copy/${idFrom}`, {})
            .pipe(
                tap((missionFromApi: Mission) => {
                    missionFromApi = Mission.fromObject(missionFromApi);
                    this.selectedMissionChanged.next(missionFromApi);
                })
            );
    }
}
