import { OnInit, OnDestroy, ViewChild, Component } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';

import { UiService } from '@services/ui.service';

@Component({
    template: ""
})
export class DatatableBaseComponent implements OnInit, OnDestroy {
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    private subscription = new Subscription();

    displayedColumns: string[];
    isLoading: boolean;

    editedId: number;
    isCreationMode: boolean;

    constructor(
        protected uiSrv: UiService
    ) {}

    ngOnInit() {
        this.initBaseSubs();
    }

    onEdit(id?: number): void {
        this.isCreationMode = !id;
        this.editedId = id;
    }

    onEditFinished(): void {
        this.editedId = null;
        this.isCreationMode = false;
    }

    private initBaseSubs(): void {
        this.subscription.add(
            this.uiSrv.loadingStateChanged
            .subscribe(loadingState => {
                this.isLoading = loadingState;
            })
        );
    }

    protected addSubscription(newSub: Subscription): void {
        this.subscription.add(newSub);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
