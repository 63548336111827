import { ClientsService } from '@services/clients.service';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup, ValidationErrors } from '@angular/forms';

import { FormEditBaseComponent } from '@components/form-edit-base.component';
import { Sample } from '@classes/sample';
import { SamplesService } from '@services/samples.service';
import { UiService } from '@services/ui.service';
import { ConfirmValidMatcher } from '@classes/validations/confirm-valid-matcher';
import { Client } from '@classes/client';


@Component({
    selector: 'app-sample-edit',
    templateUrl: './sample-edit.component.html',
    styleUrls: ['./sample-edit.component.scss']
})
export class SampleEditComponent  extends FormEditBaseComponent implements OnInit {
    @Input() sample: Sample;
    @Input() sortOrders: number[];
    clients: Client[];

    confirmValidMatcher = new ConfirmValidMatcher();

    constructor(
        protected uiSrv: UiService,
        protected fb: FormBuilder,
        private samplesSrv: SamplesService,
        private clientsSrv: ClientsService
    ) {
        super(uiSrv, fb);
    }

    ngOnInit() {
        super.ngOnInit();
        this.initSubscriptions();
    }

    private initSubscriptions(): void {
        this.isLoading = true;

        const clientsPromise = new Promise((resolve, reject) => {
            this.clientsSrv.getList()
            .subscribe(clientsFromSrv => {
                this.clients = clientsFromSrv;
                resolve(clientsFromSrv);
            });
        });

        Promise.all([clientsPromise])
        .then(() => {
            this.initForm();
            this.isLoading = false;
        });
    }

    onSubmit(): void {
        this.isLoading = true;
        if (this.sample) {
            this.update();
        } else {
            this.create();
        }
    }

    onCancel(): void {
        this.editFinished.emit();
    }

    private update(): void {
        const editedValues = this.editForm.value;
        const sampleCopy: Sample = JSON.parse(JSON.stringify(this.sample));
        sampleCopy.name = editedValues.name;
        sampleCopy.client = editedValues.client;
        sampleCopy.lowThreshold = editedValues.lowThreshold;
        sampleCopy.highThreshold = editedValues.highThreshold;
        sampleCopy.sortOrder = editedValues.sortOrder;

        this.samplesSrv.put(sampleCopy).subscribe(() => this.isLoading = false);
    }

    private create(): void {
        const editedValues = this.editForm.value;
        this.sample = new Sample(
            editedValues.name,
            editedValues.client,
            editedValues.lowThreshold,
            editedValues.highThreshold,
            editedValues.sortOrder
        );

        this.samplesSrv.post(this.sample)
        .subscribe(
            () => this.isLoading = false,
            () => this.sample = null
        );
    }

    private initForm(): void {
        let name = "";
        let client = null;
        let sortOrder = this.sortOrders[this.sortOrders.length - 1] + 1;
        let lowThreshold: number = null;
        let highThreshold: number = null;

        if (this.sample) {
            name = this.sample.name;
            client = this.sample.client;
            sortOrder = this.sample.sortOrder;
            lowThreshold = this.sample.lowThreshold;
            highThreshold = this.sample.highThreshold;
        }

        this.editForm = this.fb.group({
            name: [name, Validators.required],
            client: [client, Validators.required],
            sortOrder: [sortOrder, Validators.required],
            lowThreshold: [lowThreshold],
            highThreshold: [highThreshold]
        }, {
            validators: [
                this.thresholdMatchValidator,
                this.sortOrderValidator.bind(this)
            ]
        });
    }

    private thresholdMatchValidator(group: FormGroup): ValidationErrors | null {
        const lowThreshold = group.get('lowThreshold');
        const highThreshold = group.get('highThreshold');

        if(lowThreshold.value == null || highThreshold.value == null) {
            return null;
        }

        return lowThreshold.value > highThreshold.value ? { mismatch: true } : null;
    }

    private sortOrderValidator(group: FormGroup): ValidationErrors | null {
        const sortOrder = group.get('sortOrder');

        if(sortOrder.value == null) {
            return null;
        }

        if(this.sample && this.sample.sortOrder === sortOrder.value) {
            return null;
        }

        return this.sortOrders.indexOf(sortOrder.value) !== -1 ? { mismatch: true } : null;
    }
}
