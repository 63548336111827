import { ClientsService } from '@services/clients.service';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { FormEditBaseComponent } from '@components/form-edit-base.component';
import { UiService } from '@services/ui.service';
import { Address } from '@classes/address';
import { AddressesService } from '@services/addresses.service';
import { Client } from '@classes/client';

@Component({
  selector: 'app-address-edit',
  templateUrl: './address-edit.component.html',
  styleUrls: ['./address-edit.component.scss']
})
export class AddressEditComponent extends FormEditBaseComponent implements OnInit {
    @Input() address: Address;
    clients: Client[];

    constructor(
        protected uiSrv: UiService,
        protected fb: FormBuilder,
        private addressesSrv: AddressesService,
        private clientsSrv: ClientsService
    ) {
        super(uiSrv, fb);
    }

    ngOnInit() {
        super.ngOnInit();
        this.initSubscriptions();
    }


    private initSubscriptions(): void {
        this.isLoading = true;

        const clientsPromise = new Promise((resolve, reject) => {
            this.clientsSrv.getList()
            .subscribe(clientsFromSrv => {
                this.clients = clientsFromSrv;
                resolve(clientsFromSrv);
            });
        });

        Promise.all([clientsPromise])
        .then(() => {
            this.initForm();
            this.isLoading = false;
        });
    }

    onSubmit(): void {
        this.isLoading = true;
        if (this.address) {
            this.update();
        } else {
            this.create();
        }
    }

    onCancel(): void {
        this.editFinished.emit();
    }

    private update(): void {
        const addressCopy: Address = JSON.parse(JSON.stringify(this.address));
        addressCopy.client  = this.editForm.value.client;
        addressCopy.street  = this.editForm.value.street;
        addressCopy.streetNumber  = this.editForm.value.streetNumber;
        addressCopy.locality  = this.editForm.value.locality;
        addressCopy.postalCode  = this.editForm.value.postalCode;
        addressCopy.latitude = this.editForm.value.latitude;
        addressCopy.longitude = this.editForm.value.longitude;

        this.addressesSrv.put(addressCopy).subscribe(() => this.isLoading = false);
    }

    private create(): void {
        this.address = new Address(
            this.editForm.value.street,
            this.editForm.value.streetNumber,
            this.editForm.value.locality,
            this.editForm.value.postalCode,
            this.editForm.value.client,
            this.editForm.value.latitude,
            this.editForm.value.longitude
        );

        this.addressesSrv.post(this.address)
        .subscribe(
            () => this.isLoading = false,
            () => this.address = null
        );
    }

    private initForm(): void {
        let street = '';
        let streetNumber = '';
        let locality = '';
        let postalCode = '';
        let latitude = null;
        let longitude = null;
        let client = null;

        if (this.address) {
            client = this.address.client;
            street = this.address.street;
            streetNumber = this.address.streetNumber;
            locality = this.address.locality;
            postalCode = this.address.postalCode;
            latitude = this.address.latitude;
            longitude = this.address.longitude;
        }

        this.editForm = this.fb.group({
            client: [client, Validators.required],
            street: [street, Validators.required],
            streetNumber: [streetNumber, Validators.required],
            locality: [locality, Validators.required],
            postalCode: [postalCode, Validators.required],
            latitude: [latitude],
            longitude: [longitude],
        });
    }
}
