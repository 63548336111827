import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { SharedModule } from 'app/shared/shared.module';

import { TemplatesComponent } from './templates.component';
import { TemplateEditComponent } from './template-edit/template-edit.component';

@NgModule({
    declarations: [
        TemplatesComponent,
        TemplateEditComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        ReactiveFormsModule,
        FormsModule
    ]
})
export class TemplatesModule { }
