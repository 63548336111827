import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'environments/environment';

export class ApiTranslateLoader implements TranslateLoader {
    private baseUrl = environment.apiUrl + 'texts/';

    constructor(
        private http: HttpClient
    ) {}

    getTranslation(lang: string): Observable<any> {
        // if (environment.localTranslations) {
            return this.http.get(`/assets/i18n/fr.json`);
        // }

        // return this.http.get(this.baseUrl + lang)
        // .pipe(catchError(() => this.http.get(`/assets/i18n/fr.json`)));
    }
}
