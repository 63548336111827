import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';

import { environment } from '@environments/environment';
import { AppConfigService } from '@services/app-config.service';
import { AuthService } from '@services/auth.service';
import { NavService } from '@services/nav.service';
import { NavLink } from '@classes/nav-link';
import { AuthUser } from '@classes/auth/auth-user';

@Component({
    selector: 'app-main-nav',
    templateUrl: './main-nav.component.html',
    styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements OnInit, OnChanges {
    @Input() isAuth: boolean;
    @Output() sidenavClose = new EventEmitter<void>();

    navLinks: NavLink[];

    appVersion: string;
    user: AuthUser;
    isMaintenance: boolean;
    isLocalTranslations: boolean;

    constructor(
        private configSrv: AppConfigService,
        private authSrv: AuthService,
        private navSrv: NavService
    ) { }

    ngOnInit() {
        this.appVersion = environment.version;
        this.isMaintenance = this.configSrv.isMaintenance;
        this.isLocalTranslations = environment.localTranslations;
    }

    ngOnChanges(): void {
        if(this.isAuth) {
            this.user = this.authSrv.getUser();
            this.navLinks = this.navSrv.getNavLinks();
        } else {
            this.user = null;
            this.navLinks = null;
        }
    }

    onCloseSidenav(): void {
        this.sidenavClose.emit();
    }
}
