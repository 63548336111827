import { UsersService } from '@services/users.service';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormArray, Validators, FormControl } from '@angular/forms';

import { FormEditBaseComponent } from '@components/form-edit-base.component';
import { ContactsService } from '@services/contacts.service';
import { Step } from '@classes/step/step';
import { Contact } from '@classes/contact';
import { UiService } from '@services/ui.service';
import { Address } from '@classes/address';
import { StepsService } from '@services/steps.service';
import { AddressesService } from '@services/addresses.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Client } from '@classes/client';
import { ClientsService } from '@services/clients.service';

@Component({
    selector: 'app-step-edit',
    templateUrl: './step-edit.component.html',
    styleUrls: ['./step-edit.component.scss']
})
export class StepEditComponent extends FormEditBaseComponent implements OnInit {
    @Input() step: Step;
    contacts: Contact[];
    addresses: Address[];
    clients: Client[];
    tagPattern = "^[a-z0-9]{0,50}$";

    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    // tags: string[] = [];

    isLoadingContacts = false;
    isLoadingAdresses = false;

    constructor(
        protected uiSrv: UiService,
        protected fb: FormBuilder,
        private stepsSrv: StepsService,
        private contactsSrv: ContactsService,
        private addressesSrv: AddressesService,
        private clientsSrv: ClientsService,
        private usersSrv: UsersService

    ) {
        super(uiSrv, fb);
    }

    get tags() {
        return this.editForm.get('tags');
    }

    ngOnInit() {
        super.ngOnInit();
        this.initSub()
        this.initSubClients();
    }

    initSub() {
        this.addSubscription(
            this.contactsSrv.contactsListChanged
                .subscribe(contacts => {
                    this.contacts = contacts;
                    this.isLoadingContacts = false;
                })
        );

        this.addSubscription(
            this.addressesSrv.addressesListChanged
                .subscribe(addresses => {
                    this.addresses = addresses;
                    this.isLoadingAdresses = false;
                })
        );
    }

    onSubmit(): void {
        this.isLoading = true;
        if (this.step) {
            this.update();
        } else {
            this.create();
        }
    }

    onCancel(): void {
        this.editFinished.emit();
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // Add our tag
        if ((value || '').trim()) {
            if (value.length <= 50) {
                this.tags.setValue([... this.tags.value, value.trim()]);
                this.tags.updateValueAndValidity();
            }

        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    remove(tag: string): void {
        const index = this.editForm.value.tags.indexOf(tag);

        if (index >= 0) {
            this.tags.value.splice(index, 1);
            this.tags.updateValueAndValidity();
        }
    }

    private update(): void {
        const stepCopy: Step = JSON.parse(JSON.stringify(this.step));
        stepCopy.name = this.editForm.value.name;
        stepCopy.address = this.editForm.value.address;
        stepCopy.contacts = this.contactsSrv.setDefault(this.editForm.value.contacts);
        stepCopy.isDeposit = this.editForm.value.isDeposit;
        // stepCopy.clients = this.editForm.value.clients;
        stepCopy.client = this.editForm.value.client;
        stepCopy.tags = this.editForm.value.tags.map(tag => {
            return tag = tag.replaceAll(":", "");
        });
        stepCopy.timeOnSite = this.editForm.value.timeOnSite;
        stepCopy.comment = this.editForm.value.comment;


        this.stepsSrv.put(stepCopy).subscribe(() => this.isLoading = false);
    }

    private create(): void {
        this.step = new Step(
            this.editForm.value.name,
            this.editForm.value.address,
            this.contactsSrv.setDefault(this.editForm.value.contacts),
            this.editForm.value.isDeposit,
            this.editForm.value.client,
            this.editForm.value.tags.map(tag => {
                return tag = tag.replaceAll(":", "");
            }),
            this.editForm.value.timeOnSite,
            this.editForm.value.comment
        );

        this.stepsSrv.post(this.step)
            .subscribe(
                () => this.isLoading = false,
                () => this.step = null
            );
    }

    private initSubClients() {
        const clientsPromise = new Promise((resolve, reject) => {
            this.clientsSrv.getList()
                .subscribe(clientsFromSrv => {
                    this.clients = clientsFromSrv;
                    resolve(clientsFromSrv);
                });
        });

        Promise.all([clientsPromise])
            .then(() => {
                this.afterLoading();
            });
    }

    private initSubContactsAdresses(): void {
        this.isLoadingContacts = true;
        this.isLoadingAdresses = true;

        const contactsPromise = new Promise((resolve, reject) => {
            this.contactsSrv.getList()
                .subscribe(contactsFromSrv => {
                    this.contacts = contactsFromSrv;
                    resolve(contactsFromSrv);
                });
        });

        const addressesPromise = new Promise((resolve, reject) => {
            this.addressesSrv.getList()
                .subscribe(addressesFromSrv => {
                    this.addresses = addressesFromSrv;
                    resolve(addressesFromSrv);
                });
        });

        Promise.all([contactsPromise, addressesPromise])
            .then(() => {
                let contactsControls = [this.fb.control(null, Validators.required), this.fb.control(null)];
                let address = null;

                if (this.step) {
                    address = this.step.address || null;
                    if (this.step.contacts.length) {
                        contactsControls = [];
                        this.step.contacts.map(oneContact => {
                            contactsControls.push(this.fb.control(oneContact));
                        });
                        contactsControls = contactsControls.map((control, i) => {
                            if (i === 0) {
                                return this.fb.control(this.step.contacts[i] || null, Validators.required);
                            } else {
                                return this.fb.control(this.step.contacts[i] || null);
                            }

                        });
                    }
                }

                this.editForm.addControl('contacts', this.fb.array(contactsControls))
                this.editForm.addControl('address', new FormControl(address, Validators.required))


            });
    }

    private initSubContactsAdressesByClientId(clientId): void {
        this.isLoadingContacts = true;
        this.isLoadingAdresses = true;

        const contactsPromise = new Promise((resolve, reject) => {
            this.contactsSrv.getListByClientId(clientId)
                .subscribe(contactsFromSrv => {
                    this.contacts = contactsFromSrv;
                    resolve(contactsFromSrv);
                });
        });

        const addressesPromise = new Promise((resolve, reject) => {
            this.addressesSrv.getListByClientId(clientId)
                .subscribe(addressesFromSrv => {
                    this.addresses = addressesFromSrv;
                    resolve(addressesFromSrv);
                });
        });

        Promise.all([contactsPromise, addressesPromise])
            .then(() => {
                let contactsControls = [this.fb.control(null), this.fb.control(null)];
                let address = null;

                if (this.step) {
                    address = this.step.address || null;
                    if (this.step.contacts.length) {
                        contactsControls = [];
                        this.step.contacts.map(oneContact => {
                            contactsControls.push(this.fb.control(oneContact));
                        });
                        contactsControls = contactsControls.map((control, i) => {
                            return this.fb.control(this.step.contacts[i] || null);
                        });
                    }
                }

                this.editForm.addControl('contacts', this.fb.array(contactsControls))
                this.editForm.addControl('address', new FormControl(address, Validators.required))
            });
    }

    private afterLoading(): void {
        // if(this.contacts && this.addresses) {
        this.initForm();
        this.isLoading = false;
        // }
    }

    private initForm(): void {
        let name = '';
        let address = null;
        let contactsControls = [this.fb.control(null), this.fb.control(null)];
        let tags = [];
        let timeOnSite = null;
        let comment = '';
        let isDeposit = false;
        // let clients: Client[] = [];
        let client = null;

        if (this.step) {
            name = this.step.name;
            address = this.step.address || null;
            tags = this.step.tags;
            timeOnSite = this.step.timeOnSite;
            comment = this.step.comment;
            isDeposit = this.step.isDeposit;
            // clients = this.step.clients;
            client = this.step.client;

            // if (this.step.contacts.length) {
            //     contactsControls = [];
            //     this.step.contacts.map(oneContact => {
            //         contactsControls.push(this.fb.control(oneContact));
            //     });
            //     contactsControls = contactsControls.map((control, i) => {
            //         return this.fb.control(this.step.contacts[i] || null);
            //     });
            // }
        }


        this.editForm = this.fb.group({
            name: [name, Validators.required],
            // contacts: this.fb.array(contactsControls),
            // address: [address, Validators.required],
            // clients: this.fb.array([]),
            client: [client, Validators.required],
            isDeposit: [isDeposit],
            // tags: [tags, Validators.pattern(this.tagPattern)],
            tags: new FormControl(tags),
            timeOnSite: [timeOnSite],
            comment: [comment]
        });

        if (this.usersSrv.clientSelected === 'all') {
            if (this.step) {
                this.initSubContactsAdressesByClientId(this.step.client.id);
            }
            this.editForm.get('client').valueChanges.subscribe(client => {
                if (typeof client !== 'string') {
                    if (this.isControlSet('contacts')) {
                        this.editForm.removeControl('contacts');
                    }
                    if (this.isControlSet('address')) {
                        this.editForm.removeControl('address');
                    }
                    this.initSubContactsAdressesByClientId(client.id);
                }
            })
        } else {
            this.initSubContactsAdresses();
        }


        // if(clients && clients.length) {
        //     clients.map(c => this.onAddClient(c));
        // } else {
        //     this.onAddClient();
        // }

    }

    onAddContact(contact?: Contact): void {
        this.contactsFields.push(
            this.fb.control(null)
        );
    }

    removeContact(contactToRemove) {
        this.getArray('contacts').controls.splice(this.getArray('contacts').controls.indexOf(contactToRemove), 1);
        this.editForm.value.contacts.splice(this.editForm.value.contacts.indexOf(contactToRemove.value), 1);
    }

    get contactsFields() {
        return this.editForm.get('contacts') as FormArray;
    }

    onAddClient(client?: Client): void {
        const control = this.fb.control(client || null, [Validators.required]);
        this.getArray('clients').push(control);
    }

    onRemoveClient(index: number): void {
        this.getArray('clients').removeAt(index);
    }

    isControlSet(control: string): boolean {
        return !!this.editForm.get(control);
    }

    checkSmsNotif(event, contactControl) {
        contactControl.value.smsNotification = event.checked;
    }
}
