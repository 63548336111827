import { Client } from '@classes/client';
import { BaseClass } from './base-class';
import { BaseRepository } from '@interfaces/base-repository';

export class Sample extends BaseClass implements BaseRepository {
    name: string;
    client: Client;
    sortOrder: number;
    lowThreshold: number;
    highThreshold: number;
    quantity: number;
    temperature: number;
    hasAttachment?: boolean;

    constructor()
    constructor(name: string, client: Client, low: number, high: number, sortOrder: number)
    constructor(name?: string, client?: Client, low?: number, high?: number, sortOrder?: number) {
        super();
        this.name = this.trim(name);
        this.client = client;
        this.lowThreshold = low != null ? low : null;
        this.highThreshold = high != null ? high : null;
        this.sortOrder = sortOrder != null ? sortOrder : null;
        this.quantity = 0;
        this.temperature = null;
    }

    static fromObject(object: Sample): Sample {
        return Object.assign(new Sample(), {
            ... object,
            client: object.client ? Client.fromObject(object.client) : null,
        });
    }

    get display(): string {
        return `${this.name}`;
    }

    doesInclude(value: string): boolean {
        return this.some([this.name], value);
    }
}
