<mat-card>
    <mat-card-header>
        <mat-card-title>
            <!-- <h1>{{ 'missions.calendar.title' | translate }}</h1> -->
            <h1>{{ viewDate | amDateFormat: 'MMMM YYYY' }}</h1>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <header class="calendar-header">
            <div class="progress">
                <mat-progress-bar *ngIf="isLoading" class="progress-bar" mode="indeterminate" color="accent">
                </mat-progress-bar>
            </div>
            <ul class="actions-list">
                <li mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="getMissions()">
                    <mat-icon aria-label="Previous">navigate_before</mat-icon>
                </li>
                <li mwlCalendarToday [(viewDate)]="viewDate" (viewDateChange)="getMissions()">
                    <mat-icon aria-label="Today">today</mat-icon>
                </li>
                <li mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="getMissions()">
                    <mat-icon aria-label="Next">navigate_next</mat-icon>
                </li>
            </ul>
        </header>


        <ng-template #customCellTemplate let-day="day" let-openDay="openDay" let-locale="locale"
            let-tooltipPlacement="tooltipPlacement" let-highlightDay="highlightDay" let-unhighlightDay="unhighlightDay"
            let-eventClicked="eventClicked" let-tooltipTemplate="tooltipTemplate"
            let-tooltipAppendToBody="tooltipAppendToBody">
            <div class="cal-cell-top">
                <!-- <span style="background-color: red" class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{day.badgeTotal}}</span> -->
                    <span
                      *ngFor="let group of day.eventGroups"
                      class="badge badge-{{ group[0] }}">
                      {{ group[1].length }}
                    </span>

                <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
            </div>

            <div class="cal-events">
                <div [ngClass]="(event.id != 'alert')?'cal-event':'eventAlert'" *ngFor="let event of day.events | orderBy: 'start'; trackBy:trackByEventId"
                    (mouseenter)="highlightDay.emit({event: event})"
                    (mouseleave)="unhighlightDay.emit({event: event})"
                    [matTooltip]="event.title"
                    (mwlClick)="eventClicked.emit({ event: event })">
                    <span class="event-hour">{{ event.start | amDateFormat: 'HH:mm' }}</span> {{ event.title | calendarEventTitle:'monthTooltip':event }}
                </div>
            </div>


        </ng-template>

        <mwl-calendar-month-view
            [locale]="locale"
            [viewDate]="viewDate"
            [events]="calendarEvents"
            [activeDayIsOpen]="activeDayIsOpen"
            [cellTemplate]="customCellTemplate"
            (dayClicked)="dayClicked($event.day)"
            (eventClicked)="eventClicked($event.event)"
            (beforeViewRender)="beforeMonthViewRender($event)">
        </mwl-calendar-month-view>
    </mat-card-content>
</mat-card>
