import { UsersService } from './../../shared/services/users.service';
import { ClientsService } from './../../shared/services/clients.service';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';
import { AuthUser } from '@classes/auth/auth-user';
import { Client } from '@classes/client';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RoleName } from '@enums/role-name.enum';

@Component({
    selector: 'app-main-header',
    templateUrl: './main-header.component.html',
    styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit, OnChanges {
    @Input() isAuth: boolean;
    @Output() sidenavToggle = new EventEmitter<void>();
    appName: string = environment.appName;
    appVersion: string = environment.version;
    user: AuthUser;
    actualUser: AuthUser;
    clients: Client[] = [];

    isAdmin = false;

    clientForm: FormGroup;
    clientSelectedId: string;

    constructor(
        private authSrv: AuthService,
        private clientsSrv: ClientsService,
        private fb: FormBuilder,
        private usersSrv: UsersService
    ) { }

    ngOnInit() {

    }

    initSub() {
        this.clientsSrv.getAllClients().subscribe(clients => {
            this.clients = clients;
            this.initForm();
        })
    }

    private initForm(): void {

        let clientSelected: any = 'all';
        this.clients.map(client => {
            if (client.id === this.clientSelectedId) {
                clientSelected = client;
            }
        })

        this.clientForm = this.fb.group({
            client: [clientSelected]
        });

        this.clientForm.get('client').valueChanges.subscribe(client => {
            if (client === 'all') {
                this.usersSrv.chooseAllClient().subscribe(() => {
                    this.usersSrv.clientSelected = 'all';
                });
            } else {
                this.usersSrv.chooseClient(client).subscribe(() => {
                    this.usersSrv.clientSelected = client.id;
                });
            }

        })
    }

    ngOnChanges(): void {
        if (this.isAuth) {
            this.user = this.authSrv.getUser();
            this.isAdmin = this.user.role.name === RoleName.admin;
            this.usersSrv.getCurrentUser().subscribe(user => {
                if (user.client) {
                    this.clientSelectedId = user.client.id;
                    this.usersSrv.clientSelected = user.client.id;
                } else {
                    this.clientSelectedId = 'all';
                    this.usersSrv.clientSelected = 'all';
                }
            })
            this.initSub();
        } else {
            this.user = null;
        }
    }

    onToggleSidenav(): void {
        this.sidenavToggle.emit();
    }
}
