import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { Mission } from '@classes/mission/mission';
import { MissionStatus } from '@enums/mission-status.enum';
import { MissionsService } from '@services/missions.service';
import { UiService } from '@services/ui.service';
import { Subscription } from 'rxjs';
import { ReportService } from '@services/report.service';
import { Step } from '@classes/step/step';
import { Router } from '@angular/router';
import { UsersService } from '@services/users.service';
import { RoleName } from '@enums/role-name.enum';
import { User } from '@classes/user/user';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@services/auth.service';

@Component({
    selector: 'app-detail-summary',
    templateUrl: './detail-summary.component.html',
    styleUrls: ['./detail-summary.component.scss']
})
export class DetailSummaryComponent implements OnInit, OnDestroy {
    private subscription = new Subscription();

    @Input() mission: Mission;
    @Input() isReadOnly = true;
    @Input() doesShowRoute = false;

    missionStatus = MissionStatus;

    isRestricted: boolean;
    isAdminClient = false;
    drivers: User[];
    isLoading: boolean;
    isLoadingDrivers = false;
    showDrivers = false;
    isUserLabo: boolean;

    driverForm = new FormGroup({
        driver: new FormControl('', Validators.required)
    });

    constructor(
        private missionsSrv: MissionsService,
        private reportSrv: ReportService,
        private uiSrv: UiService,
        private router: Router,
        private usersSrv: UsersService,
        private authSrv: AuthService
    ) { }

    ngOnInit() {
        this.initSubscriptions();
        this.isAdminClient = this.authSrv.getUser().role.name === RoleName.admin_labo;
        this.isRestricted = this.authSrv.getUser().role.name === RoleName.labo;
        this.isUserLabo = this.authSrv.getUser().role.name === RoleName.labo;
    }

    showStatusText(status: MissionStatus): string {
        return 'missions.status.' + MissionStatus[status];
    }

    get completedStepsNumber(): number {
        return this.mission.steps.filter(s => s.finishedOn != null).length;
    }

    get skippedStepsNumber(): number {
        return this.mission.steps.filter(s => s.ignoredOn != null).length;
    }

    get pickupStepsNumber(): number {
        return this.mission.steps.filter(s => s.pickupedOn != null).length;
    }

    get deliveryStepsNumber(): number {
        return this.mission.steps.filter(s => s.deliveredOn != null).length;
    }

    get completion(): number {
        return this.missionsSrv.calcCompletion(this.mission.steps);
    }

    get timeLeft(): number {
        return this.mission.steps.reduce((total: number, step: Step) => {
            if (step.finishedOn == null && step.ignoredOn == null) {
                return total += step.estimatedTime + step.timeOnSite;
            }

            return total;
        }, 0);
    }

    get timeLeftToNextLab(): number {
        let hasFoundDeposit = false;
        return this.mission.steps.reduce((total: number, step: Step, index: number) => {

            // si la dernière étape (vérifier si bien labo) alors 0 en temps restant jusqu'au prochain labo
            if (index + 1 === this.mission.steps.length && step.isDeposit) {
                return total += 0;
            }

            // si finie/ignorée alors on ignore le temps
            if (step.finishedOn != null || step.ignoredOn != null) {
                return total;
            } else {
                // si l'étape est un labo et que le chauffeur a commencé dessus, on compte son temps
                if (step.isDeposit && step.arrivedOn != null) {
                    return total += step.estimatedTime + step.timeOnSite;
                } else
                    // si l'étape est un labo alors on arrête de compter
                    if (step.isDeposit && step.arrivedOn == null) {
                        hasFoundDeposit = true;
                    }

                // si labo et pas fini/arrivé alors on arrete là et on renvoie le total jusque là
                if (hasFoundDeposit) {
                    return total;
                } else {
                    // si non si mission non finie et non ignorée alors on rajoute les temps
                    if (step.finishedOn == null && step.ignoredOn == null) {
                        return total += step.estimatedTime + step.timeOnSite;
                    }

                    return total;
                }
            }


        }, 0);
    }

    get lastCompletedStep(): string {
        return this.mission.steps.filter(s => s.finishedOn != null).sort(this.compareValues('finishedOn', 'desc'))[0].name;
    }

    get numberSteps(): number {
        return this.mission.steps.filter(s => !s.onCall || (s.onCall && s.onCallActivated)).length;
    }

    getTotalSamples(mission): number {
        return mission.steps.reduce((total: number, step: Step) => {
            if (step.samples) {
                let subTotal = 0;
                step.samples.map(sample => {
                    subTotal += sample.quantity;
                })
                return total += subTotal;
            }

            return total;
        }, 0);
    }

    onPause(): void {
        this.isLoading = true;
        this.missionsSrv.pause(this.mission.id)
            .subscribe(() => {
                this.isLoading = false;
                this.uiSrv.showSnackbar('missions.paused', true);
            });
    }

    onRestart(): void {
        this.isLoading = true;
        this.missionsSrv.restart(this.mission.id)
            .subscribe(() => {
                this.isLoading = false;
                this.uiSrv.showSnackbar('missions.restarted', true);
            });
    }

    onCancel(): void {
        this.isLoading = true;
        this.missionsSrv.cancelForEver(this.mission.id)
            .subscribe(() => {
                this.isLoading = false;
                this.uiSrv.showSnackbar('missions.cancel', true);
            });
    }

    onSelectMission(id: string): void {
        this.router.navigate([`/missions/${id}`]);
    }

    onShowReport(): void {
        this.isLoading = true;
        this.reportSrv.get(this.mission.client.id, 'client').subscribe(() => this.isLoading = false);
    }

    private initSubscriptions(): void {
        this.subscription.add(
            this.uiSrv.loadingStateChanged
                .subscribe(loadingState => {
                    this.isLoading = loadingState;
                })
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    openDrivers(): void {
        this.showDrivers = true;
        this.isLoadingDrivers = true;
        const driversPromise = new Promise((resolve, reject) => {
            this.usersSrv.getList(RoleName.driver, true)
                .subscribe(usersFromSrv => {
                    if (this.mission.user) {
                        this.drivers = usersFromSrv.filter(oneDriver => {
                            return oneDriver.id !== this.mission.user.id;
                        });
                    } else {
                        this.drivers = usersFromSrv;
                    }
                    resolve(true);
                });
        });

        driversPromise.then(() => {
            this.isLoadingDrivers = false;
        });
    }

    confirmDriverChange(): void {
        this.isLoading = true;
        this.mission.user = this.driverForm.value.driver;
        this.missionsSrv.put(this.mission)
            .subscribe(() => {
                this.isLoading = false;
                this.uiSrv.showSnackbar('missions.edit.reassigned', true);
                this.showDrivers = false;
            });
    }

    compareValues(key, order = 'asc') {
        return function innerSort(a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                // property doesn't exist on either object
                return 0;
            }

            const varA = (typeof a[key] === 'string')
                ? a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string')
                ? b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === 'desc') ? (comparison * -1) : comparison
            );
        };
    }

    open() {

    }


}
