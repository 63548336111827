import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { UiService } from "@services/ui.service";
import { Mission } from '@classes/mission/mission';
import { MissionsService } from '@services/missions.service';


@Injectable()
export class MissionResolver implements Resolve<Mission> {
    constructor(
        private missionsSrv: MissionsService,
        private uiSrv: UiService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Mission> {
        const id = route.paramMap.get('id');

        this.uiSrv.routeLoadingStateChanged.next(true);

        return this.missionsSrv.get(id)
        .pipe(
            tap(() => {
                this.uiSrv.routeLoadingStateChanged.next(false);
            })
        );
    }
}
