<mat-card>
    <mat-card-header>
        <mat-card-title>
            <h1>{{ 'templates.title' | translate }}</h1>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <section class="creation-form">
            <button *ngIf="!isCreationMode" (click)="onEdit()"
                [matTooltip]="'templates.list.add' | translate"
                mat-mini-fab class="action-button" color="accent">
                <mat-icon aria-label="Add">add</mat-icon>
            </button>
            <app-template-edit *ngIf="isCreationMode && !editedId" (editFinished)="onEditFinished()"></app-template-edit>
        </section>

        <mat-progress-bar *ngIf="isLoading"  class="progress-bar" mode="indeterminate" color="accent"></mat-progress-bar>
        <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'templates.list.name' | translate }} </th>
                <td mat-cell *matCellDef="let template"> {{ template.name }} </td>
            </ng-container>
            <ng-container matColumnDef="client">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'templates.list.client' | translate }} </th>
                <td mat-cell *matCellDef="let template"> {{ template.client?.name }} </td>
            </ng-container>
            <ng-container matColumnDef="steps">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'templates.list.steps' | translate }} </th>
                <td mat-cell *matCellDef="let template"> {{ template.steps.length }} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> {{ 'templates.list.actions' | translate }} </th>
                <td mat-cell *matCellDef="let template">
                    <ng-container *ngIf="template.id !== editedId">
                        <button mat-icon-button class="action-button" color="accent"
                            [disabled]="isLoading"
                            [matTooltip]="'templates.list.edit' | translate"
                            (click)="onEdit(template.id)">
                            <mat-icon aria-label="Edit">edit</mat-icon>
                        </button>
                    </ng-container>
                    <ng-container>
                        <button mat-icon-button class="action-button" color="accent"
                            [disabled]="isLoading"
                            [matTooltip]="'templates.list.delete' | translate"
                            (click)="delete(template.id)">
                            <mat-icon aria-label="Delete">clear</mat-icon>
                        </button>
                    </ng-container>
                </td>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let template" [attr.colspan]="displayedColumns.length">
                    <div *ngIf="template.id === editedId" class="row-edit"
                        [@detailExpand]="template.id === editedId ? 'expanded' : 'collapsed'">
                        <app-template-edit [template]="template" (editFinished)="onEditFinished()"></app-template-edit>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row"
                [ngClass]="{ 'expanded-row': row.id === editedId }"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
        </table>
    </mat-card-content>
</mat-card>
