export const environment = {
    name: 'prod',

    production: true,
    localTranslations: false,

    apiDomain: 'jdwtrans.be',
    apiUrl: 'https://jdwtrans.be/api/',
    identityApiUrl: 'https://jdwtrans.be/identity/',

    apiMworkerDomain: 'app.m-worker.com',
    apiCoreMworkerUrl: 'https://app.m-worker.com/api/core/',
    apiCoreDataMworkerUrl: 'https://app.m-worker.com/api/data/',
    identityApiMworkerUrl: 'https://app.m-worker.com/api/auth/',

    tokenKey: 'token',
    tokenExpKey: 'token-exp',
    rfTokenKey: 'rf-token',
    tokenMworkerKey: 'tokenMworker',
    tokenMworkerExpKey: 'tokenMworker-exp',
    rfTokenMworkerKey: 'rf-tokenMworker',

    clientSecretMworker: 'pV7pvskC/aDp1jtFXxzRyJa38LHq90g8qLGbO8tRB7s=',
    clientIdMworker: 'jdw_webapp',
    scopeMworker: 'mworker_api',
    grant_type: 'client_credentials',

    appName: "JDW Transport",

    version: require('../../package.json').version
};
