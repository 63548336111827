import { UsersService } from './../../shared/services/users.service';
import { Component, OnInit } from '@angular/core';
import { trigger, state, style } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';

import { DatatableBaseComponent } from '@components/datatable-base.component';
import { UiService } from '@services/ui.service';
import { ClientsService } from '@services/clients.service';
import { Client } from '@classes/client';

@Component({
    selector: 'app-clients',
    templateUrl: './clients.component.html',
    styleUrls: ['./clients.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' }))
        ])
    ]
})
export class ClientsComponent extends DatatableBaseComponent implements OnInit {
    clients: Client[];

    displayedColumns = ['name', 'address', 'contacts', 'step', 'textSms', 'actions'];
    dataSource: MatTableDataSource<Client>;

    constructor(
        protected uiSrv: UiService,
        private route: ActivatedRoute,
        private clientsSrv: ClientsService,
        private usersSrv: UsersService
    ) {
        super(uiSrv);
    }

    ngOnInit() {
        super.ngOnInit();
        this.getRouteData();
        this.initSubscriptions();
    }

    private initTable(): void {
        this.dataSource = new MatTableDataSource(this.clients);
        this.dataSource.sortingDataAccessor = (item: any, property) => {
            switch (property) {
                case 'step': return item.step?.name;
                default: return item[property];
            }
        };
        this.dataSource.sort = this.sort;
    }

    private getRouteData(): void {
        this.route.data
            .subscribe((data: { clients: Client[] }) => {
                this.clients = data.clients;
                this.initTable();
            });
    }

    private initSubscriptions(): void {
        this.addSubscription(
            this.clientsSrv.clientsListChanged
                .subscribe(clients => {
                    this.dataSource.data = clients || [];
                    this.dataSource.sort = this.sort;
                    this.isLoading = false;
                    this.onEditFinished();
                })
        );

        this.addSubscription(
            this.usersSrv.clientChangedSubject.subscribe(() => {
                this.isLoading = true;
                this.addSubscription(
                    this.clientsSrv.getList().subscribe()
                )
            })
        )
    }
}
