import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { Mission } from '@classes/mission/mission';
import { UiService } from '@services/ui.service';
import { MissionsService } from '@services/missions.service';
import { AuthService } from '@services/auth.service';
import { AuthUser } from '@classes/auth/auth-user';
import { ReportService } from '@services/report.service';

@Component({
    selector: 'app-mission-detail',
    templateUrl: './mission-detail.component.html',
    styleUrls: ['./mission-detail.component.scss']
})
export class MissionDetailComponent implements OnInit, OnDestroy {
    @ViewChild('reportBlock') reportBlock: ElementRef;

    mission: Mission;
    isEditMode = false;

    isLoading: boolean;
    user: AuthUser;
    missions: Mission[];

    private subscription = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private uiSrv: UiService,
        private missionsSrv: MissionsService,
        private authSrv: AuthService,
        private reportSrv: ReportService,
        private cdr: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        this.getRouteData();
        this.iniSubscriptions();
        this.user = this.authSrv.getUser();
    }

    onEdit(): void {
        this.missionsSrv.get(this.mission.id).subscribe(missionFromSrv => {
            this.mission = missionFromSrv;
            this.isEditMode = true;
        });
    }

    onEditFinished(): void {
        this.isEditMode = false;
    }

    private getRouteData(): void {
        this.route.data
        .subscribe((data: { mission: Mission }) => {
            this.mission = data.mission;
        });
    }

    private iniSubscriptions(): void {
        this.isLoading = true;
        this.subscription.add(
            this.uiSrv.loadingStateChanged
            .subscribe(loadingState => {
                this.isLoading = loadingState;
            })
        );
        this.subscription.add(
            this.missionsSrv.selectedMissionChanged
            .subscribe(selectedMission => {
                this.mission = selectedMission;
                this.cdr.detectChanges();
                this.onEditFinished();
            })
        );

        this.subscription.add(
            this.reportSrv.selectedReportSubject
            .subscribe(selectedReport => {
                if(selectedReport) {
                    this.reportBlock.nativeElement.scrollIntoView();
                }
            })
        );

        const missionsPromise = new Promise((resolve, reject) => {
            this.missionsSrv.getListForCopyStep()
                .subscribe(missionsFromSrv => {
                    this.missions = missionsFromSrv.filter(m => m.id !== this.mission.id);
                    resolve(true);
                });
        });

        Promise.all([missionsPromise])
            .then(() => {
                this.isLoading = false;
            });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
