<form class="edit-form" *ngIf="!isLoading && clients" [formGroup]="editForm" (submit)="onSubmit()">
    <mat-form-field>
        <mat-label>{{ 'templates.edit.name.label' | translate }}</mat-label>
        <input type="text" matInput formControlName="name">
        <mat-error>{{ 'templates.edit.name.errorMessage' | translate }}</mat-error>
    </mat-form-field>

    <section class="form-field">
        <app-autocomplete [control]="getControl('client')" [list]="clients"
            label="templates.edit.client.label"
            errorMsg="templates.edit.client.errorMessage"></app-autocomplete>
    </section>

    <section class="steps-form" *ngIf="availableSteps && isControlSet('steps')">
        <app-steps-form [steps]="template?.steps" [availableSteps]="availableSteps"
            (stepsChanged)="onStepsChanged($event)"></app-steps-form>
    </section>

    <mat-form-field class="comment-field">
        <mat-label>{{ 'templates.edit.comment.label' | translate }}</mat-label>
        <textarea matInput rows="6" formControlName="comment"></textarea>
    </mat-form-field>

    <section class="actions">
        <button *ngIf="!isLoading" type="submit" mat-icon-button color="accent"
            [disabled]="editForm.invalid || !formSteps || !formSteps.length">
            <mat-icon aria-label="Validate">check</mat-icon>
        </button>
        <button *ngIf="!isLoading" type="button" mat-icon-button (click)="onCancel()">
            <mat-icon aria-label="Cancel">clear</mat-icon>
        </button>
    </section>
</form>
<mat-spinner *ngIf="isLoading" [diameter]="35" color="accent"></mat-spinner>
