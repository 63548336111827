import { UsersService } from '@services/users.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { Mission } from '@classes/mission/mission';
import { Sample } from '@classes/sample';
import { Dashboard } from '@classes/dashboard';
import { DashboardService } from '@services/dashboard.service';
import { NotificationsService } from '@services/notifications.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
    private subscription = new Subscription();

    missions: Mission[];
    samples: Sample[];

    isLoading = false;

    constructor(
        private route: ActivatedRoute,
        private dashSrv: DashboardService,
        private notifSrv: NotificationsService,
        private usersSrv: UsersService
    ) { }

    ngOnInit() {
        this.getRouteData();
        this.initSubscriptions();
    }

    private getRouteData(): void {
        this.route.data
        .subscribe((data: { dashboard: Dashboard}) => {
            this.missions = data.dashboard.missions;
            this.samples = data.dashboard.samples;
        });
    }

    private initSubscriptions(): void {
        this.subscription.add(
            this.dashSrv.missionsChanged
            .subscribe(missionsFromSrv => this.missions = missionsFromSrv)
        );
        this.subscription.add(
            this.dashSrv.samplesChanged
            .subscribe(samplesFromSrv => this.samples = samplesFromSrv)
        );
        this.subscription.add(
            this.notifSrv.notificationsSubject
            .subscribe(() => this.dashSrv.get().subscribe())
        );
        this.subscription.add(
            this.usersSrv.clientChangedSubject
            .subscribe(() => {
                this.isLoading = true;
                this.dashSrv.get().subscribe(() => {
                    this.isLoading = false;
                })
            })
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
