import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { UiService } from "@services/ui.service";
import { TemperaturesService } from '@services/temperatures.service';

// créer le model Alert

@Injectable()
export class AlertDetailResolver implements Resolve<any> {
    constructor(
        private temperaturesSrv: TemperaturesService,
        private uiSrv: UiService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        const id = route.paramMap.get('id');
        this.uiSrv.routeLoadingStateChanged.next(true);

        return this.temperaturesSrv.getDetailsAlertById(id)
        .pipe(
            tap(() => {
                this.uiSrv.routeLoadingStateChanged.next(false);
            })
        );
    }
}
