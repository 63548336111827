<mat-card>
    <mat-card-header>
        <mat-card-title>
            <h1 *ngIf="mission">{{ 'missions.edit.title' | translate }}</h1>
            <h1 *ngIf="!mission">{{ 'missions.create.title' | translate }}</h1>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-progress-bar *ngIf="isLoading" class="progress-bar" mode="indeterminate" color="primary">
        </mat-progress-bar>
        <section class="choice-field" *ngIf="!isRestricted && !mission">
            <mat-radio-group [(ngModel)]="model" (ngModelChange)="selectModel()">
                <mat-radio-button value="oneStep">envoi avec retour client</mat-radio-button>
                <mat-radio-button value="template">avec un template</mat-radio-button>
                <mat-radio-button value="steps">avec étape(s)</mat-radio-button>
            </mat-radio-group>
        </section>
        <form class="edit-form" *ngIf="(mission || templates) && editForm && !isLoading" [formGroup]="editForm"
            (submit)="onSubmit()">

            <section [className]="mission || model === 'steps' || isRestricted ? 'name-field-mission' : 'name-field'">
                <mat-form-field>
                    <mat-label>{{ 'missions.create.name.label' | translate }}</mat-label>
                    <input type="text" matInput formControlName="name">
                    <mat-error>{{ 'missions.create.name.errorMessage' | translate: { maxLength: nameMaxLength } }}
                    </mat-error>
                </mat-form-field>
            </section>


            <section class="form-field template-field" *ngIf="isControlSet('template') && !mission">
                <app-autocomplete [control]="getControl('template')" [list]="templates"
                    label="missions.create.template.label" errorMsg="missions.create.template.errorMessage">
                </app-autocomplete>
            </section>

            <section id="stepField" class="form-field step-field"
                [className]="model === 'oneStep' && isControlSet('step') && !mission && !isAdminClient ? 'form-field step-field' : 'form-field step-isAdminClient'"
                *ngIf="model === 'oneStep' && isControlSet('step') && !mission"
                [ngClass]="{'oneStep-field': isControlSet('step') && !isRestricted}">
                <app-autocomplete [control]="getControl('step')" [list]="steps" label="missions.create.step.label"
                    errorMsg="missions.create.step.errorMessage"></app-autocomplete>
            </section>

            <section id="stepField" class="form-field step-field" *ngIf="isControlSet('steps') && !mission"
                [ngClass]="{'step2-field': isControlSet('steps') && !isRestricted}">
                <fieldset class="clients-list">
                    <legend>
                        <h3>Étape(s)</h3>
                    </legend>
                    <mat-list formArrayName="steps">
                        <mat-list-item *ngFor="let stepControl of getArray('steps').controls; index as i">

                            <section class="form-field">
                                <app-autocomplete [control]="stepControl" [list]="steps"
                                    [label]="'missions.create.step.label'" [number]="i"
                                    [lastStep]="i+1 === getArray('steps').controls.length"
                                    label="missions.create.step.label" errorMsg="missions.create.step.errorMessage">
                                </app-autocomplete>
                            </section>

                            <button type="button" mat-icon-button color="accent"
                                [disabled]="getArray('steps').controls.length <= 1" (click)="onRemoveStep(i)">
                                <mat-icon aria-label="delete">delete</mat-icon>
                            </button>

                            <mat-icon matTooltip="Cette étape est la destination finale."
                                *ngIf="i+1 === getArray('steps').controls.length" class="lastStepIcon"
                                aria-label="lastStep">outlined_flag</mat-icon>

                        </mat-list-item>
                        <mat-list-item>
                            <button type="button" mat-icon-button color="accent" (click)="onAddStep()">
                                <mat-icon aria-label="Add">add</mat-icon>
                            </button>
                        </mat-list-item>
                    </mat-list>
                </fieldset>
                <!-- <app-autocomplete [control]="getControl('step')" [list]="steps" label="missions.create.step.label"
                    errorMsg="missions.create.step.errorMessage"></app-autocomplete> -->
            </section>

            <section *ngIf="!isAdminClient" [className]="model === 'oneStep' ? 'oneStep-client-field' : 'client-field'">
                <app-autocomplete *ngIf="!isRestricted && (isControlSet('steps') || isControlSet('step')) || mission"
                    [control]="getControl('client')" [list]="clients" [inOneStep]="isControlSet('step')"
                    label="missions.create.client.label"
                    errorMsg="missions.create.client.errorMessage"></app-autocomplete>
            </section>

            <fieldset class="schedules-list" *ngIf="isControlSet('schedules') || isControlSet('scheduleRegularly')">
                <legend class="legendSchedule">
                    <h3>{{ 'missions.create.schedules' | translate }}</h3>
                    <mat-radio-group formControlName="modelSchedule" *ngIf="!mission">
                        <mat-radio-button value="classic">classique</mat-radio-button>
                        <mat-radio-button value="regularly">Régulière</mat-radio-button>
                    </mat-radio-group>
                </legend>
                <mat-list formArrayName="schedules"
                    *ngIf="this.editForm.value.modelSchedule === 'classic'; else isRegularly">
                    <mat-list-item *ngFor="let control of getArray('schedules').controls; index as i"
                        [formGroupName]="i">
                        <div matLine>
                            <mat-form-field class="start-date-field">
                                <mat-label>{{ 'missions.create.startDate.label' | translate }}</mat-label>
                                <input matInput formControlName="startDate" [matDatepicker]="startDatePicker"
                                    [errorStateMatcher]="confirmValidMatcher" (click)="startDatePicker.open()"
                                    (focus)="startDatePicker.open()">
                                <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #startDatePicker></mat-datepicker>
                            </mat-form-field>

                            <mat-form-field class="start-time-field">
                                <mat-label>{{ 'missions.create.startTime.label' | translate }}</mat-label>
                                <input id="inputTime" type="time" matInput formControlName="startTime"
                                    [errorStateMatcher]="confirmValidMatcher">
                            </mat-form-field>

                            <section class="form-field" *ngIf="control.get('driver')">
                                <app-autocomplete [control]="control.get('driver')" [list]="drivers"
                                    label="missions.create.driver.label" errorMsg="missions.create.driver.errorMessage">
                                </app-autocomplete>
                            </section>

                            <button type="button" mat-icon-button color="accent" *ngIf="!mission && !isRestricted"
                                (click)="onRemoveSchedule(i)">
                                <mat-icon aria-label="delete">delete</mat-icon>
                            </button>
                        </div>
                        <div matLine *ngIf="control.hasError('mismatch')" class="schedule-error">
                            {{ 'missions.create.startDate.errorMessage' | translate }}
                        </div>
                    </mat-list-item>
                    <mat-list-item *ngIf="!mission && !isRestricted">
                        <button type="button" mat-icon-button color="accent" (click)="onAddSchedule()">
                            <mat-icon aria-label="Add">add</mat-icon>
                        </button>
                    </mat-list-item>
                </mat-list>

                <ng-template #isRegularly>
                    <section formGroupName="scheduleRegularly" class="scheduleRegularly">
                        <mat-form-field class="start-date-field">
                            <mat-label>{{ 'missions.create.startDate.label' | translate }}</mat-label>
                            <input matInput formControlName="startDateRegularly"
                                [matDatepicker]="startDatePickerRegularly" [errorStateMatcher]="confirmValidMatcher"
                                (click)="startDatePickerRegularly.open()" (focus)="startDatePickerRegularly.open()">
                            <mat-datepicker-toggle matSuffix [for]="startDatePickerRegularly"></mat-datepicker-toggle>
                            <mat-datepicker #startDatePickerRegularly></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field class="start-time-field">
                            <mat-label>{{ 'missions.create.startTime.label' | translate }}</mat-label>
                            <input id="inputTime" type="time" matInput formControlName="startTimeRegularly"
                                [errorStateMatcher]="confirmValidMatcher">
                        </mat-form-field>

                        <mat-form-field class="end-date-field">
                            <mat-label>{{ 'missions.create.regularly.endDate.label' | translate }}</mat-label>
                            <input matInput formControlName="endDateRegularly" [matDatepicker]="endDatePickerRegularly"
                                [errorStateMatcher]="confirmValidMatcher" (click)="endDatePickerRegularly.open()"
                                (focus)="endDatePickerRegularly.open()">
                            <mat-datepicker-toggle matSuffix [for]="endDatePickerRegularly"></mat-datepicker-toggle>
                            <mat-datepicker #endDatePickerRegularly></mat-datepicker>
                        </mat-form-field>

                        <section class="driverRegularly-field">
                            <app-autocomplete [control]="getControl('scheduleRegularly').get('driverRegularly')"
                                [list]="drivers" label="missions.create.driver.label"
                                errorMsg="missions.create.driver.errorMessage">
                            </app-autocomplete>
                        </section>

                        <mat-form-field class="frequency-field">
                            <mat-label>{{'missions.create.regularly.frequency.label' | translate}}</mat-label>
                            <mat-select formControlName="frequency">
                                <mat-option *ngFor="let frequency of frequencies"
                                    [value]="frequency">{{frequency.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="number-field">
                            <mat-label>{{'missions.create.regularly.number.label' | translate}}</mat-label>
                            <input matInput type="number" formControlName="number" min="1">
                        </mat-form-field>

                        <section
                            *ngIf="editForm.value.scheduleRegularly.frequency && editForm.value.scheduleRegularly.number"
                            class="sectionMessage">
                            <span class="message"
                                *ngIf="editForm.value.scheduleRegularly.frequency.value === 'days'">Tous les
                                {{editForm.value.scheduleRegularly.number}} jours</span>
                            <span class="message"
                                *ngIf="editForm.value.scheduleRegularly.frequency.value === 'weeks'">Toutes les
                                {{editForm.value.scheduleRegularly.number}} semaines</span>
                        </section>
                    </section>
                    <mat-list-item>

                    </mat-list-item>
                </ng-template>
            </fieldset>

            <section class="steps-form" *ngIf="!isRestricted && mission && isControlSet('steps')">
                <app-steps-edit-form [steps]="formSteps" [availableSteps]="steps"
                    (stepsChanged)="onStepsChanged($event)">
                </app-steps-edit-form>
            </section>

            <section class="sectionCopySteps" *ngIf="mission">
                <app-autocomplete [control]="copyStep" [list]="missions" label="steps.select.mission">
                </app-autocomplete>
                <button type="button" mat-raised-button color="accent" (click)="copySteps(copyStep.value)"
                    [disabled]="!copyStep.value">
                    Copier les étapes de
                </button>
            </section>

            <section class="check-fields">
                <mat-slide-toggle formControlName="isUrgent">{{ 'missions.create.isUrgent.label' | translate }}
                </mat-slide-toggle>

                <mat-slide-toggle formControlName="isFreeMode" *ngIf="isControlSet('isFreeMode')">{{
                    'missions.create.isFreeMode.label' | translate }}
                </mat-slide-toggle>
            </section>

            <mat-form-field class="comment-field">
                <mat-label>{{ 'missions.create.comment.label' | translate }}</mat-label>
                <textarea matInput rows="5" formControlName="comment"></textarea>
                <mat-error>{{ 'missions.create.comment.errorMessage' | translate: { maxLength: commentMaxLength } }}
                </mat-error>
            </mat-form-field>

            <section class="actions">
                <button *ngIf="!isLoading" type="submit" mat-icon-button color="accent"
                    [disabled]="editForm.invalid || (mission && (!formSteps || !formSteps.length))">
                    <mat-icon aria-label="Validate">check</mat-icon>
                </button>
                <button *ngIf="!isLoading" type="button" mat-icon-button (click)="onFinish()">
                    <mat-icon aria-label="Cancel">clear</mat-icon>
                </button>
                <mat-spinner *ngIf="isLoading" [diameter]="35" color="accent"></mat-spinner>
            </section>
        </form>
    </mat-card-content>
</mat-card>