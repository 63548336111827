import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {

    transform(array: any[], key: string, direction: 'asc' | 'desc' = 'asc'): any[] {
        if(array == null || !array.length || !key) {
            return array;
        }

        return array.sort((a: any, b: any) => {
            if (a[key] < b[key]) {
                return direction === 'asc' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }
}
