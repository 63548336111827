import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';

import { DatatableBaseComponent } from '@components/datatable-base.component';
import { UiService } from '@services/ui.service';
import { DevicesService } from '@services/devices.service';
import { Device } from '@classes/device';

@Component({
    selector: 'app-devices',
    templateUrl: './devices.component.html',
    styleUrls: ['./devices.component.scss']
})
export class DevicesComponent extends DatatableBaseComponent implements OnInit {
    devices: Device[];

    displayedColumns = ['name', 'model', 'version', 'user'];
    dataSource: MatTableDataSource<Device>;

    constructor(
        protected uiSrv: UiService,
        private route: ActivatedRoute,
        private devicesSrv: DevicesService
    ) {
        super(uiSrv);
    }

    ngOnInit() {
        super.ngOnInit();
        this.getRouteData();
        this.initSubscriptions();
    }

    private initTable(): void {
        this.dataSource = new MatTableDataSource(this.devices);
        this.dataSource.sortingDataAccessor = (item: any, property) => {
            switch(property) {
              case 'user': return item.user?.username;
              default: return item[property];
            }
          };
        this.dataSource.sort = this.sort;
    }

    private getRouteData(): void {
        this.route.data
        .subscribe((data: {devices: Device[]}) => {
            this.devices = data.devices;
            this.initTable();
        });
    }

    private initSubscriptions(): void {
        this.addSubscription(
            this.devicesSrv.devicesListChanged
            .subscribe(devices => {
                this.dataSource.data = devices || [];
                this.dataSource.sort = this.sort;
                // this.onEditFinished();
            })
        );
    }
}

