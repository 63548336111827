import { UsersService } from '@services/users.service';
import {
    Component,
    ChangeDetectionStrategy,
    OnInit,
    OnDestroy,
    ChangeDetectorRef
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
    CalendarEvent,
    CalendarView,
    DAYS_OF_WEEK,
    CalendarMonthViewDay
} from 'angular-calendar';
import {
    startOfMonth,
    endOfMonth,
    startOfWeek,
    endOfWeek,
    startOfDay,
    endOfDay,
} from 'date-fns';
import { Subscription, forkJoin } from 'rxjs';
import * as moment from 'moment';

import { MissionsService } from '@services/missions.service';
import { Mission } from '@classes/mission/mission';
import { UiService } from '@services/ui.service';
import { AssetService } from '@services/assets.service';
import { TemperaturesService } from '@services/temperatures.service';

import { Asset } from '@classes/mworker/asset';
import { AuthService } from '@services/auth.service';
import { AuthUser } from '@classes/auth/auth-user';

interface EventGroupMeta {
    type: string;
}

// weekStartsOn option is ignored when using moment, as it needs to be configured globally for the moment locale
moment.updateLocale('fr', {
    week: {
        dow: DAYS_OF_WEEK.MONDAY,
        doy: 0
    }
});

@Component({
    selector: 'app-missions-calendar',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './missions-calendar.component.html',
    styleUrls: ['./missions-calendar.component.scss']
})
export class MissionsCalendarComponent implements OnInit, OnDestroy {
    private subscription = new Subscription();

    view: CalendarView = CalendarView.Month;

    viewDate: Date = new Date();

    calendarEvents: CalendarEvent<{ mission?: any, alert?: any, incrementsBadgeAlert: boolean, type: string }>[] = [];

    activeDayIsOpen = false;
    locale = 'fr';
    isLoading: boolean;
    allAssetsOfAccount: Asset[] = [];
    alertArray = [];
    user: AuthUser;

    trackByEventId = (index: number, event: CalendarEvent) => event.id ? event.id : event;

    constructor(
        private missionsSrv: MissionsService,
        private uiSrv: UiService,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private route: ActivatedRoute,
        private assetSrv: AssetService,
        private temperatureSrv: TemperaturesService,
        private authSrv: AuthService,
        private usersSrv: UsersService
    ) { }

    ngOnInit() {
        this.user = this.authSrv.getUser();
        this.initSubscriptions();
        this.getMissions();
    }

    getMissions(): void {
        this.cdr.detectChanges();
        this.activeDayIsOpen = false;
        const datesRange = this.getDatesRange();
        this.missionsSrv.getList({ fromStartDate: datesRange.start, toStartDate: datesRange.end });
    }

    getAlerts(): void {
        this.isLoading = true;
        const datesRange = this.getDatesRange();
        const datesArray = [];
        this.alertArray = [];
        const sinceDate = moment(datesRange.start).startOf('day').format('YYYY-MM-DD HH:mm');
        const untilDate = moment(datesRange.end).endOf('day').format('YYYY-MM-DD HH:mm');

        this.allAssetsOfAccount.map(oneAsset => {
            datesArray.push(this.temperatureSrv.getAlertDateToDate(sinceDate, untilDate, oneAsset.id));
        });

        forkJoin(datesArray).subscribe(results => {
            results.map(oneData => {
                if (oneData[0] !== undefined) {
                    results.map((eventArray: []) => {
                        eventArray.map(event => {
                            this.alertArray.push(event);
                        });

                    });
                }
            });
            this.alertArray.map(alert => {

                this.calendarEvents.push(
                    {
                        title: alert.eventConfig.name,
                        start: new Date(alert.triggerDate),
                        color: {
                            primary: '#F44336',
                            secondary: '#F44336'
                        },
                        meta: {
                            alert,
                            incrementsBadgeAlert: true,
                            type: 'alert'
                        },
                        id: 'alert'
                    }
                );
            });
            this.isLoading = false;
            this.cdr.detectChanges();
        });
    }

    dayClicked({
        date,
        events,
    }: {
        date: Date;
        events: Array<CalendarEvent<{ mission: Mission }>>;
    }): void {
        if (moment(date).isSame(this.viewDate, 'month')) {
            if (
                (moment(this.viewDate).isSame(date, 'day')
                    && this.activeDayIsOpen === true)
                || events.length === 0
            ) {
                this.activeDayIsOpen = false;
            } else {
                this.activeDayIsOpen = true;
                this.viewDate = date;
            }
        }
    }

    eventClicked(event: CalendarEvent<{ mission?: Mission, alert?: any }>): void {
        if (event.meta.mission) {
            this.router.navigate([`../${event.meta.mission.id}`], { relativeTo: this.route });
        } else if (event.meta.alert) {
            this.router.navigate([`/temperatures/${event.meta.alert.id}`], { relativeTo: this.route });
        }
    }

    private initSubscriptions(): void {
        this.subscription.add(
            this.missionsSrv.missionsListChanged
                .subscribe(async (missions) => {
                    // this.calendarEvents = [];
                    this.calendarEvents = missions.map((mission: Mission) => {
                        return {
                            title: mission.name,
                            start: new Date(mission.startDate),
                            meta: {
                                mission,
                                incrementsBadgeAlert: false,
                                type: "mission"
                            }
                        };
                    });

                })
        );

        this.subscription.add(
            this.uiSrv.loadingStateChanged
                .subscribe(loadingState => {
                    this.isLoading = loadingState;
                    this.cdr.markForCheck();
                })
        );

        this.subscription.add(
            this.usersSrv.clientChangedSubject
                .subscribe(() => {
                    this.getMissions();
                })
        );

    }

    private getDatesRange(): { start: Date, end: Date } {
        const getStart: any = {
            month: startOfMonth,
            week: startOfWeek,
            day: startOfDay
        }[this.view];

        const getEnd: any = {
            month: endOfMonth,
            week: endOfWeek,
            day: endOfDay
        }[this.view];

        return {
            start: getStart(this.viewDate),
            end: getEnd(this.viewDate)
        };
    }

    beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
        body.forEach(day => {
            day.badgeTotal = day.events.filter(
                event => event.meta.incrementsBadgeAlert
            ).length;
            const groups = {};
            day.events.forEach((event: CalendarEvent<EventGroupMeta>) => {
                groups[event.meta.type] = groups[event.meta.type] || [];
                groups[event.meta.type].push(event);
            });
            day['eventGroups'] = Object.entries(groups);
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
