import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import * as signalR from '@aspnet/signalr';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { NotificationResponse } from '@classes/notification/notification-response';
import { MissionNotification } from '@classes/notification/mission-notification';
import { AuthResponse } from '@classes/auth/auth-response';
import { PaginationRequest } from '@classes/pagination/pagination-request';
import { Pagination } from '@classes/pagination/pagination';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    authChanged = new Subject<boolean>();
    notificationsSubject = new Subject<MissionNotification[]>();
    paginatioNotificationSubject = new BehaviorSubject<Pagination>(null);
    loadingStateChanged = new Subject<boolean>();

    private notifications: MissionNotification[];

    private signalRConnection: signalR.HubConnection;
    private hubUrl: string =  environment.apiUrl + 'hub/notifications';
    private baseUrl: string =  environment.apiUrl + 'notifications';
    private connected = false;
    private disconnected = false;
    private identityApiUrl: string = environment.identityApiUrl;

    private tokenKey: string = environment.tokenKey;
    private rfTokenKey: string = environment.rfTokenKey;
    private tokenExpKey: string = environment.tokenExpKey;

    filterValue = null;
    sortValue: string;
    pagination: Pagination;

    constructor(
        private jwtHelperSrv: JwtHelperService,
        private http: HttpClient
    ) {}

    init(): void {
        this.stop();

        this.signalRConnection = new signalR.HubConnectionBuilder()
            .withUrl(this.hubUrl, {
                // accessTokenFactory: () => this.jwtHelperSrv.tokenGetter(),
                accessTokenFactory: () => {
                    return localStorage.getItem(environment.tokenKey);
                }
            })
            // .configureLogging(signalR.LogLevel.Information)
            .build();

        this.signalRConnection.start().then(() => {
            this.connected = true;
            this.disconnected = false;
        });

        this.signalRConnection.onclose((error) => {
            const realThis = this;
            if(!this.disconnected) {
                setTimeout(() => {
                    if(realThis.connected) {
                        new Promise((resolve, reject) => {
                            this.refreshToken().subscribe(() => {
                                resolve(true);
                            });
                        }).then(() => {
                            realThis.reconnect();
                        });
                    }
                }, 5000);
            }
        });

        const newRequest = new PaginationRequest(
            this.filterValue,
            this.sortValue,
            1,
            12
        );

        this.signalRConnection.on("ReceiveMessage", (response: NotificationResponse) => {
            this.getList(newRequest);
        });
    }

    stop(): void {
        this.disconnected = true;
        if(this.connected) {
            this.signalRConnection.stop().then(() => {
                this.connected = false;
                this.notificationsSubject.next([]);
            });
        }
    }

    get(): Observable<MissionNotification[]> {
        this.loadingStateChanged.next(true);
        return this.http.get<NotificationResponse[]>(this.baseUrl)
        .pipe(
            map((response: NotificationResponse[]) => {
                const notifications: MissionNotification[] = response.map(res => {
                    return new MissionNotification(res);
                });
                this.notificationsSubject.next(notifications);
                this.loadingStateChanged.next(false);
                return notifications;
            })
        );
    }

    getList(request: PaginationRequest): void {
        let httpParams = new HttpParams();

        if(request.pageSize && request.includeCount && request.page) {
            httpParams = httpParams
                .append("pageSize", request.pageSize.toString())
                .append("includeCount", request.includeCount.toString())
                .append("page", request.page.toString());
        }

        this.http.get(this.baseUrl, {params: httpParams, observe: 'response'})
        .subscribe((result: HttpResponse<NotificationResponse[]>) => {
            const pagination: Pagination = JSON.parse(result.headers.get('X-Pagination'));
            this.notifications = result.body.map(res => {
                return new MissionNotification(res);
            });
            this.notificationsSubject.next([ ...this.notifications ]);
            this.paginatioNotificationSubject.next({ ...pagination });
        });
    }

    clear(): Observable<void> {
        this.loadingStateChanged.next(true);
        return this.http.put<void>(`${this.baseUrl}/clear`, {})
        .pipe(tap(() => {
            this.notificationsSubject.next([]);
            this.loadingStateChanged.next(false);
        }));
    }

    reconnect(): void {
        this.signalRConnection.start().then(() => {
            this.connected = true;
            this.disconnected = false;
        });
    }

    refreshToken(): Observable<AuthResponse> {
        const refreshToken = localStorage.getItem(this.rfTokenKey);
        const httpParams = new HttpParams()
            .append("grant_type", "refresh_token")
            .append("scope", "api offline_access")
            .append("client_id", "web")
            .append("refresh_token", refreshToken);

        return this.http.post<AuthResponse>(this.identityApiUrl + 'connect/token', httpParams)
        .pipe(
            tap(result => {
                this.saveToken(result.access_token, result.refresh_token, result.expires_in);
                this.authChanged.next(true);
            })
        );
    }

    private saveToken(accessToken: string, refreshToken: string, deadline: number): void {
        const tokenExpDate = new Date();
        tokenExpDate.setSeconds(tokenExpDate.getSeconds() + deadline);

        localStorage.setItem(this.tokenExpKey, tokenExpDate.toString());
        localStorage.setItem(this.tokenKey, accessToken);
        localStorage.setItem(this.rfTokenKey, refreshToken);
    }
}
