<mat-card [ngClass]="{'isUserLabo': isUserLabo}">
    <mat-card-header>
        <mat-card-title>
            <h2>{{ 'dashboard.currentMissions' | translate }}</h2>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-list>
            <mat-list-item class="listItemDashboard">
                <span class="label">{{ 'dashboard.missionsTotal' | translate }}</span> <span>{{ missionsLength }}</span>
            </mat-list-item>
            <mat-list-item class="listItemDashboard">
                <span class="label">{{ 'dashboard.samplesTotal' | translate }}</span> <span>{{ samplesTotal }}</span>
            </mat-list-item>
        </mat-list>
    </mat-card-content>
</mat-card>
