import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { SharedModule } from 'app/shared/shared.module';
import { SamplesListComponent } from './samples-list/samples-list.component';
import { MissionResumeComponent } from './mission-resume/mission-resume.component';
import { MissionsListComponent } from './missions-list/missions-list.component';
import { MissionsModule } from 'app/missions/missions.module';

@NgModule({
    declarations: [
        DashboardComponent,
        SamplesListComponent,
        MissionResumeComponent,
        MissionsListComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        MissionsModule
    ]
})
export class DashboardModule { }
