<mat-spinner *ngIf="isLoading" [diameter]="26"></mat-spinner>

<button mat-icon-button [matMenuTriggerFor]="notifMenu" *ngIf="!isLoading && pagination">
    <mat-icon aria-label="Notifications" matBadgeColor="warn" *ngIf="pagination.totalRecords"
        [matBadge]="pagination.totalRecords" [matBadgeHidden]="!pagination.totalRecords">
        notifications
    </mat-icon>
    <mat-icon aria-label="No notification" *ngIf="!pagination.totalRecords">
        notifications_none
    </mat-icon>
</button>

<mat-menu #notifMenu="matMenu" class="notif-menu">
    <h4>{{ 'main.notifications.title' | translate }}</h4>
    <section class="actions">
        <button mat-button color="accent"
            [disabled]="!notifications.length"
            (click)="onClear()">
            {{ 'main.notifications.clear' | translate }}
        </button>
    </section>
    <mat-list class="notif-list" *ngIf="notifications.length" (click)="$event.stopPropagation()">
    <!-- <mat-list class="notif-list" *ngIf="notifications.length"> -->
        <mat-list-item  *ngFor="let notification of notifications">
            <mat-icon mat-list-icon color="accent">{{ notification.icon }}</mat-icon>
            <h5 mat-line>
                <mat-icon *ngIf="notification.tagScanned">nfc</mat-icon>
                {{ notification.name }}
            </h5>
            <span mat-line>
                {{ notification.status | translate }} {{ notification.stepName }}
            </span>
            <span class="date">{{ notification.sentOn | amLocal | amDateFormat: 'DD/MM/YYYY HH:mm' }}</span>
        </mat-list-item>
        <mat-paginator *ngIf="pagination" [pageSize]="pagination.pageSize" [length]="pagination.totalRecords"
        [pageIndex]="pagination.pageNumber - 1" (page)="onPageChanged($event)" showFirstLastButtons>
</mat-paginator>
    </mat-list>
    <p class="empty" *ngIf="!notifications.length">{{ 'main.notifications.empty' | translate }}</p>
</mat-menu>
