import { Component, OnInit, Input } from '@angular/core';

import { AuthService } from '@services/auth.service';
import { User } from '@classes/user/user';

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {
    @Input() user: User;

    constructor(
        private authSrv: AuthService
    ) { }

    ngOnInit() {
    }

    onLogout(): void {
        this.authSrv.logout();
    }
}
