import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Injectable, InjectionToken, Injector } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TooltipData } from '@interfaces/tooltip-data';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { ComponentPortal } from '@angular/cdk/portal';

export const CONTAINER_DATA = new InjectionToken<{}>('CONTAINER_DATA');

@Injectable({
    providedIn: 'root'
})
export class UiService {
    loadingStateChanged = new Subject<boolean>();
    routeLoadingStateChanged = new Subject<boolean>();
    translationLoadingStateChanged = new Subject<boolean>();

    injector?: Injector | null;
    private overlayRef: OverlayRef;

    constructor(
        private snackbar: MatSnackBar,
        private translateSrv: TranslateService,
        private overlay: Overlay
    ) { }

    /**
     *
     * @param message Can be keys or custom string
     * @param isTranslated false by default, put to true if message is a key
     * @param ms 3000 by default
     * @param action null by default
     */
    showSnackbar(message: string, isTranslated = false, ms = 3000, action = null): void {
        if (isTranslated) {
            this.translateSrv.get(message).subscribe(text => {
                this.snackbar.open(text, action, { duration: ms });
            });
        } else {
            this.snackbar.open(message, action, { duration: ms });
        }
    }

    openOverlay(component: any, mouseEvent: MouseEvent, data?: TooltipData): void {
        // overlay's position on the cursor's position
        const positionStrategy = this.overlay.position()
            .global()
            .left(`${mouseEvent.clientX + 10}px`)
            .top(`${mouseEvent.clientY + 10}px`);

        this.overlayRef = this.overlay.create({ positionStrategy });



        if (data) {
            // adding datas to the overlay
            // const tokens = new WeakMap();
            // tokens.set(TooltipData, data);
            // injector = this.getInjector(data, this.parentInjector);

            const injector = Injector.create({
                parent: this.injector,
                providers: [
                    { provide: CONTAINER_DATA, useValue: data}
                ]
            });


            const portal = new ComponentPortal(
                component,
                null,
                injector
            );


            // create overlay
            this.overlayRef.attach(portal);
        }




    }

    closeOverlay(): void {
        this.overlayRef.dispose();
    }
}
