import { Injectable } from '@angular/core';
import { Observable, of, forkJoin, Subject } from 'rxjs';

import { environment } from 'environments/environment';

import { Temperature } from '@classes/mworker/temperature';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { RecordHistory } from '@classes/mworker/record-history';
import { RecordDays } from '@classes/mworker/record-days';
import { AssetService } from './assets.service';
import { EventConfig } from '@classes/mworker/eventConfig';
import { until } from 'protractor';

@Injectable({
    providedIn: 'root'
})
export class TemperaturesService {
    private urlRecHistory: string = environment.apiCoreDataMworkerUrl + 'record-history';
    private newUrlRecHistory: string = environment.apiCoreDataMworkerUrl + 'assets/';
    private urlCoreMworker: string = environment.apiCoreMworkerUrl;

    constructor(
        private http: HttpClient,
        private assetSrv: AssetService
    ) { }

    get(deviceId: string, sinceDateReceived: string, untilDateReceived: string): Observable<RecordHistory[]> {
        const sinceDate = moment(sinceDateReceived).format('YYYY-MM-DD HH:mm');
        const untilDate = moment(untilDateReceived).format('YYYY-MM-DD HH:mm');
        return this.http.get<RecordHistory[]>(`${this.urlRecHistory}/Datas?AssetId=${deviceId}&Since=${sinceDate}&Until=${untilDate}&AssetTypesId=6`
        , {headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('tokenMworker'))}
        )
            .pipe(
                map(temp => {
                    temp.map(oneTemp => {
                        oneTemp.idDevice = deviceId;
                    });
                    return temp;
                })
            );
    }

    newGet(deviceId: string, sinceDateReceived: string, untilDateReceived: string): Observable<any> {
        const sinceDate = moment(sinceDateReceived).format('YYYY-MM-DD HH:mm');
        const untilDate = moment(untilDateReceived).format('YYYY-MM-DD HH:mm');
        return this.http.get<any>(`${this.newUrlRecHistory}${deviceId}/record-history/by-data-type?since=${sinceDate}&until=${untilDate}&dataTypeId=6`
        , {headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('tokenMworker'))}
        )
            .pipe(
                map(results => {
                    results.records.map(oneTemp => {
                        oneTemp.idDevice = deviceId;
                    });
                    return results.records;
                })
            );
    }

    getDates(deviceId: string): Observable<RecordDays[]> {
        const sinceDate = moment(new Date("2019/11/15")).startOf('day').format('YYYY-MM-DD HH:mm');
        const untilDate = moment(new Date()).endOf('day').format('YYYY-MM-DD HH:mm');
        return this.http.get<RecordDays[]>(`${this.urlRecHistory}/DataDays?AssetId=${deviceId}&Since=${sinceDate}&Until=${untilDate}&AssetTypesId=6`
        , {headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('tokenMworker'))}
        )
            .pipe(
                map(date => {
                    return date;
                })
            );
    }

    newGetDates(deviceId: string): Observable<any[]> {
        const sinceDate = moment(new Date("2020/06/01")).startOf('day').utc().format();
        const untilDate = moment(new Date()).endOf('day').utc().format();
        return this.http.get<any[]>(`${this.newUrlRecHistory}${deviceId}/record-history/days/by-data-type?since=${sinceDate}&until=${untilDate}&dataTypeId=6`
        , {headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('tokenMworker'))}
        )
            .pipe(
                map(date => {
                    return date;
                })
            );
    }

    getAlertDateToDate(sinceDate: string, untilDate: string, assetId: string): Observable<any[]>{
        return this.http.get<any>(`${this.urlCoreMworker}events?assetID=${assetId}&startDate=${sinceDate}&endDate=${untilDate}&verbosity=full`
        , {headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('tokenMworker'))}
        )
            .pipe(
                map(event => {
                    return event.events;
                })
            );
    }

    getAlertById(id: string): Observable<any> {
        return this.http.get<any>(`${this.urlCoreMworker}events/${id}`
        , {headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('tokenMworker'))}
        );
    }

    getDetailsAlertById(id: string): Observable<any> {
        return this.http.get<any>(`${this.urlCoreMworker}event-configurations/${id}`
        , {headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('tokenMworker'))}
        );
    }

    getEventConditions(): Observable<any> {
        return this.http.get<any>(`${this.urlCoreMworker}event-conditions?q=temperature&interview=false`
        , {headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('tokenMworker'))}
        );
    }

    createAlert(alert): Observable<EventConfig> {
        return this.http.post<EventConfig>(`${this.urlCoreMworker}event-configurations`, alert
        , {headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('tokenMworker'))}
        );
    }

    getAllAlertsByAccount(): Observable<EventConfig> {
        return this.http.get<any>(`${this.urlCoreMworker}event-configurations?accountID=${localStorage.getItem('accountId')}&verbosity=full`
        , {headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('tokenMworker'))}
        );
    }

    deleteAlert(id: string) {
        return this.http.delete(`${this.urlCoreMworker}EventConfig/${id}`
        , {headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('tokenMworker'))}
        );
    }
}
