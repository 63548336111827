import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Client } from '@classes/client';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {
    clientsListChanged = new Subject<Client[]>();
    private baseUrl: string = environment.apiUrl + 'clients';
    private clients: Client[];

    constructor(
        private http: HttpClient
    ) { }

    getList(): Observable<Client[]> {
        return this.http.get<Client[]>(this.baseUrl)
        .pipe(
            map(clients => {
                this.clients = clients.map(c => Client.fromObject(c));
                this.clientsListChanged.next([ ...this.clients ]);
                return [ ...this.clients ];
            })
        );
    }

    getAllClients(): Observable<Client[]> {
        return this.http.get<Client[]>(`${this.baseUrl}?filtered=false`)
        .pipe(
            map(clients => {
                this.clients = clients.map(c => Client.fromObject(c));
                this.clientsListChanged.next([ ...this.clients ]);
                return [ ...this.clients ];
            })
        );
    }

    put(client: Client): Observable<Client> {
        return this.http.put<Client>(this.baseUrl, client)
        .pipe(
            tap(editedClient => {
                editedClient = Client.fromObject(editedClient);
                this.clients = this.clients.map(c => c.id === editedClient.id ? editedClient : c);
                this.clientsListChanged.next([ ...this.clients ]);
            })
        );
    }

    post(client: Client): Observable<Client> {
        return this.http.post<Client>(this.baseUrl, client)
        .pipe(
            tap(createdClient => {
                createdClient = Client.fromObject(createdClient);
                this.clients.push(createdClient);
                this.clientsListChanged.next([ ...this.clients ]);
            })
        );
    }
}
