import { Component, Input, OnInit } from '@angular/core';
import { Sample } from '@classes/sample';
import { AuthService } from '@services/auth.service';
import { RoleName } from '@enums/role-name.enum';

@Component({
    selector: 'app-samples-list',
    templateUrl: './samples-list.component.html',
    styleUrls: ['./samples-list.component.scss']
})
export class SamplesListComponent implements OnInit{
    @Input() samples: Sample[];
    isUserLabo: boolean;

    constructor(
        private authSrv: AuthService
    ) { }

    ngOnInit(): void {
        this.isUserLabo = this.authSrv.getUser().role.name === RoleName.labo;
    }
}
