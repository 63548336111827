import { AuthService } from '@services/auth.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { User } from '@classes/user/user';
import { environment } from '@environments/environment';
import { UiService } from './ui.service';
import { RoleName } from '@enums/role-name.enum';
import { Client } from '@classes/client';

@Injectable({
    providedIn: 'root'
})
export class UsersService {
    usersListChanged = new Subject<User[]>();
    clientChangedSubject = new Subject<boolean>();
    clientSelected: string = '';

    private baseUrl: string = environment.apiUrl + 'users';
    private users: User[];

    constructor(
        private http: HttpClient,
        private uiSrv: UiService,
        private authSrv: AuthService
    ) { }

    getList(role?: RoleName, isActive?: boolean): Observable<User[]> {
        let httpParams = new HttpParams();

        if (role) {
            httpParams = httpParams.append("roleName", role.toString());
        }

        if (isActive) {
            httpParams = httpParams.append("isActive", isActive);
        }

        return this.http.get<User[]>(this.baseUrl, { params: httpParams })
            .pipe(
                map(users => {
                    this.users = users.map(u => User.fromObject(u));
                    this.usersListChanged.next([...this.users]);
                    return [...this.users];
                })
            );
    }

    getListByClientId(clientId, role?: RoleName, isActive?: boolean): Observable<User[]> {
        let httpParams = new HttpParams();

        if (role) {
            httpParams = httpParams.append("roleName", role.toString());
        }

        if (isActive) {
            httpParams = httpParams.append("isActive", isActive);
        }

        return this.http.get<User[]>(`${this.baseUrl}?clientId=${clientId}`, { params: httpParams })
            .pipe(
                map(users => {
                    this.users = users.map(u => User.fromObject(u));
                    this.usersListChanged.next([...this.users]);
                    return [...this.users];
                })
            );
    }

    put(user: User): Observable<User> {
        return this.http.put<User>(this.baseUrl, user)
            .pipe(
                tap(editedUser => {
                    editedUser = User.fromObject(editedUser);
                    this.users = this.users.map(u => u.id === editedUser.id ? editedUser : u);
                    this.usersListChanged.next([...this.users]);
                })
            );
    }

    post(user: User): Observable<User> {
        return this.http.post<User>(this.baseUrl, user)
            .pipe(
                tap(createdUser => {
                    createdUser = User.fromObject(createdUser);
                    this.users.push(createdUser);
                    this.usersListChanged.next([...this.users]);
                    this.showEmailSentMsg();
                })
            );
    }

    delete(id: string): Observable<void> {
        return this.http.get<void>(`${this.baseUrl}/${id}`)
            .pipe(
                tap(() => {
                    this.users = this.users.filter(u => u.id !== id);
                    this.usersListChanged.next([...this.users]);
                })
            );
    }

    changeStatus(id: string, method: 'activate' | 'deactivate'): Observable<void> {
        return this.http.put<void>(`${this.baseUrl}/${id}/${method}`, null);
    }

    private showEmailSentMsg(isEmailSent = true): void {
        if (isEmailSent) {
            this.uiSrv.showSnackbar('users.emailSent', true, 5000);
        }
    }

    chooseAllClient(): Observable<User> {
        return this.http.put<User>(`${this.baseUrl}/all-client`, 'all')
            .pipe(
                tap(editedUser => {
                    this.clientChangedSubject.next(true);
                })
            );
    }

    chooseClient(client: Client): Observable<User> {
        return this.http.put<User>(`${this.baseUrl}/client`, client)
            .pipe(
                tap(editedUser => {
                    this.authSrv.changeClientUser(editedUser.client.id)
                    this.clientChangedSubject.next(true);
                })
            );
    }

    getCurrentUser() {
        return this.http.get<User>(`${this.baseUrl}/current`)
            .pipe(
        );
    }
}
