<mat-form-field>
    <input type="text" matInput [formControl]="control" [matAutocomplete]="auto">
    <mat-label>{{ label | translate }}
        <ng-container *ngIf="number >= 0 && !lastStep">{{number + 1}}</ng-container>
        <ng-container *ngIf="lastStep"> finale</ng-container>
        <ng-container *ngIf="numberContact > 1">{{numberContact + 1}}</ng-container>
    </mat-label>

    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let item of filteredList | async" [value]="item">
            {{ item.display }}
        </mat-option>
    </mat-autocomplete>
    <mat-error>{{ errorMsg | translate }} <ng-container *ngIf="inOneStep"> avec une étape par
            défaut</ng-container></mat-error>
</mat-form-field>