import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './shared/guards/auth.guard';
import { UnauthGuard } from './shared/guards/unauth.guard';
import { ChangePwdGuard } from './shared/guards/change-pwd.guard';

import { UsersResolver } from './shared/resolvers/users.resolver';
import { ContactsResolver } from './shared/resolvers/contacts.resolver';
import { ClientsResolver } from './shared/resolvers/clients.resolver';
import { DevicesResolver } from './shared/resolvers/devices.resolver';
import { SamplesResolver } from './shared/resolvers/samples.resolver';
import { AddressesResolver } from './shared/resolvers/addresses.resolver';
import { StepsResolver } from './shared/resolvers/steps.resolver';
import { TemplatesResolver } from './shared/resolvers/templates.resolver';
import { MissionResolver } from './shared/resolvers/mission.resolver';
import { DashboardResolver } from './shared/resolvers/dashboard.resolver';
import { AssetsResolver } from './shared/resolvers/assets.resolver';
import { AlertResolver } from './shared/resolvers/alert.resolver';

import { RoleName } from '@enums/role-name.enum';

import { LoginComponent } from './auth/login/login.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RepositoryComponent } from './repository/repository.component';
import { ContactsComponent } from './repository/contacts/contacts.component';
import { ClientsComponent } from './repository/clients/clients.component';
import { DevicesComponent } from './repository/devices/devices.component';
import { SamplesComponent } from './repository/samples/samples.component';
import { StepsComponent } from './repository/steps/steps.component';
import { MissionsComponent } from './missions/missions.component';
import { UsersComponent } from './users/users.component';
import { ParametersComponent } from './parameters/parameters.component';
import { ErrorComponent } from './error/error.component';
import { AddressesComponent } from './repository/addresses/addresses.component';
import { TemplatesComponent } from './templates/templates.component';
import { MissionCreateComponent } from './missions/mission-create/mission-create.component';
import { MissionsListComponent } from './missions/missions-list/missions-list.component';
import { MissionDetailComponent } from './missions/mission-detail/mission-detail.component';
import { MissionsCalendarComponent } from './missions/missions-calendar/missions-calendar.component';
import { TemperaturesSuiviComponent } from './temperatures/temperatures-suivi/temperatures-suivi.component';
import { TemperaturesDetailComponent } from './temperatures/temperatures-detail/temperatures-detail.component';
import { TemperaturesComponent } from './temperatures/temperatures.component';
import { TemperaturesCreateAlertComponent } from './temperatures/temperatures-create-alert/temperatures-create-alert.component';
import { TemperaturesAlertListComponent } from './temperatures/temperatures-alert-list/temperatures-alert-list.component';
import { AlertsListResolver } from './shared/resolvers/alertsList.resolver';
import { TemperaturesAlertDetailComponent } from './temperatures/temperatures-alert-detail/temperatures-alert-detail.component';
import { AlertDetailResolver } from './shared/resolvers/alertDetail.resolver';
import { TemperaturesEditAlertComponent } from './temperatures/temperatures-edit-alert/temperatures-edit-alert.component';


const routes: Routes = [
    {
        path: 'login', component: LoginComponent,
        canActivate: [UnauthGuard],
        data: { title: 'auth.login.title' }
    },
    {
        path: 'forgot-password', component: ForgotPasswordComponent,
        canActivate: [UnauthGuard],
        data: { title: 'auth.forgotPassword' }
    },

    {
        path: 'welcome', component: ChangePasswordComponent,
        canActivate: [ChangePwdGuard],
        data: { title: 'auth.changePassword.title' }
    },
    {
        path: 'change-password', component: ChangePasswordComponent,
        canActivate: [ChangePwdGuard],
        data: { title: 'auth.changePassword.title' }
    },

    {
        path: 'dashboard', component: DashboardComponent,
        canActivate: [AuthGuard],
        resolve: { dashboard: DashboardResolver },
        data: {
            title: 'dashboard.title',
            roles: [
                RoleName.admin,
                RoleName.admin_labo,
                RoleName.labo
            ]
        }
    },
    {
        path: 'missions', component: MissionsComponent,
        canActivateChild: [AuthGuard],
        data: {
            title: 'missions.title',
            roles: [
                RoleName.admin,
                RoleName.admin_labo,
                RoleName.driver,
                RoleName.labo
            ]
        },
        children: [
            {
                path: 'create', component: MissionCreateComponent,
                data: {
                    title: 'missions.create.title',
                    roles: [
                        RoleName.admin,
                        RoleName.labo,
                        RoleName.admin_labo,
                    ]
                }
            },
            {
                path: 'list', component: MissionsListComponent,
                data: { title: 'missions.list.title' }
            },
            {
                path: 'calendar', component: MissionsCalendarComponent,
                data: { title: 'missions.calendar.title' }
            },
            {
                path: ':id', component: MissionDetailComponent,
                resolve: { mission: MissionResolver },
                data: { title: 'missions.detail.title' }
            },
            { path: '**', redirectTo: 'list', pathMatch: 'full' }
        ]
    },
    {
        path: 'templates', component: TemplatesComponent,
        canActivate: [AuthGuard],
        resolve: { templates: TemplatesResolver },
        data: {
            title: 'templates.title',
            roles: [
                RoleName.admin,
                RoleName.admin_labo
            ]
        }
    },
    {
        path: 'repository', component: RepositoryComponent,
        canActivateChild: [AuthGuard],
        data: {
            title: 'repository.title',
            roles: [
                RoleName.admin,
                RoleName.admin_labo
            ]
        },
        children: [
            {
                path: 'contacts', component: ContactsComponent,
                resolve: { contacts: ContactsResolver },
                data: { title: 'repository.contacts.title' }
            },
            {
                path: 'clients', component: ClientsComponent,
                resolve: { clients: ClientsResolver },
                data: {
                    title: 'repository.clients.title',
                    roles: [
                        RoleName.admin
                    ]
                }
            },
            {
                path: 'devices', component: DevicesComponent,
                resolve: { devices: DevicesResolver },
                data: {
                    title: 'repository.devices.title',
                    roles: [
                        RoleName.admin
                    ]
                }
            },
            {
                path: 'samples', component: SamplesComponent,
                resolve: { samples: SamplesResolver },
                data: { title: 'repository.samples.title' }
            },
            {
                path: 'steps', component: StepsComponent,
                resolve: { steps: StepsResolver },
                data: { title: 'repository.steps.title' }
            },
            {
                path: 'addresses', component: AddressesComponent,
                resolve: { addresses: AddressesResolver },
                data: { title: 'repository.addresses.title' }
            },
            { path: '**', redirectTo: 'contacts', pathMatch: 'full' }
        ]
    },
    {
        path: 'users', component: UsersComponent,
        canActivate: [AuthGuard],
        resolve: { users: UsersResolver },
        data: {
            title: 'users.title',
            roles: [
                RoleName.admin
            ]
        }
    },
    {
        path: 'parameters', component: ParametersComponent,
        canActivate: [AuthGuard],
        data: {
            title: 'parameters.title',
            roles: [
                RoleName.admin
            ]
        }
    },

    { path: 'error', component: ErrorComponent, data: { title: 'error' } },

    { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
