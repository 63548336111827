<form class="edit-form" *ngIf="!isLoading && editForm" [formGroup]="editForm" (submit)="onSubmit()">
    <section>
        <app-autocomplete
            [control]="getControl('client')" [list]="clients" label="missions.create.client.label"
            errorMsg="missions.create.client.errorMessage"></app-autocomplete>
    </section>

    <mat-form-field>
        <mat-label>{{ 'repository.steps.edit.name.label' | translate }}</mat-label>
        <input type="text" matInput formControlName="name">
        <mat-error>{{ 'repository.steps.edit.name.errorMessage' | translate }}</mat-error>
    </mat-form-field>

    <section class="form-field" *ngIf="addresses && isControlSet('address')">
        <app-autocomplete [control]="getControl('address')" [list]="addresses"
            label="repository.steps.edit.address.label" errorMsg="repository.steps.edit.address.errorMessage">
        </app-autocomplete>
    </section>

    <ng-container formArrayName="contacts" *ngIf="contacts && isControlSet('contacts')">
        <section class="form-field contactSection"
            *ngFor="let contactControl of getArray('contacts').controls; index as i">
            <app-autocomplete [control]="contactControl" [list]="contacts"
                [label]="i <= 1 ? (i === 0 ? 'repository.steps.edit.contact.default' : 'repository.steps.edit.contact.secondary') : 'repository.clients.edit.contact.number'"
                [numberContact]="i" class="contactField"  errorMsg="repository.steps.edit.contact.errorMessage">
            </app-autocomplete>

            <mat-checkbox class="smsNotification" *ngIf="contactControl.value && contactControl.value.mobilePhone" [checked]="contactControl.value.smsNotification" (change)="checkSmsNotif($event, contactControl)">SMS
            </mat-checkbox>

            <button type="button" mat-icon-button color="accent" *ngIf="i !== 0" (click)="removeContact(contactControl)" [matTooltip]="'repository.steps.edit.removeContact' | translate">
                <mat-icon class="clearButton">clear</mat-icon>
            </button>
        </section>
        <button type="button" mat-icon-button color="accent" (click)="onAddContact()" class="addContactButton" [matTooltip]="'repository.clients.edit.addContact' | translate">
            <mat-icon aria-label="Add">add</mat-icon>
        </button>
    </ng-container>

    <mat-spinner *ngIf="isLoadingContacts || isLoadingAdresses" [diameter]="35" color="accent"></mat-spinner>

    <section class="sectionClient">
        <mat-checkbox class="checkboxClient" formControlName="isDeposit">{{ 'repository.steps.edit.isDeposit' |
            translate }}</mat-checkbox>
    </section>

    <mat-form-field class="time-field">
        <mat-label>{{ 'repository.steps.edit.timeOnSite.label' | translate }}</mat-label>
        <!-- <span matPrefix>{{ 'repository.steps.edit.timeOnSite.prefix' | translate }}</span> -->
        <input type="text" matInput formControlName="timeOnSite">
        <span matSuffix>{{ 'repository.steps.edit.timeOnSite.suffix' | translate }}</span>
        <mat-error>{{ 'repository.steps.edit.timeOnSite.errorMessage' | translate }}</mat-error>
    </mat-form-field>

    <mat-form-field class="tag-field">
        <mat-chip-list #chipList aria-label="Puce selection" formControlName="tags">
            <mat-chip *ngFor="let tag of tags.value" [selectable]="selectable" [removable]="removable"
                (removed)="remove(tag)">
                {{tag}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input placeholder="Puce NFC" [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="add($event)">
        </mat-chip-list>
    </mat-form-field>

    <!-- <fieldset class="clients-list">
        <legend>
            <h3>{{ 'repository.steps.edit.clients' | translate }}</h3>
        </legend>
        <mat-list formArrayName="clients">
            <mat-list-item *ngFor="let clientControl of getArray('clients').controls; index as i">
                <section class="form-field">
                    <app-autocomplete [control]="clientControl" [list]="clientsList"
                        label="users.edit.clients.select.label" errorMsg="users.edit.clients.select.errorMessage">
                    </app-autocomplete>
                </section>

                <button type="button" mat-icon-button color="accent"
                    [disabled]="getArray('clients').controls.length <= 1" (click)="onRemoveClient(i)">
                    <mat-icon aria-label="delete">delete</mat-icon>
                </button>
            </mat-list-item>
            <mat-list-item>
                <button type="button" mat-icon-button color="accent" (click)="onAddClient()">
                    <mat-icon aria-label="Add">add</mat-icon>
                </button>
            </mat-list-item>
        </mat-list>
    </fieldset> -->

    <mat-form-field class="comment-field">
        <mat-label>{{ 'repository.steps.edit.comment.label' | translate }}</mat-label>
        <textarea matInput rows="6" formControlName="comment"></textarea>
        <mat-error>{{ 'repository.steps.edit.comment.errorMessage' | translate }}</mat-error>
    </mat-form-field>

    <section class="actions">
        <button *ngIf="!isLoading" type="submit" mat-icon-button color="accent" [disabled]="editForm.invalid">
            <mat-icon aria-label="Validate">check</mat-icon>
        </button>
        <button *ngIf="!isLoading" type="button" mat-icon-button (click)="onCancel()">
            <mat-icon aria-label="Cancel">clear</mat-icon>
        </button>
    </section>
</form>
<mat-spinner *ngIf="isLoading" [diameter]="35" color="accent"></mat-spinner>
