import { StepsService } from './../../shared/services/steps.service';
import { state, style, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '@services/users.service';
import { AuthService } from './../../shared/services/auth.service';
import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Mission } from '@classes/mission/mission';
import { Pagination } from '@classes/pagination/pagination';
import { PaginationRequest } from '@classes/pagination/pagination-request';
import { Step } from '@classes/step/step';
import { DatatableBaseComponent } from '@components/datatable-base.component';
import { MissionStatus } from '@enums/mission-status.enum';
import { RoleName } from '@enums/role-name.enum';
import { FormatService } from '@services/format.service';
import { MissionsService } from '@services/missions.service';
import { UiService } from '@services/ui.service';
import * as moment from 'moment';

@Component({
    selector: 'app-missions-list',
    templateUrl: './missions-list.component.html',
    styleUrls: ['./missions-list.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' }))
        ])
    ]
})
export class MissionsListComponent extends DatatableBaseComponent implements OnInit {
    missions: Mission[];

    displayedColumns: string[];
    displayedColumnsMenu: any[] = [
        { editable: false, name: 'select', selected: true, visible: false },
        { editable: true, name: 'name', selected: true, visible: false },
        { editable: true, name: 'template', selected: false, visible: false },
        { editable: true, name: 'steps.length', selected: true, visible: false },
        { editable: true, name: 'completion', selected: false, visible: false },
        { editable: true, name: 'timeLeft', selected: false, visible: false },
        { editable: true, name: 'timeSpent', selected: false, visible: false },
        { editable: true, name: 'totalSamples', selected: true, visible: false },
        { editable: true, name: 'nextStepName', selected: true, visible: false },
        { editable: true, name: 'nextStepAddress', selected: false, visible: false },
        { editable: true, name: 'timeEnd', selected: false, visible: false },
        { editable: true, name: 'status', selected: false, visible: false },
        { editable: true, name: 'user', selected: false, visible: false },
        { editable: true, name: 'client', selected: true, visible: false },
        { editable: true, name: 'startDate', selected: true, visible: false },
        { editable: true, name: 'createdOn', selected: true, visible: false },
        { editable: true, name: 'modifiedOn', selected: true, visible: false },
        { editable: true, name: 'createdBy', selected: true, visible: false },
        { editable: false, name: 'actions', selected: true, visible: false },
    ];

    dataSource: MatTableDataSource<Mission>;
    missionStatus = MissionStatus;

    filterValue = null;
    pagination: Pagination;
    sortValue: string;

    filterForm: FormGroup;
    isAdmin = false;
    canDelete = true;

    initialSelection = [];
    allowMultiSelect = true;
    selection = new SelectionModel<any>(this.allowMultiSelect, this.initialSelection);

    allStatus = [
        {
            value: 1,
            name: "Nouvelle mission"
        },
        {
            value: 2,
            name: "En cours"
        },
        {
            value: 4,
            name: "En pause"
        },
        {
            value: 8,
            name: "Terminée"
        },
        {
            value: 16,
            name: "Annulée"
        }
    ];

    steps: Step[] = [];
    isLoadingSteps = false;

    constructor(
        protected uiSrv: UiService,
        protected fb: FormBuilder,
        private missionsSrv: MissionsService,
        private router: Router,
        private route: ActivatedRoute,
        private formatSrv: FormatService,
        private authSrv: AuthService,
        private usersSrv: UsersService,
        private stepsSrv: StepsService
    ) {
        super(uiSrv);
    }

    ngOnInit() {
        super.ngOnInit();
        this.isAdmin = this.authSrv.getUser().role.name === RoleName.admin;
        this.checkIfColumnsInStorage();
        this.initForm();
        this.initSubscriptions();
    }

    checkIfColumnsInStorage(): void {
        if (localStorage.getItem('displayedColumnsMenu')) {
            this.displayedColumnsMenu = JSON.parse(localStorage.getItem('displayedColumnsMenu'));
        } else {
            this.displayedColumnsMenu = [
                { editable: false, name: 'select', selected: true, visible: false },
                { editable: true, name: 'name', selected: true, visible: false },
                { editable: true, name: 'template', selected: false, visible: false },
                { editable: true, name: 'steps.length', selected: true, visible: false },
                { editable: true, name: 'completion', selected: false, visible: false },
                { editable: true, name: 'timeLeft', selected: false, visible: false },
                { editable: true, name: 'timeSpent', selected: false, visible: false },
                { editable: true, name: 'totalSamples', selected: true, visible: false },
                { editable: true, name: 'nextStepName', selected: true, visible: false },
                { editable: true, name: 'nextStepAddress', selected: false, visible: false },
                { editable: true, name: 'timeEnd', selected: false, visible: false },
                { editable: true, name: 'status', selected: false, visible: false },
                { editable: true, name: 'user', selected: false, visible: false },
                { editable: true, name: 'client', selected: true, visible: false },
                { editable: true, name: 'startDate', selected: true, visible: false },
                { editable: true, name: 'createdOn', selected: true, visible: false },
                { editable: true, name: 'modifiedOn', selected: true, visible: false },
                { editable: true, name: 'createdBy', selected: true, visible: false },
                { editable: false, name: 'actions', selected: true, visible: false },
            ];
        }
    }

    initForm(): void {
        this.filterForm = new FormGroup({
            name: new FormControl(),
            template: new FormControl(),
            status: new FormControl(),
            user: new FormControl(),
            client: new FormControl(),
            fromStartDate: new FormControl(),
            toStartDate: new FormControl(),
            fromCreatedOn: new FormControl(),
            toCreatedOn: new FormControl(),
            fromModifiedOn: new FormControl(),
            toModifiedOn: new FormControl(),
            createdBy: new FormControl(),
            step: new FormControl()
        });
    }

    showStatusText(status: MissionStatus): string {
        return 'missions.status.' + MissionStatus[status];
    }

    calcMissionCompletion(steps: Step[]): string {
        const completion: number = this.missionsSrv.calcCompletion(steps);
        return this.formatSrv.formatPercent(completion);
    }

    onSelectMenu(event, name) {
        this.displayedColumnsMenu.find(f => f.name === name).selected = event.checked;
        localStorage.setItem('displayedColumnsMenu', JSON.stringify(this.displayedColumnsMenu));
        this.setDisplayedColumns();
    }

    private setDisplayedColumns(): void {
        this.displayedColumns = this.displayedColumnsMenu.filter(f => (f || {}).selected).map(m => m.name);
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach(row => this.selection.select(row));
    }

    onFilter(): void {
        this.selection.clear();
        const formValue = this.filterForm.value;

        this.filterValue = formValue;
        this.getList();
    }

    clearFilters(): void {
        this.filterForm.reset();
        this.filterValue = null;
        this.getList();
    }

    onPageChanged(pageEvent: PageEvent): void {
        this.selection.clear();
        this.pagination.pageNumber = pageEvent.pageIndex + 1;
        this.getList();
    }

    onSortChanged(sortEvent: Sort): void {
        if (sortEvent.direction === '') {
            this.sortValue = null;
        } else {
            this.sortValue = `${sortEvent.active} ${sortEvent.direction}`;
        }
        this.getList();
    }

    onExport(): void {
        const idMissions = [];
        this.selection.selected.map(mission => {
            idMissions.push(mission.id);
        });
        this.missionsSrv.exportExcel(idMissions).subscribe(() => {
            this.selection.clear();
        });
    }

    onDelete(): void {
        let confirmation = confirm("Êtes-vous certain de vouloir supprimer ces missions ?");
        if (confirmation) {
            const idMissions = [];
            this.selection.selected.map(mission => {
                idMissions.push(mission.id);
            });
            this.missionsSrv.delete(idMissions).subscribe(() => {
                this.onFilter();
            });
        }

    }

    onExportRoadMap(): void {
        const idMissions = [];
        this.selection.selected.map(mission => {
            idMissions.push(mission.id);
        });
        this.missionsSrv.exportPdf(idMissions).subscribe(() => {
            this.selection.clear();
        });
    }

    onSelectMission(id: string): void {
        this.router.navigate([`../${id}`], { relativeTo: this.route });
    }

    private getList(): void {
        const newRequest = new PaginationRequest(
            this.filterValue,
            this.sortValue,
            this.pagination.pageNumber,
            40
        );

        this.missionsSrv.getList(newRequest);
    }

    private initTable(): void {
        this.dataSource = new MatTableDataSource(this.missions);
        // this.dataSource.sort = this.sort;
    }

    private initSubscriptions(): void {
        this.isLoadingSteps = true;

        this.missionsSrv.getList(new PaginationRequest());

        this.addSubscription(
            this.missionsSrv.missionsListChanged
                .subscribe(missions => {
                    this.missions = missions;
                    this.setDisplayedColumns();
                    this.initTable();
                })
        );

        this.addSubscription(
            this.missionsSrv.paginationSubject
                .subscribe(pagination => {
                    this.pagination = pagination;
                })
        );

        this.selection.changed.subscribe(() => {
            let hasFoundOne = false;
            this.selection.selected.map(mission => {
                if (mission.status !== 8 && mission.status !== 16) {
                    hasFoundOne = true;
                }
            })

            if (hasFoundOne) {
                this.canDelete = false;
            } else {
                this.canDelete = true;
            }
        })

        this.addSubscription(
            this.usersSrv.clientChangedSubject
                .subscribe(() => {
                    this.getList();
                    this.getStepList();
                })
        );

        this.addSubscription(
            this.stepsSrv.getList().subscribe((stepsFromSrv: Step[]) => {
                this.steps = stepsFromSrv;
                this.isLoadingSteps = false;
            }, (error) => {
                console.log("error", error);
                this.isLoadingSteps = false;
            })
        )
    }

    timeLeft(mission): number {
        return mission.steps.reduce((total: number, step: Step) => {
            if (step.finishedOn == null && step.ignoredOn == null) {
                return total += step.estimatedTime + step.timeOnSite;
            }

            return total;
        }, 0);
    }

    getStepList() {
        this.steps = [];
        this.isLoadingSteps = true;
        this.addSubscription(
            this.stepsSrv.getList().subscribe((stepsFromSrv: Step[]) => {
                this.filterForm.get('step').reset();
                this.filterForm.get('step').updateValueAndValidity();
                this.steps = stepsFromSrv;
                this.isLoadingSteps = false;
            }, (error) => {
                console.log("error", error);
                this.isLoadingSteps = false;
            })
        )
    }

    timeSpent(mission): number {
        return this.diff_minutes(new Date(mission.finishedOn), new Date(mission.startedOn));
    }

    completedStepsNumber(mission): number {
        return mission.steps.filter(s => s.finishedOn != null).length;
    }

    diff_minutes(dt2, dt1) {
        let diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= 60;
        return Math.abs(Math.round(diff));

    }

    getTotalSamples(mission): number {
        return mission.steps.reduce((total: number, step: Step) => {
            if (step.samples) {
                let subTotal = 0;
                step.samples.map(sample => {
                    subTotal += sample.quantity;
                })
                return total += subTotal;
            }

            return total;
        }, 0);
    }

    getBlood(mission): number {
        return mission.steps.reduce((total: number, step: Step) => {
            if (step.samples) {
                return total += step.samples.length;
            }

            return total;
        }, 0);
    }

    getNextStepName(mission): string {
        let index = mission.steps.indexOf(mission.steps.filter(s => s.finishedOn != null).sort(this.compareValues('finishedOn', 'desc'))[0]);
        if (mission.steps[index + 1]) {
            return mission.steps[index + 1].name;
        } else {
            return null;
        }
    }

    getNextStepAddress(mission): string {
        let index = mission.steps.indexOf(mission.steps.filter(s => s.finishedOn != null).sort(this.compareValues('finishedOn', 'desc'))[0]);
        if (mission.steps[index + 1]) {
            let step = mission.steps[index + 1].address;
            let address = step.postalCode + ' ' + step.locality + ' ' + step.street + ' ' + step.streetNumber;
            return address;
        } else {
            return null;
        }
    }

    getTimeEndMission(mission) {
        let lastStep = mission.steps.filter(s => s.finishedOn != null).sort(this.compareValues('finishedOn', 'desc'))[0];
        let timeLeft = this.timeLeft(mission);
        if (lastStep) {
            return moment(new Date(lastStep.finishedOn)).add(timeLeft, 'minutes');
        } else {
            return moment(new Date(mission.startedOn)).add(timeLeft, 'minutes');
        }


    }

    compareValues(key, order = 'asc') {
        return function innerSort(a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                // property doesn't exist on either object
                return 0;
            }

            const varA = (typeof a[key] === 'string')
                ? a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string')
                ? b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === 'desc') ? (comparison * -1) : comparison
            );
        };
    }

    drop(event: CdkDragDrop<string[]>) {
        // Swap the elements around
        moveItemInArray(this.displayedColumnsMenu, event.previousIndex, event.currentIndex);
        localStorage.setItem('displayedColumnsMenu', JSON.stringify(this.displayedColumnsMenu));
        this.setDisplayedColumns();
    }

    getControl(control: string): FormControl {
        return this.filterForm.get(control) as FormControl;
    }
}
