import { UsersService } from './../../shared/services/users.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { trigger, state, style } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';

import { DatatableBaseComponent } from '@components/datatable-base.component';
import { StepsService } from '@services/steps.service';
import { UiService } from '@services/ui.service';
import { Step } from '@classes/step/step';
import { AuthService } from '@services/auth.service';
import { RoleName } from '@enums/role-name.enum';

@Component({
    selector: 'app-steps',
    templateUrl: './steps.component.html',
    styleUrls: ['./steps.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' }))
        ])
    ]
})
export class StepsComponent extends DatatableBaseComponent implements OnInit {
    steps: Step[];

    displayedColumns = ['name', 'address', 'contacts', 'client', 'isDeposit', 'tags', 'timeOnSite', 'actions'];
    dataSource: MatTableDataSource<Step>;

    isAdminClient: boolean = false;

    constructor(
        protected uiSrv: UiService,
        private route: ActivatedRoute,
        private stepsSrv: StepsService,
        private usersSrv: UsersService,
        private authSrv: AuthService
    ) {
        super(uiSrv);
    }

    ngOnInit() {
        super.ngOnInit();
        this.isAdminClient = this.authSrv.getUser().role.name === RoleName.admin_labo;
        if (this.isAdminClient) this.displayedColumns.pop();

        this.getRouteData();
        this.initSubscriptions();
    }

    private initTable(): void {
        this.dataSource = new MatTableDataSource(this.steps);
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (item: any, property) => {
            switch (property) {
                case 'client': return item.client.name;
                default: return item[property];
            }
        };
    }

    private getRouteData(): void {
        this.route.data
            .subscribe((data: { steps: Step[] }) => {
                this.steps = data.steps;
                this.initTable();
            });
    }

    private initSubscriptions(): void {
        this.addSubscription(
            this.stepsSrv.stepsListChanged
                .subscribe(steps => {
                    this.dataSource.data = steps || [];
                    this.dataSource.sort = this.sort;
                    this.isLoading = false;
                    this.onEditFinished();
                })
        );

        this.addSubscription(
            this.usersSrv.clientChangedSubject.subscribe(() => {
                this.isLoading = true;
                this.addSubscription(
                    this.stepsSrv.getList().subscribe()
                )
            })
        )
    }

    delete(idStep: string) {
        const confirmation = confirm('Êtes-vous certain de vouloir supprimer ce lieu de passage ?');

        if (confirmation) {
            this.stepsSrv.delete(idStep).subscribe(() => {
                this.uiSrv.showSnackbar('repository.steps.deleted', true);
            });
        }
    }
}
