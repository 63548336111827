import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FrequenciesMissionService {
    private baseUrl: string = '../../../assets/mock-data/frequencyMission.json';

    constructor(
        private http: HttpClient
    ) { }

    get(): Observable<any[]> {
        return this.http.get<any[]>(this.baseUrl);
    }

}
