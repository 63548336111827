import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as CryptoJS from 'crypto-js';
import { Subject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from 'environments/environment';

import { AuthData } from '@classes/auth/auth-data';
import { AuthResponse } from '@classes/auth/auth-response';
import { RoleName } from '@enums/role-name.enum';
import { Role } from '@classes/role';
import { NotificationsService } from './notifications.service';
import { AuthUser } from '@classes/auth/auth-user';
import { Profile } from '@classes/mworker/profile';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    authChanged = new Subject<boolean>();

    private identityApiUrl: string = environment.identityApiUrl;
    private identityApiMworkerUrl: string = environment.identityApiMworkerUrl;
    private apiUrl: string = environment.apiUrl;
    private apiCoreMworkerUrl: string = environment.apiCoreMworkerUrl;

    private tokenKey: string = environment.tokenKey;
    private rfTokenKey: string = environment.rfTokenKey;
    private tokenExpKey: string = environment.tokenExpKey;
    private tokenMworkerKey: string = environment.tokenMworkerKey;
    private rfTokenMworkerKey: string = environment.rfTokenMworkerKey;
    private tokenMworkerExpKey: string = environment.tokenMworkerExpKey;
    private afterLoginUrl = '/';

    actualUser: AuthUser = null;

    constructor(
        private router: Router,
        private http: HttpClient,
        private jwtHelperSrv: JwtHelperService,
        private notificationsSrv: NotificationsService
    ) { }

    login(authData: AuthData): void {
        const hashedPassword = this.cryptString(authData.password);
        const httpParams = new HttpParams()
            .append("username", authData.username)
            .append("password", hashedPassword)
            .append("grant_type", "password")
            .append("scope", "api offline_access")
            .append("client_id", "web");

        this.http.post<AuthResponse>(this.identityApiUrl + 'connect/token', httpParams)
            .subscribe(result => {
                this.saveToken(result.access_token, result.refresh_token, result.expires_in);
                this.authChanged.next(true);
                this.router.navigateByUrl(this.afterLoginUrl);
            });

        // const httpParamsMworker = new HttpParams()
        //     .append("grant_type", environment.grant_type)
        //     .append("client_secret", environment.clientSecretMworker)
        //     .append("scope", environment.scopeMworker)
        //     .append("client_id", environment.clientIdMworker);

        // // request to login in the Mworker api
        // this.http.post<AuthResponse>(this.identityApiMworkerUrl + 'connect/token', httpParamsMworker)
        // .subscribe(result => {
        //     this.saveTokenMworker(result.access_token, result.expires_in);
        //     // this.authChanged.next(true);
        // });



    }

    /**
     * Called if user forgot his password
     * Send email with link to reset password
     * @param username: username from request form
     */
    requestPasswordReset(username: string): Observable<void> {
        const httpParams = new HttpParams().append("username", username);

        return this.http.post<void>(this.apiUrl + 'users/password-reset-request', httpParams);
    }

    /**
     * Called if welcome link is expired or manually ask for new email in user management page
     * Send email with welcome link
     * @param username: username from email link or users list
     */
    requestPasswordInit(username: string): Observable<void> {
        const httpParams = new HttpParams()
            .append("username", username);

        return this.http.post<void>(this.apiUrl + 'users/password-init-request', httpParams);
    }

    updatePassword(resetData: { currentPassword: string, newPassword: string }): Observable<string> {
        const hashedCurrentPassword = this.cryptString(resetData.currentPassword);
        const hashedNewPassword = this.cryptString(resetData.newPassword);

        const httpParams = new HttpParams()
            .append("current_password", hashedCurrentPassword)
            .append("new_password", hashedNewPassword);

        return this.http.post<string>(this.apiUrl + 'users/password', httpParams)
            .pipe(
                tap(() => {
                    this.afterPwdReset(resetData.newPassword);
                })
            );
    }

    resetPassword(userId: string, token: string, newPassword: string): Observable<void> {
        const hashedNewPassword = this.cryptString(newPassword);

        // Send data in body in place of http params to avoid mess in token serialisation due to special characters
        const body = {
            token,
            new_password: hashedNewPassword
        };

        return this.http.post<void>(`${this.apiUrl}users/${userId}/password-reset`, body)
            .pipe(
                tap(() => {
                    this.router.navigate(['/login']);
                })
            );
    }

    refreshToken(): Observable<AuthResponse> {
        const refreshToken = localStorage.getItem(this.rfTokenKey);
        const httpParams = new HttpParams()
            .append("grant_type", "refresh_token")
            .append("scope", "api offline_access")
            .append("client_id", "web")
            .append("refresh_token", refreshToken);

        return this.http.post<AuthResponse>(this.identityApiUrl + 'connect/token', httpParams)
            .pipe(
                tap(result => {
                    this.saveToken(result.access_token, result.refresh_token, result.expires_in);
                    this.authChanged.next(true);
                })
            );
    }

    // refreshMworkerToken(): Observable<AuthResponse> {
    //     const httpParamsMworker = new HttpParams()
    //         // .append("grant_type", "client_credentials")
    //         // .append("client_secret", "229d73ce-71b2-41f6-b768-653e807f49d0")
    //         // .append("scope", "mworker_api")
    //         // .append("client_id", "JDW");
    //         .append("grant_type", environment.grant_type)
    //         .append("client_secret", environment.clientSecretMworker)
    //         .append("scope", environment.scopeMworker)
    //         .append("client_id", environment.clientIdMworker);

    //     // request to login in the Mworker api
    //     return this.http.post<AuthResponse>(this.identityApiMworkerUrl + 'connect/token', httpParamsMworker)
    //     .pipe(
    //         tap(result => {
    //         this.saveTokenMworker(result.access_token, result.expires_in);
    //         // this.authChanged.next(true);
    //     }));
    // }

    logout(prevUrl?: string): void {
        if (prevUrl) {
            this.afterLoginUrl = prevUrl;
        }

        // localStorage.clear();
        localStorage.removeItem(this.tokenKey);
        localStorage.removeItem(this.tokenExpKey);
        localStorage.removeItem(this.rfTokenKey);
        localStorage.removeItem(this.tokenMworkerKey);
        localStorage.removeItem(this.tokenMworkerExpKey);
        localStorage.removeItem(this.rfTokenMworkerKey);
        this.authChanged.next(false);
        this.notificationsSrv.stop();
        this.router.navigate(['/login']);
    }

    isAuth(): boolean {
        const decodedToken = this.getDecodedToken();

        if (!decodedToken || decodedToken.upd_pwd_req === true) {
            return false;
        }

        return !this.isTokenExpired();
    }

    getDecodedToken(): any {
        const token = this.jwtHelperSrv.tokenGetter();
        if (token) {
            return this.jwtHelperSrv.decodeToken(token);
        }

        return null;
    }

    getUser(): AuthUser {
        const decodedToken = this.getDecodedToken();
        if (!decodedToken) {
            return null;
        }

        this.actualUser = new AuthUser(decodedToken.name, new Role(decodedToken.role), decodedToken.client);

        return this.actualUser;
    }

    changeClientUser(clientId) {
        this.actualUser.clientId = clientId;
    }

    getActualUser() {
        return this.actualUser;
    }

    isAuthorized(roles: RoleName[]): boolean {
        const token = this.getDecodedToken();

        if (!token) {
            return false;
        }

        if (roles && roles.indexOf(token.role) === -1) {
            // role not authorised
            return false;
        }

        // authorised
        return true;
    }

    private saveToken(accessToken: string, refreshToken: string, deadline: number): void {
        const tokenExpDate = new Date();
        tokenExpDate.setSeconds(tokenExpDate.getSeconds() + deadline);

        localStorage.setItem(this.tokenExpKey, tokenExpDate.toString());
        localStorage.setItem(this.tokenKey, accessToken);
        localStorage.setItem(this.rfTokenKey, refreshToken);
    }

    private saveTokenMworker(accessToken: string, deadline: number): void {
        const tokenExpDate = new Date();
        tokenExpDate.setSeconds(tokenExpDate.getSeconds() + deadline);

        localStorage.setItem(this.tokenMworkerExpKey, tokenExpDate.toString());
        localStorage.setItem(this.tokenMworkerKey, accessToken);

        this.getProfileMworker().subscribe(async (result) => {
            localStorage.setItem('accountId', result.account.id);
        });
    }

    private getProfileMworker(): Observable<Profile> {
        return this.http.get<Profile>(`${this.apiCoreMworkerUrl}users/profile`
            , { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem(this.tokenMworkerKey)) }
        );
    }

    private afterPwdReset(newPassword: string): void {
        const decodedToken = this.getDecodedToken();

        if (decodedToken) {
            this.login({ username: decodedToken.name, password: newPassword });
        } else {
            this.logout();
        }
    }

    private isTokenExpired(): boolean {
        const tokenExpDate = new Date(localStorage.getItem(this.tokenExpKey));
        const tokenMworkerExpDate = new Date(localStorage.getItem(this.tokenMworkerExpKey));
        const now = new Date();
        return tokenExpDate < now && tokenMworkerExpDate < now;
    }

    private cryptString(stringTocrypt: string): string {
        return CryptoJS.SHA256(stringTocrypt).toString();
    }
}
