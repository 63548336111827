import { APP_INITIALIZER } from '@angular/core';

import { AppConfigService } from '@services/app-config.service';


// Load config files before app start
export function ConfigFactory(config: AppConfigService) {
    return () => config.loadConfig();
}

// Load translations before app start
export function TranslationsFactory(config: AppConfigService) {
    return () => config.loadTranslations();
}

export const appInitializerProviders = [
    {
        provide: APP_INITIALIZER,
        useFactory: ConfigFactory,
        deps: [AppConfigService],
        multi: true
    },
    {
        provide: APP_INITIALIZER,
        useFactory: TranslationsFactory,
        deps: [AppConfigService],
        multi: true
    }
];
