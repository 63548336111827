import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { trigger, state, style } from '@angular/animations';

import { DatatableBaseComponent } from '@components/datatable-base.component';
import { AuthService } from '@services/auth.service';
import { UiService } from '@services/ui.service';
import { UsersService } from '@services/users.service';
import { User } from '@classes/user/user';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' }))
        ])
    ]
})
export class UsersComponent extends DatatableBaseComponent implements OnInit {
    users: User[];

    displayedColumns: string[] = ['username', 'email', 'role', 'clients', 'lastLogonOn', 'actions'];
    dataSource: MatTableDataSource<User>;

    constructor(
        protected uiSrv: UiService,
        private authSrv: AuthService,
        private usersSrv: UsersService,
        private route: ActivatedRoute
    ) {
        super(uiSrv);
    }

    ngOnInit() {
        super.ngOnInit();
        this.getRouteData();
        this.initSubscriptions();
    }

    onSendMail(user: User): void {
        if (user.lastLogonOn != null) {
            return;
        }

        this.isLoading = true;
        this.authSrv.requestPasswordInit(user.username)
            .subscribe(() => {
                this.uiSrv.showSnackbar('users.emailSent', true);
                this.isLoading = false;
            });
    }

    onChangeState(user: User, isActive: boolean): void {
        this.usersSrv.changeStatus(user.id, isActive ? 'activate' : 'deactivate')
            .subscribe(() => {
                this.users.map(u => {
                    if (u.id === user.id) {
                        u.isActive = isActive;
                    }
                });
            });
    }

    private initTable(): void {
        this.dataSource = new MatTableDataSource(this.users);
        this.sortTable();
    }

    private sortTable(): void {
        this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'role': return item.role.name.toLowerCase();
                default: return item[property];
            }
        };
        this.dataSource.sort = this.sort;
    }

    private getRouteData(): void {
        this.route.data
            .subscribe((data: { users: User[] }) => {
                this.users = data.users.sort((a, b) => Number(b.isActive) - Number(a.isActive));
                this.initTable();
            });
    }

    private initSubscriptions(): void {
        this.addSubscription(
            this.usersSrv.usersListChanged
                .subscribe(users => {
                    this.dataSource.data = users.sort((a, b) => Number(b.isActive) - Number(a.isActive)) || [];
                    this.isLoading = false;
                    this.sortTable();
                    this.onEditFinished();
                })
        );

        this.addSubscription(
            this.usersSrv.clientChangedSubject.subscribe(() => {
                this.isLoading = true;
                this.addSubscription(
                    this.usersSrv.getList().subscribe()
                )
            })
        )
    }
}
