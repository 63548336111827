import { UsersService } from '@services/users.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { trigger, state, style } from '@angular/animations';

import { Sample } from '@classes/sample';
import { DatatableBaseComponent } from '@components/datatable-base.component';
import { UiService } from '@services/ui.service';
import { SamplesService } from '@services/samples.service';
import { AuthService } from '@services/auth.service';
import { RoleName } from '@enums/role-name.enum';

@Component({
    selector: 'app-samples',
    templateUrl: './samples.component.html',
    styleUrls: ['./samples.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' }))
        ])
    ]
})
export class SamplesComponent extends DatatableBaseComponent implements OnInit {
    samples: Sample[];
    sortOrders: number[] = [];

    displayedColumns = ['sortOrder', 'client', 'name', 'lowThreshold', 'highThreshold', 'actions'];
    dataSource: MatTableDataSource<Sample>;
    isAdminClient: boolean = false;

    constructor(
        protected uiSrv: UiService,
        private route: ActivatedRoute,
        private samplesSrv: SamplesService,
        private usersSrv: UsersService,
        private authSrv: AuthService
    ) {
        super(uiSrv);
    }

    ngOnInit() {
        this.isAdminClient = this.authSrv.getUser().role.name === RoleName.admin_labo;
        if (this.isAdminClient) this.displayedColumns.pop();

        super.ngOnInit();
        this.getRouteData();
        this.initSubscriptions();
    }

    private initTable(): void {
        this.samples.sort((a, b) => a.sortOrder - b.sortOrder);
        this.dataSource = new MatTableDataSource(this.samples);
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (item: any, property) => {
            switch (property) {
                case 'client': return item.client.name;
                default: return item[property];
            }
        };
        this.sortOrders = this.samples.reduce((sO: number[], s: Sample) => [...sO, s.sortOrder], []);
    }

    private getRouteData(): void {
        this.route.data
            .subscribe((data: { samples: Sample[] }) => {
                this.samples = data.samples;
                this.initTable();
            });
    }

    private initSubscriptions(): void {
        this.addSubscription(
            this.samplesSrv.samplesListChanged
                .subscribe(samples => {
                    this.samples = samples || [];
                    this.isLoading = false;
                    this.initTable();
                    this.onEditFinished();
                })
        );


        this.addSubscription(
            this.usersSrv.clientChangedSubject.subscribe(() => {
                this.isLoading = true;
                this.addSubscription(
                    this.samplesSrv.getList().subscribe()
                )
            })
        )
    }

    delete(idSample: string) {
        const confirmation = confirm('Êtes-vous certain de vouloir supprimer cet échantillon ?');

        if (confirmation) {
            this.samplesSrv.delete(idSample).subscribe(() => {
                this.uiSrv.showSnackbar('repository.samples.deleted', true);
            });
        }
    }
}
