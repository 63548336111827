import { UsersResolver } from './users.resolver';
import { ContactsResolver } from './contacts.resolver';
import { ClientsResolver } from './clients.resolver';
import { DevicesResolver } from './devices.resolver';
import { AssetsResolver } from './assets.resolver';
import { SamplesResolver } from './samples.resolver';
import { StepsResolver } from './steps.resolver';
import { AddressesResolver } from './addresses.resolver';
import { TemplatesResolver } from './templates.resolver';
import { MissionResolver } from './mission.resolver';
import { DashboardResolver } from './dashboard.resolver';
import { AlertResolver } from './alert.resolver';
import { AlertsListResolver } from './alertsList.resolver';
import { AlertDetailResolver } from './alertDetail.resolver';
export const resolverProviders = [
    UsersResolver,
    ContactsResolver,
    ClientsResolver,
    DevicesResolver,
    SamplesResolver,
    StepsResolver,
    AddressesResolver,
    TemplatesResolver,
    MissionResolver,
    DashboardResolver,
    AssetsResolver,
    AlertResolver,
    AlertsListResolver,
    AlertDetailResolver
];
