import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';

import { AuthService } from '@services/auth.service';
import { UiService } from '@services/ui.service';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    private subscription = new Subscription();

    loginForm: FormGroup;
    isLoading: boolean;

    constructor(
        private authSrv: AuthService,
        private uiSrv: UiService,
        private fb: FormBuilder
    ) { }

    ngOnInit() {
        this.initForm();
        this.initSubscriptions();
    }

    onSubmit(): void {
        this.isLoading = true;
        this.authSrv.login(this.loginForm.value);
    }

    private initForm(): void {
        this.loginForm = this.fb.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    private initSubscriptions(): void {
        this.isLoading = false;

        this.subscription.add(
            this.uiSrv.loadingStateChanged
            .subscribe(loadingState => {
                this.isLoading = loadingState;
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
