<mat-card>
    <mat-card-header>
        <mat-card-title>
            <h1>{{ 'repository.devices.title' | translate }}</h1>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <section class="datatable-container">
            <table mat-table [dataSource]="dataSource" matSort>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'repository.devices.list.name' | translate }} </th>
                    <td mat-cell *matCellDef="let device"> {{ device.name }}</td>
                </ng-container>
                <ng-container matColumnDef="model">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'repository.devices.list.model' | translate }} </th>
                    <td mat-cell *matCellDef="let device"> {{ device.model }}</td>
                </ng-container>
                <ng-container matColumnDef="version">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'repository.devices.list.version' | translate }} </th>
                    <td mat-cell *matCellDef="let device">{{ device.version }}</td>
                </ng-container>
                <ng-container matColumnDef="user">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'repository.devices.list.user' | translate }} </th>
                    <td mat-cell *matCellDef="let device"> {{ device.user.username }} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </section>
        <mat-progress-bar *ngIf="isLoading"  class="progress-bar" mode="indeterminate" color="accent"></mat-progress-bar>
    </mat-card-content>
</mat-card>
