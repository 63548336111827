import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap, map } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { Asset } from '@classes/mworker/asset';

@Injectable({
    providedIn: 'root'
})
export class AssetService {
    private baseUrl: string = environment.apiCoreMworkerUrl + 'realtime/assets';
    assetsListChanged = new Subject<Asset[]>();
    constructor(
        private http: HttpClient
    ) { }

    getAllAssetsOfAccount(id: string): Observable<Asset[]> {
        this.assetsListChanged.next([]);
        return this.http.get(`${this.baseUrl}?accountId=${id}`
        ,{headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('tokenMworker'))}
         )
                        .pipe(
                            map((assets: {assets: Asset[]}) => {
                                // get only the assets with captors for temperature

                                // this.assetsListChanged.next([ ...assets.assets.filter(oneAsset => {
                                //     return oneAsset.assetCategory.id === 6;
                                // }) ]);
                                // return assets.assets.filter(oneAsset => {
                                //     return oneAsset.assetCategory.id === 6;
                                // });

                                this.assetsListChanged.next([ ...assets.assets ]);
                                return [ ...assets.assets ];
                            })
                        );
    }
}
