import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

import { UiService } from "@services/ui.service";
import { DevicesService } from '@services/devices.service';
import { Device } from '@classes/device';


@Injectable()
export class DevicesResolver implements Resolve<Device[]> {
    constructor(
        private devicesSrv: DevicesService,
        private uiSrv: UiService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Device[]> {
        this.uiSrv.routeLoadingStateChanged.next(true);

        return this.devicesSrv.getList()
        .pipe(
            tap(() => {
                this.uiSrv.routeLoadingStateChanged.next(false);
            })
        );
    }
}
