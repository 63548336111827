import { BaseClass } from './base-class';
import { Civility } from '@enums/civility.enum';
import { BaseRepository } from '@interfaces/base-repository';
import { Client } from './client';

export class Contact extends BaseClass implements BaseRepository {
    civility: Civility;
    client: Client;
    firstname: string;
    lastname: string;
    phone: string;
    mobilePhone: string;
    email: string;
    identifier?: string;
    isDefault?: boolean;
    smsNotification?: boolean;

    constructor()
    constructor(civility: Civility, firstname: string, lastname: string, phone: string, mobilePhone: string, email: string, client: Client,
                identifier?: string, smsNotification?: boolean)
    constructor(civility?: Civility, firstname?: string, lastname?: string, phone?: string, mobilePhone?: string, email?: string, client?: Client,
                identifier?: string, smsNotification?: boolean) {
        super();
        this.civility = civility || null;
        this.firstname = this.trim(firstname);
        this.lastname = this.trim(lastname);
        this.phone = this.trim(phone).replace(' ', '').replace('/', '');
        this.mobilePhone = this.trim(mobilePhone).replace(' ', '').replace('/', '');
        this.email = this.trim(email).toLowerCase();
        this.identifier = this.trim(identifier).replace(' ', '').replace(':', '').toLowerCase();
        this.client = client;
        this.smsNotification = smsNotification;
    }

    static fromObject(object: Contact): Contact {
        return Object.assign(new Contact(), {
            ... object,
            client: object.client ? Client.fromObject(object.client) : null,
        });
    }

    get display(): string {
        return `${this.firstname} ${this.lastname}`;
    }

    doesInclude(value: string): boolean {
        return this.some([this.firstname, this.lastname], value);
    }
}
