import { Injectable } from '@angular/core';
import { Router, Route } from '@angular/router';

import { NavLink } from '@classes/nav-link';
import { AuthService } from './auth.service';
import { Role } from '@classes/role';
import { RoleName } from '@enums/role-name.enum';

@Injectable({
    providedIn: 'root'
})
export class NavService {
    private navLinks: NavLink[] = [
        { route: '/dashboard', icon: 'dashboard' },
        { route: '/missions/list', icon: 'list_alt' },  // view_list
        { route: '/missions/calendar', icon: 'event' },
        // { route: '/temperatures/follow', icon: 'ac_unit' },
        // { route: '/temperatures/listAlert', icon: 'notification_important' },
        // { route: '/temperatures/createAlert', icon: 'add_alert' },
        { route: '/missions/create', icon: 'playlist_add' },
        { route: '/templates', icon: 'vertical_split' }, // vertical_split | timeline
        {
            route: '/repository', icon: 'local_library',
            children: [
                { route: '/repository/contacts', icon: 'contacts' },
                { route: '/repository/clients', icon: 'store' },
                { route: '/repository/devices', icon: 'smartphone' },
                { route: '/repository/samples', icon: 'colorize' },
                { route: '/repository/steps', icon: 'place' },
                { route: '/repository/addresses', icon: 'map' }
            ]
        },
        { route: '/users', icon: 'group' },
        // { route: '/parameters', icon: 'settings' }
    ];

    constructor(
        private router: Router,
        private authSrv: AuthService

    ) {
        this.navLinks = this.configureLinks();
    }


    /**
     * Return hierarchical nav links based on auth status and user role
     * Used in main menu and home page
     * Can be called without any param
     */
    getNavLinks(links?: NavLink[]): NavLink[] {
        links = links || JSON.parse(JSON.stringify(this.navLinks));

        return links.filter(link => this.authSrv.isAuthorized(link.authorized))
            .map(link => {
                if (link.children && link.children.length) {
                    link.children = this.getNavLinks(link.children);
                    // Remove hierarchy if only one child
                    if (link.children.length === 1) {
                        link = link.children[0];
                    }
                }

                return link;
            });
    }

    /**
     * Return NavLink object from array of navigations points
     * Return null if user is not allowed on this route or if the route does'nt exists
     * @param path: array of navigations points from parent to selected child
     * @param icon: code of showed icon
     */
    getLinkFromRoute(path: string[], icon: string): NavLink {
        const routeFound = this.getRouteFromPath(path);

        if (!routeFound) {
            return null;
        }

        const fullPath = '/' + path.join('/');
        return new NavLink(fullPath, routeFound.data.title, icon);
    }

    /**
     * Return corresponding route, if allowed, from array of navigations points
     * Check user role and authentication
     * By default, return null if user is not allowed/authenticated or if the route does'nt exists
     * @param path: array of navigations points from parent to selected child
     * @param testRole: if true (default), return null if user role is not allowed
     */
    private getRouteFromPath(path: string[], testRole = true, routes?: Route[], parentRoles?: Role[], index = 0): Route {
        routes = routes || this.router.config;

        return routes.reduce((found, current) => {
            // If already found in previous loop
            if (found) {
                return found;
            }

            if (parentRoles && !current.data.roles) {
                current.data.roles = parentRoles;
            }

            if (current.path !== path[index] || (testRole && !this.authSrv.isAuthorized(current.data.roles))) {
                return null;
            }

            // If last point of path's array
            if (!path[index + 1]) {
                return current;
            }

            if (current.children && current.children.length) {
                return this.getRouteFromPath(path, testRole, current.children, current.data.roles, index + 1);
            }
        }, null);
    }

    /**
     * Get array of nav links. Generate texts and add roles based on router config
     * Used to manage route texts and role in only one place
     * @param links: NavLink[]
     */
    private configureLinks(links?: NavLink[]): NavLink[] {
        links = links || this.navLinks;

        return links.map(link => {
            const splittedRoute = link.route.split('/').slice(1);
            const routeFound = this.getRouteFromPath(splittedRoute, false);

            if (routeFound && routeFound.data) {
                link.authorized = routeFound.data.roles || null;
                link.title = routeFound.data.title;
            }

            if (link.children) {
                link.children = this.configureLinks(link.children);
            }

            return link;
        });
    }
}
