export class UserDetail {
    firstname: string;
    lastname: string;
    phone: string;

    constructor(firstname?: string, lastname?: string, phone?: string) {
        this.firstname = firstname ? firstname.trim() : '';
        this.lastname = lastname ? lastname.trim() : '';
        this.phone = phone ? phone.trim().replace(' ', '').replace('/', '') : '';
    }
}
