import { User } from './user/user';

export class BaseClass {
    id: string;
    createdOn: Date;
    modifiedOn: Date;
    createdBy: User;
    modifiedBy: User;

    protected trim(value: string): string {
        return value ? value.trim() : '';
    }

    protected some(props: string[], value: string): boolean {
        return props.some(prop => prop.toLowerCase().includes(value))
                || this.id.toLowerCase().includes(value);
    }
}
