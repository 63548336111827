import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import {
    CalendarDateFormatter,
    CalendarModule,
    CalendarMomentDateFormatter,
    DateAdapter,
    MOMENT
} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';

import * as moment from 'moment';

import { SharedModule } from 'app/shared/shared.module';

import { MissionsComponent } from './missions.component';
import { MissionEditComponent } from './mission-edit/mission-edit.component';
import { MissionDetailComponent } from './mission-detail/mission-detail.component';
import { MissionsCalendarComponent } from './missions-calendar/missions-calendar.component';
import { MissionCreateComponent } from './mission-create/mission-create.component';
import { MissionsListComponent } from './missions-list/missions-list.component';
import { DetailSummaryComponent } from './mission-detail/detail-summary/detail-summary.component';
import { DetailStepsComponent } from './mission-detail/detail-steps/detail-steps.component';
import { StepReportComponent } from './mission-detail/step-report/step-report.component';

export function momentAdapterFactory() {
    return adapterFactory(moment);
}

@NgModule({
    declarations: [
        MissionsComponent,
        MissionEditComponent,
        MissionDetailComponent,
        MissionsCalendarComponent,
        MissionCreateComponent,
        MissionsListComponent,
        DetailSummaryComponent,
        DetailStepsComponent,
        StepReportComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        MomentModule,
        CalendarModule.forRoot(
            {
                provide: DateAdapter,
                useFactory: momentAdapterFactory
            },
            {
                dateFormatter: {
                    provide: CalendarDateFormatter,
                    useClass: CalendarMomentDateFormatter
                }
            }
        )
    ],
    providers: [
        {
          provide: MOMENT,
          useValue: moment
        }
    ],
    exports: [
        DetailSummaryComponent
    ]
})
export class MissionsModule { }
