import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { Sample } from '@classes/sample';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SamplesService {
    samplesListChanged = new Subject<Sample[]>();
    private baseUrl: string = environment.apiUrl + 'samples';
    private samples: Sample[];

    constructor(
        private http: HttpClient
    ) { }

    getList(): Observable<Sample[]> {
        return this.http.get<Sample[]>(this.baseUrl)
        .pipe(
            map(samples => {
                this.samples = samples.map(s => Sample.fromObject(s));
                this.samplesListChanged.next([ ...this.samples ]);
                return [ ...this.samples ];
            })
        );
    }

    put(sample: Sample): Observable<Sample> {
        return this.http.put<Sample>(this.baseUrl, sample)
        .pipe(
            tap(editedSample => {
                editedSample = Sample.fromObject(editedSample);
                this.samples = this.samples.map(s => s.id === editedSample.id ? editedSample : s);
                this.samplesListChanged.next([ ...this.samples ]);
            })
        );
    }

    post(sample: Sample): Observable<Sample> {
        return this.http.post<Sample>(this.baseUrl, sample)
        .pipe(
            tap(createdSample => {
                createdSample = Sample.fromObject(createdSample);
                this.samples.push(createdSample);
                this.samplesListChanged.next([ ...this.samples ]);
            })
        );
    }

    delete(id: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${id}`)
        .pipe(
            tap(() => {
                this.samples = this.samples.filter(s => s.id !== id);
                this.samplesListChanged.next([ ...this.samples ]);
            })
        );
    }
}
