<mat-list class="steps-list">
    <mat-list-item *ngFor="let step of steps" class="step-item">
        <mat-icon mat-list-icon color="accent">{{ getStatusIcon(step) }}</mat-icon>
        <span *ngIf="step.finishedOn" class="timeFinishedOn">
            {{ step.finishedOn | amDateFormat: 'HH:mm'}}
        </span>
        <span class="step-name" [class.call-waiting]="step.onCall && !step.onCallActivated"
            [class.red]="step.alerts > 0">
            {{ step.name }}
        </span>
        <span *ngIf="step.pickupedOn != null" class="step-pickup">
            <span class="type-text">{{ 'steps.pickup' | translate }}</span>
        </span>
        <span *ngIf="step.deliveredOn != null" class="step-delivery">
            <span class="type-text">{{ 'steps.delivery' | translate }}</span>
        </span>
        <mat-icon *ngIf="step.tagScannedOn">nfc</mat-icon>
        <ng-container *ngIf="doesShowActions">
            <button mat-icon-button *ngIf="step.pickupedOn || step.deliveredOn" color="accent"
                [matTooltip]="'missions.report.action' | translate" [disabled]="isLoading"
                (click)="onShowReport(step.missionStepId)">
                <mat-icon aria-label="Report">
                    description
                </mat-icon>
            </button>
            <mat-slide-toggle *ngIf="step.onCall" [checked]="step.onCallActivated" class="activateToggle"
                (change)="step.onCallActivated ? onDeactivate(step) : onActivate(step)">
                <span *ngIf="!step.onCallActivated">{{ 'steps.activateStep' | translate }}</span>
                <span *ngIf="!!step.onCallActivated">{{ 'steps.stepActivated' | translate }}</span>
            </mat-slide-toggle>

            <div *ngIf="step.onCallActivatedBy" class="logOnCall">
                | Activé le <b>{{step.onCallActivatedOn | amDateFormat: 'DD/MM/YYYY HH:mm'}} {{step.onCallActivatedBy}}</b>
            </div>

            <div *ngIf="step.onCallDeactivatedBy" class="logOnCall">
                | Désactivé le <b>{{step.onCallDeactivatedOn | amDateFormat: 'DD/MM/YYYY HH:mm'}} {{step.onCallDeactivatedBy}}</b>
            </div>
        </ng-container>
        <mat-spinner *ngIf="isLoading && step.id === selectedStepId" [diameter]="35" color="accent"></mat-spinner>
    </mat-list-item>
</mat-list>
