<mat-toolbar color="accent">
    <mat-toolbar-row class="mat-elevation-z2 main-toolbar">
        <button class="nav-toggler" mat-icon-button (click)="onToggleSidenav()">
            <mat-icon aria-label="Menu">menu</mat-icon>
        </button>
        <a class="app-name" routerLink="/">{{ appName }}</a>

        <ul class="menu-links" *ngIf="isAuth">
            <li *ngIf="isAdmin && isAuth && clientForm">
                <form [formGroup]="clientForm" class="clientForm">
                    <mat-form-field>
                        <mat-label>Client</mat-label>
                        <mat-select formControlName="client">
                            <mat-option value="all">
                                Tous
                            </mat-option>
                            <mat-option *ngFor="let client of clients" [value]="client">
                                {{client.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
            </li>
            <li>
                <app-notifications></app-notifications>
            </li>
            <li *ngIf="user">
                <app-user-menu [user]="user"></app-user-menu>
            </li>
        </ul>
        <ul class="version" *ngIf="!isAuth">
            <li>
                {{ 'main.version' | translate }}: {{ appVersion }}
            </li>
        </ul>
    </mat-toolbar-row>
</mat-toolbar>
