import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { User } from '@classes/user/user';
import { TemplatesService } from '@services/templates.service';
import { UsersService } from '@services/users.service';
import { RoleName } from '@enums/role-name.enum';
import { Template } from '@classes/template';

@Component({
    selector: 'app-mission-create',
    templateUrl: './mission-create.component.html',
    styleUrls: ['./mission-create.component.scss']
})
export class MissionCreateComponent implements OnInit {
    templates: Template[];

    constructor(
        private templatesSrv: TemplatesService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.getDropdownsContent();
    }

    onLeave(): void {
        this.router.navigate(['../list'], { relativeTo: this.route });
    }

    private getDropdownsContent(): void {
        this.templatesSrv.getList()
        .subscribe(templatesFromSrv => {
            this.templates = templatesFromSrv;
        });
    }
}
