import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild, ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService } from '@services/auth.service';
import { UiService } from '@services/ui.service';
import { RoleName } from '@enums/role-name.enum';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
    private stateUrl: string;

    constructor(
        private authSrv: AuthService,
        private uiSrv: UiService,
        private router: Router,
        private jwtHelperSrv: JwtHelperService,
        private route: ActivatedRoute
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        this.stateUrl = state.url;
        // No token
        if (!this.jwtHelperSrv.tokenGetter()) {
            this.logout();
            return false;
        }
        // User password not set
        if (this.needResetPwd()) {
            this.uiSrv.showSnackbar('auth.updPwdReq', true, 5000);
            this.authSrv.authChanged.next(false);

            this.router.navigate(['/change-password']);
            return false;
        }

        // User role cannot access this route
        if (!this.authSrv.isAuthorized(next.data.roles)) {
            if(this.checkIfDriver()) {
                this.router.navigate(['/missions/list']);
            } else {
                this.router.navigate(['/']);
            }
            return false;
        }

        // token valid and up to date
        if (this.authSrv.isAuth()) {
            return true;
        }

        // Token exists but outdated
        return this.refreshToken();
    }

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivate(route, state);
    }

    private needResetPwd(): boolean {
        const decodedToken = this.authSrv.getDecodedToken();

        if (decodedToken && decodedToken.upd_pwd_req) {
            return true;
        }

        return false;
    }

    private refreshToken(): Observable<boolean> {
        this.uiSrv.routeLoadingStateChanged.next(true);

        return this.authSrv.refreshToken().pipe(
            map(() => {
                this.uiSrv.routeLoadingStateChanged.next(false);
                return true;
            }),
            catchError(error => {
                this.uiSrv.routeLoadingStateChanged.next(false);
                this.logout();
                return of(error);
            })
        );
    }

    private logout(): void {
        this.authSrv.logout(this.stateUrl);
    }

    // check if the user is a driver or not
    checkIfDriver(): boolean{
        const user = this.authSrv.getUser();
        if(user.role.name === 'driver') {
            return true;
        } else {
            return false;
        }
    }
}
