import { BaseClass } from '../base-class';
import { Contact } from '../contact';
import { Address } from '../address';
import { BaseRepository } from '@interfaces/base-repository';
import { Sample } from '@classes/sample';
import { Client } from '@classes/client';

export class Step extends BaseClass implements BaseRepository {
    // Base properties
    name: string;
    timeOnSite: number;
    comment: string;
    tags?: string[];

    intruder?: boolean;

    isDeposit: boolean;
    client: Client;
    address: Address;
    contacts: Contact[];

    // Properties added when step is affected to a mission or template
    sortOrder: number;
    estimatedTime: number;
    onCall: boolean;
    onCallActivated: boolean;
    onCallActivatedBy?: string;
    onCallDeactivatedBy?: string;
    onCallActivatedOn?: Date;
    onCallDeactivatedOn?: Date;

    // Properties updated by the driver
    ignoredOn?: Date;
    ignoredComment?: string;
    arrivedOn?: Date;
    pickupedOn?: Date;
    deliveredOn?: Date;
    finishedOn?: Date;
    tagScannedOn?: Date;

    templateStepId?: string;
    missionStepId?: string;

    alerts?: number;
    samples?: Sample[];

    constructor()
    constructor(name: string, address: Address, contacts: Contact[], isDeposit: boolean, client: Client,
        tags?: string[], timeOnSite?: number, comment?: string)
    constructor(name?: string, address?: Address, contacts?: Contact[], isDeposit?: boolean, client?: Client,
        tags?: string[], timeOnSite?: number, comment?: string) {
        super();
        this.name = this.trim(name);
        this.address = address || null;
        this.contacts = contacts || [];
        this.isDeposit = isDeposit;
        this.client = client;
        if (tags) {
            tags.map(oneTag => {
                this.trim(oneTag).replace(' ', '').replace(':', '').toLowerCase();
            });
            this.tags = tags;
        }
        // this.tag = this.trim(tag).replace(' ', '').replace(':', '').toLowerCase();
        this.timeOnSite = timeOnSite != null ? timeOnSite : 0;
        this.comment = this.trim(comment);
    }

    static fromObject(object: Step): Step {
        return Object.assign(new Step(), {
            ...object,
            address: object.address ? Address.fromObject(object.address) : null,
            contacts: object.contacts ? object.contacts.map(o => Contact.fromObject(o)) : null,
            client: object.client ? Client.fromObject(object.client) : null
        });
    }

    get display(): string {
        return `${this.name}`;
    }

    doesInclude(value: string): boolean {
        return this.some([this.name], value);
    }
}
