<svg viewBox="0 0 100 100" class="gauge-svg">
    <circle cx="50" cy="50" class="gauge-background"
        [ngClass]="{darken: isDarken}"
        [attr.stroke-width]="strokeWidth + 2"
        [attr.r]="radius" />
    <circle cx="50" cy="50" class="gauge-value"
        [ngClass]="{negative: value < 0}"
        [attr.stroke-width]="strokeWidth"
        [style.strokeDasharray]="circumference"
        [style.strokeDashoffset]="dashOffset"
        [attr.r]="radius" />
    <text class="label" x="50%" y="50%" dy="0.3em"
        [ngClass]="{negative: value < 0}">
        {{ value | formatPercent}}
    </text>
</svg>
