<form [formGroup]="stepsForm">
    <fieldset class="steps-list">
        <legend>
            <h3>{{ 'steps.title' | translate }} ici </h3>
        </legend>
        <mat-list formArrayName="steps" cdkDropList (cdkDropListDropped)="onDropStep($event)">
            <!-- <app-steps-list [steps]="lockedSteps"></app-steps-list> -->

            <mat-list-item *ngFor="let stepControl of stepsFields.controls; index as i" [formGroupName]="i" cdkDrag
                [cdkDragDisabled]="(!stepsForm.getRawValue().steps[i].step && stepsForm.getRawValue().steps[i].onCall) || stepsForm.getRawValue().steps[i].step && stepsForm.getRawValue().steps[i] && (stepsForm.getRawValue().steps[i].onCall || stepsForm.getRawValue().steps[i].step.finishedOn || stepsForm.getRawValue().steps[i].step.onCallActivated  || stepsForm.getRawValue().steps[i].step.arrivedOn || stepsForm.getRawValue().steps[i].step.ignoredOn)">
                <!-- Si l'étape n'est pas finie -->
                <span
                    *ngIf="!stepsForm.getRawValue().steps[i].step || !stepsForm.getRawValue().steps[i] || (!stepsForm.getRawValue().steps[i].onCall && !stepsForm.getRawValue().steps[i].step.finishedOn && !stepsForm.getRawValue().steps[i].step.onCallActivated && !stepsForm.getRawValue().steps[i].step.arrivedOn && !stepsForm.getRawValue().steps[i].step.ignoredOn); else otherCondition">
                    <mat-icon class="drag-icon" aria-label="Drag Me">drag_indicator</mat-icon>

                    <span *ngIf="!isTablet" class="sort-order">{{ i + startOrder }}</span>

                    <mat-form-field *ngIf="isTablet" class="startOrder-field">
                        <input matInput type="number" [value]="i + startOrder"
                            (keyup.enter)="changeOrder(i, $event.target.value)">
                    </mat-form-field>

                    <section class="step-select">
                        <app-autocomplete [control]="stepControl.get('step')" [list]="availableSteps" [noDefault]="true"
                            label="steps.select.label" errorMsg="steps.select.errorMessage">
                        </app-autocomplete>
                    </section>

                    <mat-form-field class="time-field" floatLabel="always">
                        <mat-label>{{ 'steps.estimatedTime.label' | translate }}</mat-label>
                        <mat-icon matPrefix color="accent">access_time</mat-icon>
                        <input type="number" matInput formControlName="estimatedTime" onclick="this.select()">
                        <span matSuffix>{{ 'steps.estimatedTime.suffix' | translate }}</span>
                        <mat-error>{{ 'steps.estimatedTime.errorMessage' | translate }}</mat-error>
                    </mat-form-field>
                    <app-tooltip *ngIf="i < stepsFields.controls.length - 1"
                        [text]="'steps.estimatedTime.toNext' | translate"></app-tooltip>
                    <app-tooltip *ngIf="i === stepsFields.controls.length - 1"
                        [text]="'steps.estimatedTime.toClient' | translate"></app-tooltip>



                    <mat-slide-toggle formControlName="onCall" (change)="activateDesactiveCall($event, stepControl)">{{
                        'steps.onCall' | translate }}</mat-slide-toggle>

                    <button type="button" mat-icon-button color="accent" (click)="onRemoveStep(i)">
                        <mat-icon aria-label="delete">delete</mat-icon>
                    </button>
                </span>

                <!-- Si l'étape est finie -->
                <ng-template #otherCondition>
                    <mat-icon mat-list-icon color="accent">{{ getStatusIcon(stepsForm.getRawValue().steps[i])
                        }}</mat-icon>
                    <span class="sort-order">{{ i + startOrder }}</span>

                    <section class="step-select">
                        <app-autocomplete [control]="stepControl.get('step')" [list]="availableSteps"
                            label="steps.select.label" errorMsg="steps.select.errorMessage">
                        </app-autocomplete>
                    </section>

                    <mat-form-field class="time-field" floatLabel="always">
                        <mat-label>{{ 'steps.estimatedTime.label' | translate }}</mat-label>
                        <mat-icon matPrefix color="accent">access_time</mat-icon>
                        <input type="number" matInput formControlName="estimatedTime" autocomplete="off"
                            onKeyDown="return false">
                        <span matSuffix>{{ 'steps.estimatedTime.suffix' | translate }}</span>
                        <mat-error>{{ 'steps.estimatedTime.errorMessage' | translate }}</mat-error>
                    </mat-form-field>
                    <app-tooltip *ngIf="i < stepsFields.controls.length - 1"
                        [text]="'steps.estimatedTime.toNext' | translate"></app-tooltip>
                    <app-tooltip *ngIf="i === stepsFields.controls.length - 1"
                        [text]="'steps.estimatedTime.toClient' | translate"></app-tooltip>



                    <mat-slide-toggle formControlName="onCall" (change)="activateDesactiveCall($event, stepControl)">{{
                        'steps.onCall' | translate }}</mat-slide-toggle>

                    <!-- <button type="button" mat-icon-button color="accent" (click)="onRemoveStep(i)">
                        <mat-icon aria-label="delete">delete</mat-icon>
                    </button> -->
                </ng-template>
            </mat-list-item>


            <mat-list-item>
                <button type="button" mat-icon-button color="accent" (click)="onAddStep()">
                    <mat-icon aria-label="Add">add</mat-icon>
                </button>
            </mat-list-item>
            <mat-list-item>
                <span class="label">{{ 'steps.estimatedTime.total.label' | translate }}
                    <app-tooltip [text]="'steps.estimatedTime.total.summary' | translate"></app-tooltip>
                </span>
                <span class="total-estimated-time">{{ totalEstimatedTime }}
                    {{ 'steps.estimatedTime.suffix' | translate }}</span>
            </mat-list-item>
        </mat-list>
    </fieldset>
</form>