<mat-card class="login-card">
    <mat-card-header>
        <mat-card-title>
            <h1>{{'auth.forgotPassword' | translate}}</h1>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form *ngIf="!resetPasswordSent"
            [formGroup]="resetForm" (ngSubmit)="onSubmit()">
            <div class="reset-field">
                <mat-form-field>
                    <mat-label>{{'auth.login.username.label' | translate}}</mat-label>
                    <mat-icon matPrefix>person</mat-icon>
                    <input type="text" matInput formControlName="username">
                    <mat-error>{{'auth.login.username.errorMessage' | translate}}</mat-error>
                </mat-form-field>
            </div>

            <button *ngIf="!isLoading"
                type="submit" mat-raised-button color="accent"
                [disabled]="resetForm.invalid || isLoading">{{'global.actions.submit' | translate}}</button>
            <mat-spinner *ngIf="isLoading" [diameter]="35" color="accent" ></mat-spinner>
        </form>
        <div *ngIf="resetPasswordSent">
            <p>{{'auth.changePassword.email' | translate}}</p>
        </div>
    </mat-card-content>
</mat-card>
