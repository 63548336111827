<div class="main-nav">
    <mat-nav-list *ngIf="navLinks" (click)="onCloseSidenav()">
        <mat-accordion>
            <app-nav-item *ngFor="let link of navLinks" [link]="link"></app-nav-item>
        </mat-accordion>
    </mat-nav-list>

    <mat-list class="version">
        <mat-list-item>
            <a href="https://www.bizzdev.com/" target="_blank">
                <img src="/assets/icons/bizzdev.png" alt="Logo Bizzdev" width="44" height="28">
            </a>
            {{'main.version' | translate}}: {{appVersion}}
        </mat-list-item>
        <mat-list-item *ngIf="isLocalTranslations" class="local-translation">
            - Local Translations: ON -
        </mat-list-item>
    </mat-list>
</div>
