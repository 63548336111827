import { MomentModule } from 'ngx-moment';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from './material/material.module';
import { FormEditBaseComponent } from './components/form-edit-base.component';
import { DatatableBaseComponent } from './components/datatable-base.component';
import { StepsFormComponent } from './components/steps-form/steps-form.component';
import { GaugeComponent } from '@components/gauge/gauge.component';
import { StepsListComponent } from './components/steps-list/steps-list.component';
import { ChartComponent } from '@components/chart/chart.component';

import { FormatPercentPipe } from './pipes/format-percent.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { EventDaysPipe } from './pipes/eventDays.pipe';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { TooltipComponent } from '@components/tooltip/tooltip.component';
import { StepsEditFormComponent } from './components/steps-edit-form/steps-edit-form.component';
import { OverlaySamplesComponent } from './components/overlay-samples/overlay-samples.component';
import { InitialsDialogComponent } from './components/initials-dialog/initials-dialog.component';

@NgModule({
    declarations: [
        FormEditBaseComponent,
        DatatableBaseComponent,
        StepsFormComponent,
        GaugeComponent,
        StepsListComponent,
        ChartComponent,
        FormatPercentPipe,
        OrderByPipe,
        EventDaysPipe,
        AutocompleteComponent,
        TooltipComponent,
        StepsEditFormComponent,
        OverlaySamplesComponent,
        InitialsDialogComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        TranslateModule,
        ReactiveFormsModule,
        MomentModule
    ],
    exports: [
        MaterialModule,
        TranslateModule,
        FormEditBaseComponent,
        DatatableBaseComponent,
        StepsFormComponent,
        StepsEditFormComponent,
        GaugeComponent,
        StepsListComponent,
        ChartComponent,
        FormatPercentPipe,
        OrderByPipe,
        EventDaysPipe,
        AutocompleteComponent,
        TooltipComponent,
        InitialsDialogComponent
    ]
})
export class SharedModule { }
