<div class="dashboard" *ngIf="!isLoading">
    <section class="missions-resume">
        <app-mission-resume [missionsLength]="missions.length" [samples]="samples"></app-mission-resume>
    </section>

    <section class="samples-list">
        <app-samples-list [samples]="samples"></app-samples-list>
    </section>

    <section class="missions-list">
        <app-missions-list [missions]="missions"></app-missions-list>
    </section>
</div>

<mat-spinner *ngIf="isLoading" [diameter]="35" color="accent" ></mat-spinner>
