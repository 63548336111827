import { BaseClass } from './base-class';
import { BaseRepository } from '@interfaces/base-repository';
import { Client } from './client';

export class Address extends BaseClass implements BaseRepository {
    street: string;
    streetNumber: string;
    locality: string;
    postalCode: string;
    latitude: number;
    longitude: number;
    client: Client;

    constructor()
    constructor(street: string, streetNumber: string, locality: string, postalCode: string, client: Client, latitude?: number, longitude?: number)
    constructor(street?: string, streetNumber?: string, locality?: string, postalCode?: string, client?: Client, latitude?: number, longitude?: number) {
        super();

        this.street = this.trim(street);
        this.streetNumber = this.trim(streetNumber);
        this.locality = this.trim(locality);
        this.postalCode = this.trim(postalCode);
        this.longitude = longitude == null ? null : longitude;
        this.latitude = latitude == null ? null : latitude;
        this.client = client;
    }

    static fromObject(object: Address): Address {
        return Object.assign(new Address(), {
            ... object,
            client: object.client ? Client.fromObject(object.client) : null,
        });
    }

    get display(): string {
        return `${this.street} ${this.streetNumber}, ${this.postalCode} ${this.locality}`;
    }

    doesInclude(value: string): boolean {
        return this.some([this.street, this.streetNumber, this.postalCode, this.locality], value);
    }
}
