<mat-card class="mission-card" [ngClass]="{'isUserLabo': isUserLabo}">
    <mat-card-header>
        <mat-card-title>
            <h1>{{ mission.name }}</h1>
        </mat-card-title>
        <mat-card-subtitle *ngIf="!isRestricted">
            <p class="created-by">
                {{ 'missions.detail.createdOn' | translate }} {{ mission.createdOn | amDateFormat: 'DD/MM/YYYY HH:mm' }}
                {{ 'missions.detail.createdBy' | translate }} <span class="username">{{ mission.createdBy.username
                    }}</span>
                <ng-container *ngIf="mission.modifiedOn">
                    , <br> {{ 'missions.detail.modifiedOn' | translate }}
                    {{ mission.modifiedOn | amDateFormat: 'DD/MM/YYYY HH:mm' }}
                    {{ 'missions.detail.modifiedBy' | translate }} <span class="username">{{ mission.modifiedBy.username
                        }}</span>
                </ng-container>
            </p>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <section class="global-section">
            <h2>{{ 'missions.detail.globalInfo' | translate }}</h2>

            <ul class="global-list">
                <li class="global-driver">
                    <form [formGroup]="driverForm">
                        <div>
                            <span class="label">{{ 'missions.detail.driver' | translate }} </span>
                            <span *ngIf="mission.user">{{ mission.user.username }}</span>
                            <span *ngIf="!mission.user">{{ 'missions.detail.noDriver' | translate }}</span>
                            <button class="driverButton"
                                *ngIf="!isReadOnly && !showDrivers && (mission.status === 2 || mission.status === 4)"
                                mat-raised-button color="accent" type="button"
                                (click)="openDrivers()">Réassigner</button>
                            <button class="driverButton"
                                *ngIf="!isReadOnly && showDrivers && (mission.status === 2 || mission.status === 4)"
                                mat-raised-button color="accent" type="button" (click)="confirmDriverChange()"
                                [disabled]="driverForm.pristine">Valider</button>
                        </div>

                        <ng-container *ngIf="showDrivers">
                            <mat-form-field class="drivers-field" *ngIf="!isLoadingDrivers">
                                <mat-label>Réassigner un nouveau chauffeur</mat-label>
                                <mat-select formControlName="driver">
                                    <mat-option *ngFor="let driver of drivers" [value]="driver">
                                        {{driver.username}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-spinner *ngIf="isLoadingDrivers" [diameter]="35" color="accent"></mat-spinner>
                        </ng-container>
                    </form>



                </li>
                <li class="global-client" *ngIf="!isRestricted">
                    <span class="label">{{ 'missions.detail.client' | translate }}</span>
                    <span>{{ mission.client?.name }}</span>
                    <button mat-icon-button *ngIf="mission.status === missionStatus.completed" color="accent"
                        [matTooltip]="'missions.report.action' | translate" [disabled]="isLoading"
                        (click)="onShowReport()">
                        <mat-icon aria-label="Report">
                            description
                        </mat-icon>
                    </button>
                    <mat-spinner *ngIf="isLoading" [diameter]="35" color="accent"></mat-spinner>
                </li>
                <li class="global-template">
                    <span class="label">{{ 'missions.detail.template' | translate }} </span>
                    <span *ngIf="mission.template">{{ mission.template.name }}</span>
                    <span *ngIf="!mission.template">{{ 'missions.detail.noTemplate' | translate }}</span>
                </li>
                <li class="global-comment">
                    <span class="label">{{ 'missions.detail.comment' | translate }}</span>
                    <span *ngIf="mission.comment">{{ mission.comment }}</span>
                    <span *ngIf="!mission.comment">{{ 'missions.detail.noComment' | translate }}</span>
                </li>
            </ul>
        </section>

        <section class="progress-section">
            <h2>{{ 'missions.detail.progress' | translate }} ({{ showStatusText(mission.status) | translate }})</h2>

            <ul class="progress-list">
                <li class="progress-steps">
                    <span class="label">{{ 'missions.detail.steps' | translate }}</span>
                    <!-- Enlever les étapes non activées -->
                    <!-- <span>{{ mission.steps.length }}</span> -->
                    <span>{{ numberSteps }}</span>
                </li>
                <li class="progress-completed">
                    <span class="label">{{ 'missions.detail.completed' | translate }}</span>
                    <span>{{ completedStepsNumber }} ({{ skippedStepsNumber }}
                        {{ 'missions.detail.cancelled' | translate }})</span>
                </li>
                <ng-container *ngIf="completedStepsNumber > 0; else noLastStep">
                    <li class="progress-lastOne">
                        <span class="label">Dernier</span>
                        <span>{{lastCompletedStep}}</span>
                    </li>
                </ng-container>
                <ng-template #noLastStep>
                    <li class="progress-lastOne">
                        <span class="label">Dernier</span>
                        <span>Aucun</span>
                    </li>
                </ng-template>


                <li class="progress-graph">
                    <app-gauge [value]="completion" [strokeWidth]="7"></app-gauge>
                </li>
                <li class="progress-pickup">
                    <ng-container *ngIf="!isRestricted">
                        <span class="label">{{ 'missions.detail.pickup' | translate }}</span>
                        <span>{{ pickupStepsNumber }}</span>
                    </ng-container>
                    <div *ngIf="mission.samples" class="samplesContainer">
                        <span class="label">Échantillons</span>
                        <span>{{getTotalSamples(mission)}}</span>
                    </div>
                    <ng-container *ngIf="mission.samples">
                        <div class="hide" *ngIf="mission.samples.length > 0">
                            <ul>
                                <li *ngFor="let sample of mission.samples">
                                    <span class="label">{{sample.name}}:</span> <span>{{sample.quantity}}</span>
                                </li>
                            </ul>
                        </div>
                    </ng-container>
                </li>
                <li class="progress-delivery" *ngIf="!isRestricted">
                    <span class="label">{{ 'missions.detail.delivery' | translate }}</span>
                    <span>{{ deliveryStepsNumber }}</span>
                </li>
                <li class="progress-temp">
                    <span class="label">{{ 'missions.detail.alertTemp' | translate }}</span>
                    <span [ngClass]="{'red': mission.alerts > 0}">{{ mission.alerts }}</span>
                </li>
                <li class="progress-timeleft" *ngIf="mission.status !== missionStatus.completed">
                    <!-- <span class="label">{{ 'missions.detail.timeLeft' | translate }}</span> -->
                    <span class="label">Passage dépôt estimé</span>
                    <!-- <span>{{ timeLeft }} {{ 'steps.estimatedTime.suffix' | translate }}</span> -->
                    <span>{{ timeLeftToNextLab }} {{ 'steps.estimatedTime.suffix' | translate }}</span>
                </li>
            </ul>
        </section>
    </mat-card-content>
    <mat-card-actions>
        <button mat-raised-button color="accent" type="button"
            *ngIf="mission.status !== missionStatus.onPause && mission.status !== missionStatus.completed && mission.status !== missionStatus.cancelled && !isReadOnly && !isRestricted && mission.status !== missionStatus.newMission"
            [disabled]="isLoading" (click)="onPause()">{{'missions.detail.pause' | translate}}</button>
        <button mat-raised-button color="accent" type="button"
            *ngIf="mission.status === missionStatus.onPause && mission.status !== missionStatus.newMission && !isRestricted && mission.user"
            [disabled]="isLoading" (click)="onRestart()">{{'missions.detail.restart' | translate}}</button>
        <button mat-raised-button color="accent" type="button"
            *ngIf="mission.status === missionStatus.newMission && !isRestricted && mission.user" [disabled]="isLoading"
            (click)="onRestart()">Commencer la mission</button>
        <button mat-raised-button color="accent" type="button"
            *ngIf="(mission.status === missionStatus.newMission || mission.status === missionStatus.onPause) && !isReadOnly"
            [disabled]="isLoading" (click)="onCancel()">{{'missions.detail.cancel' | translate}}</button>
        <button mat-raised-button color="accent" type="button" *ngIf="doesShowRoute"
            (click)="onSelectMission(mission.id)">{{ 'missions.list.detail' | translate }}</button>
        <mat-progress-bar class="progress-bar" mode="indeterminate" color="accent" *ngIf="isLoading"></mat-progress-bar>
    </mat-card-actions>
</mat-card>