import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { Step } from '@classes/step/step';
import { AuthService } from '@services/auth.service';
import { RoleName } from '@enums/role-name.enum';
import { Mission } from '@classes/mission/mission';
import { FormControl } from '@angular/forms';
import { MissionsService } from '@services/missions.service';
import { UiService } from '@services/ui.service';

@Component({
    selector: 'app-detail-steps',
    templateUrl: './detail-steps.component.html',
    styleUrls: ['./detail-steps.component.scss']
})
export class DetailStepsComponent implements OnInit {
    @Input() steps: Step[];
    @Input() mission: Mission;
    @Input() missions: Mission[];
    @Input() doesShowEdit: boolean;
    @Input() isReadOnly = true;

    @Output() editStarted = new EventEmitter<void>();

    isRestricted: boolean;

    copyStep = new FormControl();

    constructor(
        private authSrv: AuthService,
        private missionsSrv: MissionsService,
        private uiSrv: UiService
    ) { }

    ngOnInit(): void {
        this.isRestricted = this.authSrv.getUser().role.name === RoleName.labo;
    }

    onEdit(): void {
        this.editStarted.next();
    }

    copySteps(missionSelected) {
        const confirmation = confirm("Êtes-vous certain de copier les étapes de la mission actuelle vers celle choisie ? Cela anulera la mission actuelle.");
        if (confirmation) {
            this.missionsSrv.copySteps(missionSelected.id, this.mission.id).subscribe(() => {
                this.uiSrv.showSnackbar('steps.copied', true);
            });
        }

    }
}
