import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Contact } from '@classes/contact';
import { environment } from '@environments/environment';
import { tap, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ContactsService {
    contactsListChanged = new Subject<Contact[]>();
    private baseUrl: string = environment.apiUrl + 'contacts';
    private contacts: Contact[];

    constructor(
        private http: HttpClient
    ) { }

    getList(): Observable<Contact[]> {
        return this.http.get<Contact[]>(this.baseUrl)
        .pipe(
            map(contacts => {
                this.contacts = contacts.map(c => Contact.fromObject(c));
                this.contactsListChanged.next([ ...this.contacts ]);
                return [ ...this.contacts ];
            })
        );
    }

    getListByClientId(clientId): Observable<Contact[]> {
        return this.http.get<Contact[]>(`${this.baseUrl}?clientId=${clientId}`)
        .pipe(
            map(contacts => {
                this.contacts = contacts.map(c => Contact.fromObject(c));
                this.contactsListChanged.next([ ...this.contacts ]);
                return [ ...this.contacts ];
            })
        );
    }

    put(contact: Contact): Observable<Contact> {
        return this.http.put<Contact>(this.baseUrl, contact)
        .pipe(
            tap(editedContact => {
                editedContact = Contact.fromObject(contact);
                this.contacts = this.contacts.map(c => c.id === editedContact.id ? editedContact : c);
                this.contactsListChanged.next([ ...this.contacts ]);
            })
        );
    }

    post(contact: Contact): Observable<Contact> {
        return this.http.post<Contact>(this.baseUrl, contact)
        .pipe(
            tap(createdContact => {
                createdContact = Contact.fromObject(contact);
                this.contacts.push(createdContact);
                this.contactsListChanged.next([ ...this.contacts ]);
            })
        );
    }

    setDefault(contacts: Contact[]): Contact[] {
        if(!contacts.length) {
            return contacts;
        }

        return contacts.filter(c => c != null)
        .map((contact, i) => {
            contact.isDefault = !i;
            return contact;
        });
    }

    setSmsNotification(contact: Contact): Contact {
        contact.smsNotification = true;
        return contact;
    }

    delete(id: string): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${id}`)
        .pipe(
            tap(() => {
                this.contacts = this.contacts.filter(c => c.id !== id);
                this.contactsListChanged.next([ ...this.contacts ]);
            })
        );
    }
}
