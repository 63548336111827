<mat-card>
    <mat-card-header>
        <mat-card-title>
            <h1>{{ 'repository.clients.title' | translate }}</h1>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <section class="creation-form">
            <button *ngIf="!isCreationMode" (click)="onEdit()" [matTooltip]="'repository.clients.list.add' | translate"
                mat-mini-fab class="action-button" color="accent">
                <mat-icon aria-label="Add">person_add</mat-icon>
            </button>
            <app-client-edit *ngIf="isCreationMode && !editedId" (editFinished)="onEditFinished()"></app-client-edit>
        </section>
        <section class="datatable-container">
            <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'repository.clients.list.name' | translate
                        }} </th>
                    <td mat-cell *matCellDef="let client"> {{ client.name }} </td>
                </ng-container>

                <ng-container matColumnDef="contacts">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'repository.clients.list.contacts.default'
                        | translate }} </th>
                    <td mat-cell *matCellDef="let client">
                        <ng-container *ngFor="let contact of client.contacts">
                            <span *ngIf="contact.isDefault">{{ contact.firstname }} {{ contact.lastname }}</span>
                        </ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'repository.clients.list.address' |
                        translate }} </th>
                    <td mat-cell *matCellDef="let client">
                        <ng-container *ngIf="client.address">{{ client.address?.street }} {{
                            client.address?.streetNumber }}, {{ client.address?.postalCode }} {{
                            client.address?.locality }}</ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="step">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'repository.clients.list.step' | translate
                        }} </th>
                    <td mat-cell *matCellDef="let client">
                        <ng-container *ngIf="client.step">{{ client.step.name }}</ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="textSms">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'repository.clients.list.textSms' |
                        translate }} </th>
                    <td mat-cell *matCellDef="let client">
                        <mat-icon color="accent" *ngIf="client.textSms" class="smsIcon">sms</mat-icon>
                        <div class="smsHover" [innerHTML]="client.textSms">
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef> {{ 'repository.clients.list.actions' | translate }} </th>
                    <td mat-cell *matCellDef="let client">
                        <ng-container *ngIf="client.id !== editedId">
                            <button mat-icon-button class="action-button" color="accent" [disabled]="isLoading"
                                [matTooltip]="'repository.clients.list.edit' | translate" (click)="onEdit(client.id)">
                                <mat-icon aria-label="Edit">edit</mat-icon>
                            </button>
                        </ng-container>
                    </td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let client" [attr.colspan]="displayedColumns.length">
                        <div *ngIf="client.id === editedId" class="row-edit"
                            [@detailExpand]="client.id === editedId ? 'expanded' : 'collapsed'">
                            <app-client-edit [client]="client" (editFinished)="onEditFinished()"></app-client-edit>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row"
                    [ngClass]="{ 'expanded-row': row.id === editedId }"></tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
            </table>
        </section>
        <mat-progress-bar *ngIf="isLoading" class="progress-bar" mode="indeterminate" color="accent"></mat-progress-bar>
    </mat-card-content>
</mat-card>