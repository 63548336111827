import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FormatService {

    constructor() { }

    formatPercent(value: number): string {
        if(value == null || isNaN(value)) {
            return "";
        }

        return this.precision((value * 100), 2) + " %";
    }

    precision(value: number, precision: number): number {
        if(value == null || isNaN(value)) {
            return 0;
        }

        const factor = Math.pow(10, precision);
        return Math.round(value * factor) / factor;
    }
}
