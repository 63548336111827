import { UsersService } from '@services/users.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { trigger, state, style } from '@angular/animations';

import { DatatableBaseComponent } from '@components/datatable-base.component';
import { UiService } from '@services/ui.service';
import { TemplatesService } from '@services/templates.service';
import { Template } from '@classes/template';

@Component({
    selector: 'app-templates',
    templateUrl: './templates.component.html',
    styleUrls: ['./templates.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*' }))
        ])
    ]
})
export class TemplatesComponent extends DatatableBaseComponent implements OnInit {
    templates: Template[];

    displayedColumns = ['name', 'client', 'steps', 'actions'];
    dataSource: MatTableDataSource<Template>;

    constructor(
        protected uiSrv: UiService,
        private route: ActivatedRoute,
        private templatesSrv: TemplatesService,
        private usersSrv: UsersService
    ) {
        super(uiSrv);
    }

    ngOnInit() {
        super.ngOnInit();
        this.getRouteData();
        this.initSubscriptions();
    }

    private initTable(): void {
        this.dataSource = new MatTableDataSource(this.templates);
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = (item: any, property) => {
            switch(property) {
              case 'client': return item.client.name;
              default: return item[property];
            }
          };
    }

    private getRouteData(): void {
        this.route.data
        .subscribe((data: { templates: Template[] }) => {
            this.templates = data.templates;
            this.initTable();
        });
    }

    private initSubscriptions(): void {
        this.addSubscription(
            this.templatesSrv.templatesListChanged
            .subscribe(templates => {
                this.dataSource.data = templates || [];
                this.dataSource.sort = this.sort;
                this.isLoading = false;
                this.onEditFinished();
            })
        );

        this.addSubscription(
            this.usersSrv.clientChangedSubject.subscribe(() => {
                this.isLoading = true;
                this.templatesSrv.getList().subscribe();
            })
        )
    }

    delete(idTemplate: string) {
        const confirmation = confirm('Êtes-vous certain de vouloir supprimer ce template ?');

        if (confirmation) {
            this.templatesSrv.delete(idTemplate).subscribe(() => {
                this.uiSrv.showSnackbar('templates.deleted', true);
            });
        }
    }
}
