import { Injectable, Injector } from '@angular/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { AppConfig } from '@interfaces/app-config';

@Injectable({
    providedIn: 'root'
})
export class AppConfigService {
    private config: AppConfig;

    constructor(
        private http: HttpClient,
        private injector: Injector,
        private translateSrv: TranslateService
    ) { }

    loadConfig(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            // const configFile = `assets/config/${environment.name}-config.json`;

            this.http.get<AppConfig>(`assets/config/app-config.json`)
            .pipe(
                map(data => {
                    this.config = data;
                    resolve(true);
                }),
                catchError(error => {
                    resolve(false);
                    return of(error);
                })
            ).subscribe();
        });
    }

    loadTranslations(): Promise<boolean> {
        return new Promise<any>((resolve, reject) => {
            const locationInitialized = this.injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

            locationInitialized.then(() => {
                const langToSet = this.translateSrv.getBrowserLang();
                this.translateSrv.setDefaultLang('fr-FR');
                this.translateSrv.use(langToSet)
                    .subscribe({
                        complete() { resolve(null); }
                    });
            });
        });
    }

    get isMaintenance(): boolean {
        return this.config ? this.config.maintenance : null;
    }
}
